import { Box } from "@mui/material";
import React from "react";

export default function BrandLogo({ brand }) {
  return (
    <>
      <Box
        component="img"
        sx={{ height: {mobile: "25px", xs: "25px", sm: "30px", md: "35px", lg: "45px", xl: "50px" }, width: "auto" }}
        src={brand?.image?.fileUrl}
        alt={brand?.name}
      />
    </>
  );
}

import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Box, CardActionArea } from "@mui/material";

export default function GameNewsCard({ img, title, content }) {
  return (
    <Card sx={{ maxWidth: 345, background: "#101010" }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height={{
            mobile: "220px",
            xs: "230px",
            sm: "240px",
            md: "280px",
            lg: "280px",
            xl: "280px",
          }}
          image={img}
          alt="game news"
        />
        <CardContent
          sx={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            height: {
              mobile: "120px",
              xs: "130px",
              sm: "140px",
              md: "150px",
              lg: "150px",
              xl: "150px",
            },
            background: "linear-gradient(358.94deg, #000000 30.91%, rgba(0, 0, 0, 0) 100.11%)",
            display: "flex",
            flexDirection: "column-reverse",
            paddingX: "10px",
            paddingY: "0px",
          }}
        >
          <Typography variant="newsCardHead" color="white" style={{ padding: "8px" }}>
            {title}
          </Typography>
        </CardContent>
      </CardActionArea>
      <Box
        sx={{
          width: "100%",
          padding: "15px 15px 15px 15px",
        }}
      >
        <Typography
          variant="newsCardContent"
          color="white"
          sx={{
            paddingX: "8px",
            height: {
              mobile: "120px",
              xs: "130px",
              sm: "140px",
              md: "165px",
              lg: "165px",
              xl: "165px",
            },
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            // WebkitLineClamp: 3,
            WebkitBoxOrient: "vertical",
          }}
        >
          {content}
        </Typography>
      </Box>
    </Card>
  );
}

import { Box, Button, Grid, Radio, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";

export default function AddressSelectForm({
  addresses,
  selectedAddress,
  setSelectedAddress,
  setAddNewAddress,
}) {
  function capitalize(word) {
    return word.charAt(0).toUpperCase() + word.substring(1);
  }

  function capitalizeWords(sentence) {
    return sentence
      .split(" ")
      .map((word) => capitalize(word))
      .join(" ");
  }

  useEffect(() => {
    if (addresses.length > 0) {
      setSelectedAddress(addresses[0]);
    }
  }, [addresses]);

  return (
    <Box
      sx={{
        width: "100%",
        boxShadow: "0px 0px 10.798px 6.64491px rgba(0, 0, 0, 0.35)",
        padding: {
          mobile: "10px",
          sm: "10px",
          md: "20px",
          lg: "50px",
          xl: "40px",
        },
      }}
    >
      <Grid container spacing={4}>
        <Grid item mobile={12}>
          <Typography variant="h4"
          textAlign={"center"}
            // sx={{
            //   fontFamily: "inter",
            //   fontWeight: 500,
            //   fontSize: "25px",
            //   color: "white",
            //   textAlign: "center",
            // }}
          >
            SELECT DELIVERY ADDRESS
          </Typography>
        </Grid>
        {addresses.map((address, key) => {
          return (
            <Grid key={key} item mobile={12} sm={6} md={6} lg={6} xl={6}>
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  boxShadow: "0px 0px 10.798px 6.64491px rgba(0, 0, 0, 0.35)",
                  paddingRight: "10px",
                  paddingY: "10px",
                }}
              >
                <Stack direction="row" justifyContent="space-between" alignItems="start">
                  <Radio
                    checked={selectedAddress._id === address._id}
                    onChange={(e) => setSelectedAddress(address)}
                    value={address}
                    name="radio-buttons"
                    inputProps={{ "aria-label": "A" }}
                    sx={{ color: "#01FFFF" }}
                    size="small"
                  />

                  <Stack sx={{ width: "100%" }}>
                    <Typography
                    variant="subtitle1"
                      sx={{
                        // color: "#FFFFFF",
                        // fontFamily: "inter",
                        // fontWeight: 500,
                        // fontSize: "14px",
                        wordBreak: "break-all",
                      }}
                    >
                      {address.fullname},
                    </Typography>
                    <Typography
                    variant="subtitle1"
                      sx={{
                        // color: "#FFFFFF",
                        // fontFamily: "inter",
                        // fontWeight: 500,
                        // fontSize: "14px",
                      }}
                    >
                      Mobile: {address.mobile},
                    </Typography>
                    <Typography
                    variant="subtitle1"
                      sx={{
                        // color: "#FFFFFF",
                        // fontFamily: "inter",
                        // fontWeight: 500,
                        // fontSize: "14px",
                        wordBreak: "break-word",
                      }}
                    >
                      {address.address},
                    </Typography>
                    <Typography
                    variant="subtitle1"
                      sx={{
                        // color: "#FFFFFF",
                        // fontFamily: "inter",
                        // fontWeight: 500,
                        // fontSize: "14px",
                      }}
                    >
                      {address.city},
                    </Typography>
                    <Typography
                    variant="subtitle1"
                      sx={{
                        // color: "#FFFFFF",
                        // fontFamily: "inter",
                        // fontWeight: 500,
                        // fontSize: "14px",
                      }}
                    >
                      {capitalizeWords(address.state)},
                    </Typography>
                    <Typography
                    variant="subtitle1"
                      sx={{
                        // color: "#FFFFFF",
                        // fontFamily: "inter",
                        // fontWeight: 500,
                        // fontSize: "14px",
                      }}
                    >
                      {address.postCode},
                    </Typography>
                    <Typography
                    variant="subtitle1"
                      sx={{
                        // color: "#FFFFFF",
                        // fontFamily: "inter",
                        // fontWeight: 500,
                        // fontSize: "14px",
                      }}
                    >
                      Landmark: {address.landmark},
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
            </Grid>
          );
        })}
        <Grid item mobile={12}>
          <Stack justifyContent={"center"} alignItems={"center"} spacing={0.2}>
            <Typography
              sx={{
                color: "#FFFFFF",
                fontFamily: "roboto",
                fontWeight: 300,
                fontSize: 12,
              }}
            >
              - OR -
            </Typography>

            <Button
              sx={{
                color: "#01FFFF",
                fontFamily: "roboto",
                fontWeight: 500,
                fontSize: 12,
              }}
              onClick={() => {
                setAddNewAddress(true);
                setSelectedAddress({});
              }}
            >
              Add New Address
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}

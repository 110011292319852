import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import BrandLogo from "./BrandLogo";

export default function ProductBrand() {
  const [brands, setBrands] = useState([]);

  const getBrands = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/brand/all`);
      console.log("res", res.data.data);
      if (!res.data.isError) {
        const brandata = res.data.data.result;
        const newBrandData = Array.from(
          { length: 50 },
          () => brandata[Math.floor(Math.random() * brandata.length)]
        );
        const uniqueBrandData = newBrandData.filter((value, index, arr) => {
          return value !== arr[index - 1];
        });
        setBrands(uniqueBrandData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBrands();
  }, []);

  return (
    <Container
      maxWidth="xl"
      sx={{
        marginBottom: {
          mobile: "30px",
          xs: "50px",
          sm: "70px",
          md: "80px",
          lg: "90px",
        },
        
      }}
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{ width: "100%", height: "fit-content", marginBottom: "30px" }}
      >
        <Typography
          variant="h2"
          sx={{
            color: "#fff",
          }}
          gutterBottom
        >
          Product Brands
        </Typography>
      </Stack>
      <Stack
        sx={{
          width: "100%",
          height: { mobile: "180px", xs: "180px", md: "240px" },
          overflow: "hidden",
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background:
              " linear-gradient(90deg, rgba(29,35,38,1) 7%, rgba(253,187,45,0) 25%, rgba(215,160,42,0) 75%, rgba(29,35,38,1) 93%)",
          }}
        />
        <Grid container spacing={{ mobile: 1, xs: 1, sm: 1, md: 1.5, lg: 2.5, xl: 3 }} padding={1}>
          {brands.map((item, key) => {
            return (
              <Grid item key={key}>
                <BrandLogo brand={item} />
              </Grid>
            );
          })}
        </Grid>
      </Stack>
    </Container>
  );
}

const localCart = (cartItems) => {
  const products = [];
  let maxPrice = 0;
  let subTotal = 0;
  let shippingCharge = 0;
  if (cartItems.length) {
    cartItems.forEach((item) => {
      const product = {
        productId: item._id,
        quantity: Number(item.quantity ? item.quantity : 1),
        name: item.name,
        shortName: item.shortName,
        sellingPrice: item.sellingPrice,
        mrp: item.mrp,
        image: {
          fileUrl: item.images[0].fileUrl,
        },
        description: item.description,
        brand: item.brand,
        category: item.category,
        specifications: item.specifications,
        shippingCharge: item.shippingCharge,
      };
      maxPrice =
        maxPrice + Number(item.mrp * Number(item.quantity ? item.quantity : 1));
      subTotal =
        subTotal +
        Number(item.sellingPrice * Number(item.quantity ? item.quantity : 1));
      if (item.shippingCharge && item.shippingCharge > shippingCharge) {
        shippingCharge = item.shippingCharge;
      }
      products.push(product);
    });

    let grandTotal = subTotal + shippingCharge;
    let discount = maxPrice - subTotal;
    return {
      products,
      maxPrice,
      subTotal,
      shippingCharge,
      grandTotal,
      discount,
    };
  }
  return {
    products: [],
    maxPrice: 0,
    subTotal: 0,
    shippingCharge: 0,
    grandTotal: 0,
    discount: 0,
  };
};

export default localCart;

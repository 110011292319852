import StoreMenu from "./StoreMenu";

const navConfig = [
  {
    name: "Home",
    path: "/",
    menu: false,
  },
  {
    name: "Store",
    path: "/store",
    menu: true,
    menuItem: <StoreMenu />,
  },
  {
    name: "Contact",
    path: "/contact-us",
    menu: false,
  },
];

export default navConfig;

import * as Yup from "yup";
import axios from "axios";
import { useState } from "react";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
// material
import {
    Stack,
    Checkbox,
    TextField,
    IconButton,
    InputAdornment,
    FormControlLabel,
    Typography,
    Box,
    Container,
    styled,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { CloseSharp, Visibility, VisibilityOff } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { removeUser, updateUser } from "../../redux/userSlice";
import toast, { Toaster } from "react-hot-toast";
import { useEffect } from "react";
import { addToCartOnLogin } from "../../redux/apis";
import Page from "../../utils/Page";

const CssTextField = styled(TextField)({
    "& label.Mui-focused": {
        color: "#FFFFFF",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#FFFFFF",
    },
    "& .MuiInputBase-input": {
        color: "#FFFFFF",
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#FFFFFF",
        },
        "&:hover fieldset": {
            borderColor: "#46fafa",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#00eff7",
        },
    },
});

export default function Login() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const uninterceptedAxiosInstance = axios.create();
    const [showPassword, setShowPassword] = useState(false);
    const [loginsuccess, setLoginsuccess] = useState(false);
    const [userData, setUserData] = useState({});

    const token = searchParams.get("token")

    const LoginSchema = Yup.object().shape({
        password: Yup.string().min(6, "Too Short!").max(20, "Too Long!").required("Password required"),
        confirmPassword: Yup.string().oneOf([Yup.ref("password"), null], "Passwords must match"),
    });
    const formik = useFormik({
        initialValues: {
            password: "",
            confirmPassword: "",
        },
        validationSchema: LoginSchema,
        onSubmit: async () => {
            try {
                const res = await uninterceptedAxiosInstance.put(
                    `${process.env.REACT_APP_API_URL}/auth/user/reset-password`,
                    {
                        token: token,
                        password: values.password,
                    }
                );
                if (res.data.isError) {
                    toast.error(res.data.message, {
                        style: {
                            border: "1px solid #713200",
                            padding: "16px",
                            color: "#713200",
                        },
                    });
                } else {
                    toast.success("Password has been changed", {
                        style: {
                            border: "1px solid #713200",
                            padding: "16px",
                            color: "#713200",
                        },
                    });
                    navigate("/login")
                }
            } catch (error) {
                console.log(error);
                toast.error(error.response.data.message, {
                    style: {
                        border: "1px solid #713200",
                        padding: "16px",
                        color: "#713200",
                    },
                });
            }
        },
    });

    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

    const handleShowPassword = () => {
        setShowPassword((show) => !show);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Page title="Reset">
            <Box sx={{ width: "100%", bgcolor: "#1D2326", minHeight: "100vh" }}>
                <Container maxWidth="xl">
                    <Stack justifyContent="center" alignItems="center">
                        <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                                width: {mobile:"99%", xs: "95%", sm: "600px", md: "700px" },
                                bgcolor: "#000000",
                                marginY: "50px",
                                borderRadius: "30px",
                            }}
                        >
                            <Stack justifyContent="center" padding={2} alignItems="end" sx={{ width: "100%" }}>
                                <IconButton onClick={() => navigate(-1)}>
                                    <CloseSharp sx={{ color: "#f2f2f2" }} />
                                </IconButton>
                            </Stack>

                            <Box
                                onClick={() => navigate("/store")}
                                component="img"
                                src="/images/logo.png"
                                alt="logo"
                                sx={{
                                    width: "200px",
                                    marginBottom: "80px",
                                    cursor: "pointer",
                                    marginTop: "40px",
                                }}
                            />
                            <FormikProvider value={formik}>
                                <Form
                                    autoComplete="off"
                                    style={{ width: "100%", paddingBottom: "100px" }}
                                    noValidate
                                    onSubmit={handleSubmit}
                                >
                                    <Stack
                                        sx={{
                                            width: "100%",
                                            paddingX: {mobile:"15px", xs: "20px", sm: "50px", md: "100px" },
                                        }}
                                        justifyContent="center"
                                        alignItems="center"
                                        spacing={4}
                                    >


                                        <CssTextField
                                            fullWidth
                                            autoComplete="current-password"
                                            type={showPassword ? "text" : "password"}
                                            label="Password"
                                            InputLabelProps={{
                                                style: { color: "#f2f2f2" },
                                            }}
                                            {...getFieldProps("password")}
                                            InputProps={{
                                                autocomplete: "new-password",
                                                form: {
                                                    autocomplete: "off",
                                                },
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={handleShowPassword} edge="end">
                                                            {showPassword ? (
                                                                <Visibility sx={{ color: "#FFFFFF" }} />
                                                            ) : (
                                                                <VisibilityOff sx={{ color: "#FFFFFF" }} />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            error={Boolean(touched.password && errors.password)}
                                            helperText={touched.password && errors.password}
                                        />
                                        <CssTextField
                                            fullWidth
                                            autoComplete="current-password"
                                            type={showPassword ? "text" : "password"}
                                            label="Confirm Password"
                                            InputLabelProps={{
                                                style: { color: "#f2f2f2" },
                                            }}
                                            {...getFieldProps("confirmPassword")}
                                            InputProps={{
                                                autocomplete: "new-password ",
                                                form: {
                                                    autocomplete: "off",
                                                },
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={handleShowPassword} edge="end">
                                                            {showPassword ? (
                                                                <Visibility sx={{ color: "#FFFFFF" }} />
                                                            ) : (
                                                                <VisibilityOff sx={{ color: "#FFFFFF" }} />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                                            helperText={touched.confirmPassword && errors.confirmPassword}
                                        />
                                        <LoadingButton
                                            fullWidth
                                            size="large"
                                            style={{
                                                marginTop: "40px",
                                                width: "200px",
                                                backgroundColor: "#01FFFF",
                                                color: "#1D2326",
                                                fontFamily: "inter",
                                                fontWeight: "700",
                                            }}
                                            type="submit"
                                            variant="contained"
                                            loading={isSubmitting}
                                        >
                                            Reset Password
                                        </LoadingButton>

                                        <Link style={{ textDecoration: "none" }} to="/login">
                                            <Typography variant="subtitle1" sx={{ color: "#f2f2f2", cursor: "pointer", fontSize: "15px", fontWeight: 400 }}>
                                                <span style={{ color: "#01FFFF", fontWeight: 500 }}>Remember Password?</span>
                                            </Typography>
                                        </Link>

                                    </Stack>
                                </Form>
                            </FormikProvider>
                        </Stack>
                    </Stack>
                </Container>
            </Box>
        </Page>
    );
}

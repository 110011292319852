import { Box, Container, Grid, Pagination, Stack, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import BreadCrumbHeader from "../../components/BreadCrumb/BreadCrumbHeader";
import OrderCards from "../../components/OrderView/OrderCards";
import Page from "../../utils/Page";

const OrderView = () => {
  const [orders, setOrders] = useState();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  useEffect(() => {
    getAllOrders();
  }, [page, pageSize]);

  const getAllOrders = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/order/user/all`, {
        params: { page, pageSize },
      });
      setOrders(res.data.data.orders);
      setTotalPages(res.data.data.maxRecords);
      console.log(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  function handlePageChange(event, page) {
    setPage(page - 1);
  }
  const items = [];
  const currentPage = "Orders";

  return (
    <Page title="Orders">
      <BreadCrumbHeader items={items} currentPage={currentPage} />
      <Container maxWidth={"lg"}>
        <Stack sx={{ padding: { mobile: "20px 0px", xs: "20px 0px", md: "20px" } }} spacing={3}>
          <Typography
            variant="h4"
            textAlign={"center"}
            fontWeight="bold"
            fontSize={{ mobile: "24px", xs: "24px", md: "34px" }}
          >
            MY ORDERS
          </Typography>
          {orders?.map((order, index) => (
            <OrderCards order={order} getAllOrders={getAllOrders} />
          ))}
        </Stack>
        <Grid item mobile={12}>
          <Stack justifyContent="center" alignItems="center" sx={{ margin: "20px" }}>
            <Pagination
              variant="text"
              sx={{
                color: "white",
                "& .MuiPaginationItem-root": { color: "white" },
              }}
              color="primary"
              count={Math.ceil(totalPages / pageSize)}
              page={page + 1}
              onChange={handlePageChange}
            />
          </Stack>
        </Grid>
      </Container>
    </Page>
  );
};

export default OrderView;

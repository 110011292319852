import React from "react";
import ContactUsBanner from "../../components/ContactUs/ContactUsBanner";
import GetInTouchWithUs from "../../components/ContactUs/GetInTouchWithUs";
import ProductBrand from "../../components/CategorySections/ProductBrand/ProductBrand";
import DeliverySupport from "../../components/StoreSections/DeliverySupport/DeliverySupport";
import Page from "../../utils/Page";
import { Container } from "@mui/material";

export default function ContactUs() {
  return (
    <Page title="Contact Us">
      <ContactUsBanner />
      <GetInTouchWithUs />
      <DeliverySupport />
      <ProductBrand />
      <Container maxWidth="lg">
      </Container>
    </Page>
  );
}

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import DialogTitle from "@mui/material/DialogTitle";
import ApprovedIcon from "../../assets/icon/approve.png";
import RejectedIcon from "../../assets/icon/rejected.png";

import Slide from "@mui/material/Slide";
import { Box, Stack, styled, TextField, Tooltip, Typography } from "@mui/material";
import moment from "moment";
import { titleCase } from "../../utils/stringStyle";
import { toast } from "react-hot-toast";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledDialog = styled(Dialog)`
  & .MuiDialog-paper {
    background-color: #1D2326 !important;
  }
`;

export default function ReasonDialog({ product, isReplacement, isReturn }) {
  const [open, setOpen] = React.useState(false);
  console.log(isReplacement, isReturn);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Tooltip title="Click to view reason">
        <Typography
          variant="button"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "4px",
            flexDirection: { mobile: "column", xs: "column", md: "row" },
            fontSize: { mobile: "12px", xs: "12px", md: "16px" },
            textAlign: "center",
            cursor: "pointer",
          }}
          onClick={handleClickOpen}
        >
          {isReturn ? `Return Rejected` : `Replacement Rejected`}
          <img src={RejectedIcon} width="24px" style={{ marginLeft: "5px" }} />
        </Typography>
      </Tooltip>

      <StyledDialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          {isReturn ? `Request for a Return Rejected` : `Request for a Replacement Rejected`}
        </DialogTitle>

        <DialogContent>
          <DialogContentText color="white">
            {isReturn &&
              ` 
            Your requested for a return of ${titleCase(product.productShortName)}
            rejected due to following reason.`}
            {isReplacement &&
              ` 
            Your requested for a replace of ${titleCase(product.productShortName)}
            rejected due to following reason.`}
          </DialogContentText>
          <Stack
            justifyContent="center"
            alignItems="center"
            direction="row"
            spacing={1.5}
            m={2}
            sx={{ padding: "20px", height: "50px" }}
          >
            <Box
              component={"img"}
              src={product.image}
              sx={{ width: "100px", height: "90px", objectFit: "contain" }}
            />
            <Typography
              flex={5}
              sx={{
                color: "#FFFFFF",
                fontFamily: "inter",
                fontWeight: 500,
                fontSize: "14px",
                width: "100%",
                overflow: "ellipsis",
                wordBreak: "break-word",
              }}
            >
              {product?.productShortName}.
            </Typography>
          </Stack>
          <TextField
            focused
            margin="dense"
            id="name"
            label="Reasons"
            type="text"
            fullWidth
            variant="standard"
            value={isReturn ? product?.returnComment : product.replacementComment}
            InputProps={{
              readOnly: true,
            }}

            // value={product.reason}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={handleClose}>Ok</Button>
        </DialogActions>
      </StyledDialog>
    </div>
  );
}

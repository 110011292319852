import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Stack, styled, Typography } from "@mui/material";
import { ReplayCircleFilledOutlined } from "@mui/icons-material";
import moment from "moment";
import { titleCase } from "../../utils/stringStyle";
import returnIcon from "../../assets/icon/return.png";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-hot-toast";
import axios from "axios";

const StyledDialog = styled(Dialog)`
  & .MuiDialog-paper {
    background-color: #1D2326 !important;
  }
`;

export default function ReplacementDialog({ product, orderId, getAllOrders }) {
  const [open, setOpen] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [replacementReason, setReplacementReason] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleApplyReplacement = async () => {
    setIsSubmitting(true);
    try {
      await axios
        .post(`${process.env.REACT_APP_API_URL}/order/user/request-replacement`, {
          orderId: orderId,
          invoiceId: product._id,
          replacementReason,
        })
        .then((res) => toast.success(res.data.message))
        .catch((err) => toast.error(err.response.data.message))
        .finally(() => {
          setIsSubmitting(false);
          handleClose();
          getAllOrders();
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Typography
        variant="body1"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "4px",
          flexDirection: { mobile: "column", xs: "column", md: "row" },
          fontSize: { mobile: "column", xs: "12px", md: "16px" },
          textAlign: "center",
          cursor: "pointer",
        }}
        onClick={handleClickOpen}
      >
        Replacement Valid Till {moment(product.replacementValidTill).format("DD-MM-YYYY")}
        <img src={returnIcon} width="30px" style={{ marginLeft: "10px" }} />
      </Typography>
      <StyledDialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Typography variant="h6">Request for a replacement</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText color="white">
            You are requested to replacement {titleCase(product.productShortName)}.
          </DialogContentText>
          <Stack
            justifyContent="center"
            alignItems="center"
            direction="row"
            spacing={1.5}
            m={2}
            sx={{ padding: "20px", height: "50px" }}
          >
            <Box
              component={"img"}
              src={product.image}
              sx={{ width: "100px", height: "90px", objectFit: "contain" }}
            />
            <Typography
              flex={5}
              sx={{
                color: "#FFFFFF",
                fontFamily: "inter",
                fontWeight: 500,
                fontSize: "14px",
                width: "100%",
                overflow: "ellipsis",
                wordBreak: "break-word",
              }}
            >
              {product?.productShortName}.
            </Typography>
          </Stack>
          <TextField
            focused
            margin="dense"
            id="name"
            label="Enter your reason"
            // type="text"
            fullWidth
            variant="standard"
            onChange={(e) => setReplacementReason(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton onClick={handleApplyReplacement} loading={isSubmitting}>
            Request
          </LoadingButton>
        </DialogActions>
      </StyledDialog>
    </div>
  );
}

import numeral from "numeral";

export function titleCase(str) {
  return str
    .toLowerCase()
    .split(" ")
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
}

export function fCurrency(number) {
  const format = number ? numeral(number).format("0,0") : "0 ";
  return format;
}

export function formatCase(str, type = "sentence") {
  if (type === "sentence") {
    if (str === null || str === "") return false;
    else str = str.toString();
    return str.replace(/(\w)([^\s-]*)/g, function (match, firstChar, restOfWord) {
      return firstChar.toUpperCase() + restOfWord.toLowerCase();
    });
  } else if (type === "upper") {
    return str.toUpperCase();
  } else if (type === "lower") {
    return str.toLowerCase();
  }
}

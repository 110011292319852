import React, { useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { Stack, Typography, Box, Container } from "@mui/material";

export default function VerifyRegister() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [token, setToken] = useState(searchParams.get("token"));
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);

  const verifyEmail = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/user/register/verificationLink`,
        { token }
      );
      setMessage(res.data.message);
      setIsError(res.data.isError);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setMessage("Failed to verify your email. Please try again");
      setIsError(true);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    verifyEmail();
  }, []);

  return (
    <Box sx={{ width: "100%", bgcolor: "white" }}>
      <Container maxWidth="xl">
        <Stack justifyContent="center" alignItems="center">
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{
              width: "700px",
              bgcolor: "#1D2326",
              margin: "50px",
              borderRadius: "30px",
              paddingX: "100px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "inter",
                fontWeight: 400,
                fontSize: "14px",
                color: "#01FFFF",
                marginY: "80px",
              }}
            >
              {isLoading && <> Please wait while we are verifying your email...</>}
              {!isLoading && <>{message}.</>}
              {!isError && <Link to="/login">&nbsp; Please Login</Link>}
              {isError && <Link to="/register">&nbsp; Try Again</Link>}
            </Typography>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
}

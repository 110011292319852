import { Box, Button, CardMedia, Container, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import nvidiaLogo from "../../../assets/img/nvidia.png";
import Outlet1 from "../../../assets/img/outlet1.png";
import arrowLeft from "../../../assets/img/arrowLeft.png";
import arrowRight from "../../../assets/img/arrowRight.png";
import { formatCase } from "../../../utils/stringStyle";
import background from "../../../assets/img/outletBackground.png";
import ImageCard from "../VisitOutlet/ImageCard";
import outlet1 from "../../../assets/img/outletGrid2.png";
import outlet2 from "../../../assets/img/outletGrid4.png";
import { Call } from "@mui/icons-material";

const PromoSection = () => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${background})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        backgroundPosition: "center",
        paddingBottom: {
          mobile: "30px",
          xs: "30px",
          sm: "40px",
          md: "45px",
          lg: "50px",
          xl: "50px",
        },
      }}
    >
      <Container maxWidth="lg">
        <Stack
          justifyContent={"center"}
          alignItems={"center"}
          sx={{
            marginTop: {
              mobile: "30px",
              xs: "30px",
              sm: "40px",
              md: "45px",
              lg: "50px",
              xl: "50px",
            },
            width: "100%",
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={2}
            marginBottom={{
              mobile: "30px",
              xs: "30px",
              sm: "40px",
              md: "45px",
              lg: "50px",
              xl: "50px",
            }}
          >
            <Box
              component={"img"}
              src={arrowLeft}
              sx={{
                width: { mobile: "20px", xs: "24px", sm: "30px", md: "36px", lg: "40px" },
                height: "auto",
              }}
            />
            <Typography variant="sectionHead"> {formatCase("Our Outlet")}</Typography>
            <Box
              component={"img"}
              src={arrowRight}
              sx={{
                width: { mobile: "20px", xs: "24px", sm: "30px", md: "36px", lg: "40px" },
                height: "auto",
              }}
            />
          </Stack>
        </Stack>
        <Grid
          container
          spacing={{ mobile: 2, xs: 2, lg: 10, xl: 10 }}
          paddingX={2}
          sx={{}}
          rowGap={{ mobile: 2, xs: 2, sm: 2, md: 0 }}
        >
          <ImageCard outlet={outlet1} />
          <ImageCard outlet={outlet2} />
        </Grid>
        <Stack
          alignItems={"center"}
          justifyContent="center"
          p={2}
          marginY={{
            mobile: 1,
            xs: 1,
            sm: 2,
            md: 3,
            lg: 4,
          }}
          spacing={2}
        >
          <Typography variant="OutletHead">HiLITE MALL</Typography>
          <Typography
            variant="promoSectionContent"
            sx={{
              maxWidth: { mobile: "100%", xs: "100%", sm: "80%", md: 450, lg: 600 },
            }}
          >
            Lambda Gaming, Third Floor, HiLITE Mall, <br /> Kozhikode, Kerala, India, PIN - 673014,
            <br />
            {/* Phone +91 73560 33055 Mail: support@lambdagaming.com, <br /> */}
          </Typography>
          <Button
            endIcon={<Call />}
            sx={{
              marginTop: "20px",
              backgroundColor: "#01FFFF",
              color: "#1b1b1b",
              height: { mobile: "26px", xs: "28px", sm: "30px", md: "36px", lg: "38px" },
              borderRadius: { mobile: "5px", xs: "6px", sm: "8px", md: "9px", lg: "10px" },
              "&:hover": {
                backgroundColor: "#01FFFF",
              },
              fontFamily: "Poppins",
              fontWeight: 600,
              fontSize: { mobile: "14px", xs: "16px", sm: "17px", md: "18px", lg: "19px" },
              textTransform: "none",
            }}
          >
            Contact Us
          </Button>
        </Stack>
      </Container>
    </Box>
  );
};

export default PromoSection;

import { Box, Container, Stack, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Slider1 from "./Slider";
import { formatCase } from "../../../utils/stringStyle";

export default function LatestDeals({ productType }) {
  const [latestDeals, setLatestDeals] = useState();
  useEffect(() => {
    getLatestProducts();
  }, [productType]);

  const getLatestProducts = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/products/user/all`, {
        params: { latestDeal: true, productType },
      })
      .then((res) => {
        console.log("deals", res);
        setLatestDeals(res.data.data.products);
      })
      .catch((err) => console.log(err));
  };
  return (
    <Container maxWidth="xl">
      <Box
        paddingY={{ mobile: "30px", xs: "50px", sm: "70px", md: "80px", lg: "90px", xl: "100px" }}
      >
        <Stack direction="column" alignItems="center" justifyContent="center" spacing={3}>
          <Box
            sx={{
              border: "2px solid #01FFFF",
              borderRadius: "5px",
              padding: "5px 10px",
              bgcolor: "#1D2326",
              boxShadow: "0px 0px 32px rgba(1, 255, 255, 0.25)",
              marginBottom: {
                mobile: "20px",
                xs: "25px",
                sm: "30px",
                md: "35px",
                lg: "40px",
                xl: "45px",
              },
            }}
          >
            <Typography variant="h2">{formatCase("LATEST DEALS")}</Typography>
          </Box>
        </Stack>
        <Box sx={{ height: { mobile: "480px", xs: "480px", sm: "300px" } }}>
          <Slider1 latestDeals={latestDeals} />
        </Box>
      </Box>
    </Container>
  );
}

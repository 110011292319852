import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  breakpoints: {
    values: {
      mobile: 0,
      xs: 420,
      sm: 600,
      md: 920,
      lg: 1300,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: "#00FFFF",
    },
    secondary: {
      main: "#222222",
    },
    neutral: {
      main: "#00FFFF",
      contrastText: "#fff",
    },
    text: {
      primary: "#ffff",
    },
  },
});

// typography variants

// for main heading navbar
theme.typography.h5 = {
  fontFamily: "Poppins",
  fontStyle: "Regular",

  [theme.breakpoints.down("md")]: {
    fontSize: "22px",
    fontWeight: "500",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "20px",
    fontWeight: "400",
  },
  palette: {
    primary: {
      main: "#00FFFF",
    },
    secondary: {
      main: "#222222",
    },
    neutral: {
      main: "#00FFFF",
      contrastText: "#fff",
    },
    text: {
      primary: "#ffff",
    },
    menuPopup: {
      backgroundColor: "#464646",
    },
  },
};

// for main heading banners
theme.typography.h1 = {
  fontFamily: "Microgramma",
  letterSpacing: "1px",

  [theme.breakpoints.up("xs")]: {
    fontSize: "28px",
    fontWeight: "500",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "40px",
    fontWeight: "500",
  },

  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "50px",
    fontWeight: "500",
  },
  [theme.breakpoints.between("lg", "xl")]: {
    fontSize: "70px",
    fontWeight: "500",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "80px",
    fontWeight: "500",
  },
  palette: {
    primary: {
      main: "#00FFFF",
    },
    secondary: {
      main: "#222222",
    },
    neutral: {
      main: "#00FFFF",
      contrastText: "#fff",
    },
    text: {
      primary: "#ffff",
    },
    menuPopup: {
      backgroundColor: "#464646",
    },
  },
};

//for sub heading sections

theme.typography.h2 = {
  fontFamily: "Microgramma",
  fontStyle: "normal",
  letterSpacing: "2px",
  [theme.breakpoints.up("mobile")]: {
    fontSize: "22px",
  },
  [theme.breakpoints.up("xs")]: {
    fontSize: "25px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "29px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "36px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "38px",
  },
  palette: {
    primary: {
      main: "#00FFFF",
    },
    secondary: {
      main: "#222222",
    },
    neutral: {
      main: "#00FFFF",
      contrastText: "#fff",
    },
    text: {
      primary: "#ffff",
    },
    menuPopup: {
      backgroundColor: "#464646",
    },
  },
};

// sub of sub heading product name slider

theme.typography.h3 = {
  fontFamily: "Roboto",
  fontStyle: "normal",
  letterSpacing: "2px",
  [theme.breakpoints.down("md")]: {
    fontSize: "18px",
    fontWeight: "500",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "26px",
    fontWeight: "500",
  },
  palette: {
    primary: {
      main: "#00FFFF",
    },
    secondary: {
      main: "#222222",
    },
    neutral: {
      main: "#00FFFF",
      contrastText: "#fff",
    },
    text: {
      primary: "#ffff",
    },
    menuPopup: {
      backgroundColor: "#464646",
    },
  },
};

// product name card
theme.typography.h4 = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  letterSpacing: "1px",
  [theme.breakpoints.down("md")]: {
    fontSize: "18px",
    fontWeight: "400",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "20px",
    fontWeight: "400",
  },
  palette: {
    primary: {
      main: "#00FFFF",
    },
    secondary: {
      main: "#222222",
    },
    neutral: {
      main: "#00FFFF",
      contrastText: "#fff",
    },
    text: {
      primary: "#ffff",
    },
    menuPopup: {
      backgroundColor: "#464646",
    },
  },
};

// for body section
theme.typography.body1 = {
  fontFamily: "Poppins",
  fontStyle: "regular",
  letterSpacing: "1px",
  [theme.breakpoints.down("md")]: {
    fontSize: "16px",
    fontWeight: "500",
  },

  [theme.breakpoints.up("md")]: {
    fontSize: "18px",
    fontWeight: "500",
  },
  palette: {
    primary: {
      main: "#00FFFF",
    },
    secondary: {
      main: "#222222",
    },
    neutral: {
      main: "#00FFFF",
      contrastText: "#fff",
    },
    text: {
      primary: "#ffff",
    },
    menuPopup: {
      backgroundColor: "#464646",
    },
  },
};

// for price

theme.typography.body2 = {
  fontFamily: "Poppins",
  fontStyle: "regular",
  letterSpacing: "1px",
  [theme.breakpoints.up("mobile")]: {
    fontSize: "12px",
    fontWeight: "400",
  },
  [theme.breakpoints.up("xs")]: {
    fontSize: "13px",
    fontWeight: "400",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "15px",
    fontWeight: "400",
  },

  [theme.breakpoints.up("md")]: {
    fontSize: "18px",
    fontWeight: "400",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "22px",
    fontWeight: "400",
  },

  palette: {
    primary: {
      main: "#00FFFF",
    },
    secondary: {
      main: "#222222",
    },
    neutral: {
      main: "#00FFFF",
      contrastText: "#fff",
    },
    text: {
      primary: "#ffff",
    },
    menuPopup: {
      backgroundColor: "#464646",
    },
  },
};

// for subtitle product description

theme.typography.subtitle1 = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  letterSpacing: "1px",
  lineHeight: "25px",
  fontWeight: 300,
  [theme.breakpoints.up("mobile")]: {
    fontSize: "11px",
    lineHeight: "18px",
  },
  [theme.breakpoints.up("xs")]: {
    fontSize: "13px",
    lineHeight: "18px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "14px",
    lineHeight: "19px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "15px",
    lineHeight: "20px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "16px",
    lineHeight: "20px",
  },
  palette: {
    primary: {
      main: "#00FFFF",
    },
    secondary: {
      main: "#222222",
    },
    neutral: {
      main: "#00FFFF",
      contrastText: "#fff",
    },
    text: {
      primary: "#ffff",
    },
    menuPopup: {
      backgroundColor: "#464646",
    },
  },
};

// theme.typography.body1 = {
//   fontFamily: "Roboto",
//   fontStyle: "normal",
//   [theme.breakpoints.down("md")]: {
//     fontSize: "14px",
//   },
//   [theme.breakpoints.up("md")]: {
//     fontSize: "21px",
//   },
//   palette: {
//     primary: {
//       main: "#00FFFF",
//     },
//     secondary: {
//       main: "#222222",
//     },
//     neutral: {
//       main: "#00FFFF",
//       contrastText: "#fff",
//     },
//     text: {
//       primary: "#ffff",
//     },
//     menuPopup: {
//       backgroundColor: "#464646",
//     },
//   },
// };

theme.typography.new = {
  fontSize: "1rem",
  color: "yellow",
  fontFamily: "Poppins",
};

///// newly added
///// Home banner head
theme.typography.homeBannerHead = {
  fontFamily: "Microgramma",
  letterSpacing: "1px",
  fontWeight: "bold",
  [theme.breakpoints.up("mobile")]: {
    fontSize: "32px",
  },
  [theme.breakpoints.up("xs")]: {
    fontSize: "38px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "34px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "50px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "64px",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "80px",
  },
};

theme.typography.button = {
  fontFamily: "Poppins",
  fontStyle: "regular",
  fontWeight: 500,
  [theme.breakpoints.up("mobile")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.up("xs")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "17px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "18px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "19px",
  },
};

theme.typography.h6 = {
  fontFamily: "Poppins",
  fontStyle: "regular",
  letterSpacing: "1px",
  fontWeight: 500,
  [theme.breakpoints.up("mobile")]: {
    fontSize: "18px",
  },
  [theme.breakpoints.up("xs")]: {
    fontSize: "18px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "18px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "18px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "18px",
  },
};

theme.typography.homeBannerContent = {
  fontFamily: "Poppins",
  fontStyle: "regular",
  letterSpacing: "1px",
  fontWeight: 500,
  color: "#2E2E2E",
  [theme.breakpoints.between("xs", "sm")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "18px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "20px",
  },
};

theme.typography.promoSectionHead = {
  fontFamily: "Microgramma",
  fontStyle: "regular",
  letterSpacing: "1px",
  textAlign: "center",
  fontWeight: 400,
  color: "#01ffff",
  [theme.breakpoints.up("mobile")]: {
    fontSize: "22px",
  },
  [theme.breakpoints.up("xs")]: {
    fontSize: "25px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "29px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "38px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "45px",
  },
};

theme.typography.OutletHead = {
  fontFamily: "Microgramma",
  fontStyle: "regular",
  letterSpacing: "1px",
  textAlign: "center",
  fontWeight: 400,
  color: "#01ffff",
  [theme.breakpoints.up("mobile")]: {
    fontSize: "21px",
  },
  [theme.breakpoints.up("xs")]: {
    fontSize: "24px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "28px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "34px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "38px",
  },
};

theme.typography.promoSectionContent = {
  fontFamily: "Poppins",
  fontStyle: "regular",
  letterSpacing: "0.03em",

  color: "#fff",
  [theme.breakpoints.up("mobile")]: {
    fontSize: "14px",
    fontWeight: 400,
  },
  [theme.breakpoints.up("xs")]: {
    fontSize: "15px",
    fontWeight: 500,
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "17px",
    fontWeight: 500,
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "19px",
    fontWeight: 500,
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "20px",
    fontWeight: 500,
  },
};

theme.typography.cloudHead = {
  fontFamily: "Microgramma",
  fontStyle: "regular",
  fontWeight: 700,
  color: "#fff",
  lineHeight: "1.2",
  [theme.breakpoints.up("mobile")]: {
    fontSize: "20px",
  },
  [theme.breakpoints.up("xs")]: {
    fontSize: "28px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "36px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "40px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "48px",
  },
};

theme.typography.cloudContent = {
  fontFamily: "Poppins",
  fontStyle: "regular",

  color: "#fff",
  [theme.breakpoints.up("mobile")]: {
    fontSize: "11px",
    fontWeight: 400,
  },
  [theme.breakpoints.up("xs")]: {
    fontSize: "14px",
    fontWeight: 500,
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "15px",
    fontWeight: 500,
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "17px",
    fontWeight: 500,
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "18px",
    fontWeight: 500,
  },
};

theme.typography.sectionHead = {
  fontFamily: "Microgramma",
  letterSpacing: "2px",
  fontStyle: "regular",
  fontWeight: 700,
  color: "#fff",
  [theme.breakpoints.up("mobile")]: {
    fontSize: "24px",
  },
  [theme.breakpoints.up("xs")]: {
    fontSize: "26px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "28px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "32px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "38px",
  },
};

theme.typography.outletCardHead = {
  fontFamily: "Roboto",
  fontStyle: "regular",
  fontWeight: "bold",
  color: "#01ffff",
  [theme.breakpoints.up("mobile")]: {
    fontSize: "20px",
  },
  [theme.breakpoints.up("xs")]: {
    fontSize: "20px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "20px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "22px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "24px",
  },
};
theme.typography.outletCardContent = {
  fontFamily: "Poppins",
  fontStyle: "regular",
  fontWeight: 400,
  color: "#ffffff",
  [theme.breakpoints.up("mobile")]: {
    fontSize: "14px",
    lineHeight: "18px",
  },
  [theme.breakpoints.up("xs")]: {
    fontSize: "14px",
    lineHeight: "18px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "15px",
    lineHeight: "19px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "16px",
    lineHeight: "20px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "16px",
    lineHeight: "20px",
  },
};
theme.typography.newsCardHead = {
  fontFamily: "Poppins",

  [theme.breakpoints.up("mobile")]: {
    fontSize: "17px",
    fontWeight: 500,
  },
  [theme.breakpoints.up("xs")]: {
    fontSize: "18px",
    fontWeight: 600,
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "19px",
    fontWeight: 600,
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "20px",
    fontWeight: 600,
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "22px",
    fontWeight: 600,
  },
};
theme.typography.newsCardContent = {
  fontFamily: "Poppins",
  lineHeight: "20px",
  [theme.breakpoints.up("mobile")]: {
    fontSize: "13px",
    fontWeight: 400,
  },
  [theme.breakpoints.up("xs")]: {
    fontSize: "14px",
    fontWeight: 500,
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "15px",
    fontWeight: 500,
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "15px",
    fontWeight: 500,
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "16px",
    fontWeight: 500,
  },
};

theme.typography.gameLambdaContent = {
  fontFamily: "Poppins",
  [theme.breakpoints.up("mobile")]: {
    fontSize: "14px",
    fontWeight: 400,
  },
  [theme.breakpoints.up("xs")]: {
    fontSize: "15px",
    fontWeight: 400,
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "16px",
    fontWeight: 500,
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "17px",
    fontWeight: 500,
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "18px",
    fontWeight: 500,
  },
};

theme.typography.selectLabel = {
  fontFamily: "Roboto",
  fontStyle: "regular",

  color: "#ffffff",
  [theme.breakpoints.up("mobile")]: {
    fontSize: "11px",
    lineHeight: "18px",
    fontWeight: 400,
  },
  [theme.breakpoints.up("xs")]: {
    fontSize: "13px",
    lineHeight: "18px",
    fontWeight: 500,
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "14px",
    lineHeight: "19px",
    fontWeight: 500,
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "15px",
    lineHeight: "20px",
    fontWeight: 500,
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: 500,
  },
};

theme.typography.footerText = {
  fontFamily: "Poppins",
  [theme.breakpoints.up("mobile")]: {
    fontSize: "13px",
    fontWeight: 400,
    lineHeight: "24px",
  },
  [theme.breakpoints.up("xs")]: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "25px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "26px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "28px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "30px",
  },
};

theme.typography.contactUsText = {
  fontFamily: "Poppins",
  [theme.breakpoints.up("mobile")]: {
    fontSize: "11px",
    fontWeight: 400,
  },
  [theme.breakpoints.up("xs")]: {
    fontSize: "12px",
    fontWeight: 500,
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "14px",
    fontWeight: 500,
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "15px",
    fontWeight: 500,
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "16px",
    fontWeight: 500,
  },
};

theme.typography.footerHead = {
  fontFamily: "Poppins",
  lineHeight: "20px",
  [theme.breakpoints.up("mobile")]: {
    fontSize: "16px",
    fontWeight: 500,
  },
  [theme.breakpoints.up("xs")]: {
    fontSize: "17px",
    fontWeight: 600,
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "18px",
    fontWeight: 600,
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "18px",
    fontWeight: 600,
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "18px",
    fontWeight: 600,
  },
};

export default theme;

import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import Logout from "@mui/icons-material/Logout";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import {
  AccountCircle,
  FavoriteRounded,
  ArrowDropDown,
  ArrowDropUp,
  KeyboardArrowDown,
} from "@mui/icons-material";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Box, Typography, Button } from "@mui/material";
import { formatCase } from "../../utils/stringStyle";

export default function StoreMenu() {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const type = searchParams.get("type") || "";
  const [currentType, setCurrentType] = React.useState(type);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    setCurrentType(type);
  }, [type]);

  return (
    <>
      <Typography
        style={{
          fontFamily: "Roboto, sans-serif",
          fontWeight: 600,
          fontSize: "18px",
          color: "#01FFFF",
          display: "flex",
          alignItems: "center",
        }}
        onClick={handleClick}
      >
        STORE{" "}
        {
          <KeyboardArrowDown
            sx={{
              margin: "0 auto",
              padding: 0,
              fontWeight: "bold",
              ...(open && { transform: "rotate(180deg)" }),
              transition: "0.3s",
            }}
          />
        }
      </Typography>
      <Menu
        anchorEl={anchorEl}
        id="basic-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            bgcolor: "#1D2326",
            color: "#01ffff",
            fontWeight: 600,
            width: "150px",
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
          },
        }}
      >
        <MenuItem
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "initial",
            "&.Mui-selected": {
              backgroundColor: "#464646", // Customize the selected background color
            },
          }}
          onClick={() => {
            navigate("/store?type=brand-new");
            handleClose();
          }}
          selected={type === "brand-new"}
        >
          {formatCase("brand-new")}
        </MenuItem>

        <MenuItem
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "initial",
            fontWeight: 600,
            "&.Mui-selected": {
              backgroundColor: "#464646", // Customize the selected background color
            },
          }}
          onClick={() => {
            navigate("/store?type=pre-owned");
            handleClose();
          }}
          selected={type === "pre-owned"}
        >
          {formatCase(" PRE-OWNED")}
        </MenuItem>
      </Menu>
    </>
  );
}

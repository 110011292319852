import { Stack } from "@mui/material";
import React from "react";
import ShopByCategory from "../../components/StoreSections/ShopByCategory/ShopByCategory";
import Banner from "../../components/StoreSections/Banner/Banner";
// import ProductBrand from "../../components/StoreSections/ProductBrand/ProductBrand";
import DeliverySupport from "../../components/StoreSections/DeliverySupport/DeliverySupport";
import PromoBanner from "../../components/StoreSections/PromoBanner/PromoBanner";
import LatestDeals from "../../components/StoreSections/LatestDeals/LatestDeals";
import NewArrivals from "../../components/StoreSections/NewArrivals/NewArrivals";
import RefurbishedBanner from "../../components/StoreSections/Refurbished/RefurbishedBanner";
import OfferBanner from "../../components/StoreSections/OfferBanner/OfferBanner";
import Page from "../../utils/Page";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import ProductBrand from "../../components/CategorySections/ProductBrand/ProductBrand";
import { useSearchParams } from "react-router-dom";

export default function Store() {
  const { pathname, search } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const productType = searchParams.get("type");
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (pathname === "/store" && !productType) {
      navigate("/store?type=brand-new");
    }
  }, [pathname, search, navigate]);

  return (
    <Page title="Store">
      <Stack direcrion="row" justifyContent="center" alignItems="center">
        <Banner />
        <ShopByCategory productType={productType} />
        <PromoBanner productType={productType} />
        <LatestDeals productType={productType} />
        {/* <RefurbishedBanner /> */}
        <OfferBanner productType={productType} />
        <NewArrivals productType={productType} />

        <DeliverySupport />
        <ProductBrand />
      </Stack>
    </Page>
  );
}

import { AccountBox, Facebook, Instagram, Twitter } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import facebook from "../../assets/icon/facebbook.png";
import instagram from "../../assets/icon/instagram.png";
import twitter from "../../assets/icon/twitter.png";
import discord from "../../assets/icon/discord.png";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <Stack
      sx={{
        backgroundColor: "#000000",
        paddingTop: {
          mobile: "60px",
          xs: "70px",
          sm: "80px",
          md: "90px",
          lg: "100px",
          xl: "110px",
        },
      }}
    >
      <Stack
        direction={{ mobile: "column", xs: "column", sm: "column", md: "row", lg: "row" }}
        sx={{
          paddingX: {
            mobile: "10px",
            xs: "10px",
            sm: "40px",
            md: "70px",
            lg: "95px",
            xl: "109px",
          },
          bgcolor: "black",
          paddingY: "28px",
        }}
        spacing={5}
        justifyContent="space-between"
      >
        {/* left  */}
        <Stack direction="column" flex={1} spacing={2}>
          <Stack
            direction={{ mobile: "row", xs: "row", sm: "row", md: "row", lg: "row" }}
            justifyContent="space-between"
            alignItems="center"
            sx={{ maxWidth: { mobile: "100%", xs: "100%", sm: "400px" } }}
          >
            <Stack
              sx={{
                width: {
                  mobile: "100px",
                  xs: "110px",
                  sm: "120px",
                  md: "130px",
                  lg: "146px",
                  xl: "146px",
                },
                height: {
                  mobile: "100px",
                  xs: "110px",
                  sm: "120px",
                  md: "130px",
                  lg: "146px",
                  xl: "146px",
                },
              }}
            >
              <img style={{ objectFit: "contain" }} src="/images/footerLogo.png" alt="FooterLogo" />
            </Stack>
            <Stack
              direction="column"
              spacing={{
                mobile: 1,
                xs: 1,
                sm: 2,
                md: 2,
                lg: 3,
                xl: 3,
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: {
                    mobile: "14px",
                    xs: "14px",
                    sm: "15px",
                    md: "16px",
                    lg: "18px",
                  },
                  fontWeight: 400,
                }}
              >
                JOIN US
              </Typography>
              <Stack direction="row" spacing={2}>
                {/* <Instagram sx={{ color: "white" }} /> */}
                <Box
                  component={"img"}
                  src={instagram}
                  alt="instagram"
                  sx={{
                    cursor: "pointer",
                    height: {
                      mobile: "20px",
                      xs: "25px",
                      sm: "28px",
                      md: "30px",
                      lg: "34px",
                      xl: "34px",
                    },
                  }}
                  onClick={() => {
                    window.open("https://www.instagram.com/lambda.gaming", "_blank");
                  }}
                />
                <Box
                  component={"img"}
                  src={facebook}
                  alt="facebook"
                  sx={{
                    cursor: "pointer",
                    height: {
                      mobile: "20px",
                      xs: "25px",
                      sm: "28px",
                      md: "30px",
                      lg: "34px",
                      xl: "34px",
                    },
                  }}
                  onClick={() => {
                    window.open("https://www.facebook.com/zonelambda", "_blank");
                  }}
                />
                <Box
                  component={"img"}
                  src={twitter}
                  alt="twitter"
                  sx={{
                    cursor: "pointer",
                    height: {
                      mobile: "20px",
                      xs: "25px",
                      sm: "28px",
                      md: "30px",
                      lg: "34px",
                      xl: "34px",
                    },
                  }}
                  onClick={() => {
                    window.open("https://twitter.com/lambda_gaming", "_blank");
                  }}
                />
                {/* <Box component={"img"} src={discord} alt="discord" style={{ cursor: "pointer" }} /> */}
              </Stack>
            </Stack>
          </Stack>

          <Stack>
            <Typography
              variant="footerText"
              style={{
                fontFamily: "Poppins",
                fontWeight: 500,
              }}
            >
              Discover the ultimate gaming experience with Lambda Gaming and stay up to date with
              the latest news and updates in the gaming industry. At Lambda Gaming, we are dedicated
              to providing you with the best gaming experience possible. We stay on top of the
              latest trends and innovations in the gaming industry, so you can be sure you're
              getting the most up-to-date gaming experience.
            </Typography>
          </Stack>
        </Stack>
        {/* left end  */}

        {/* right  */}
        <Stack direction="column" flex={1} justifyContent="end" alignItems="start" spacing={3}>
          <Stack direction="column" spacing={2}>
            <Typography
              variant="footerHead"
              style={{
                textAlign: "left",
                color: "#01FFFF",
              }}
            >
              CONTACT US
            </Typography>
            <Stack sx={{ color: "white" }}>
              <Typography
                style={{
                  fontFamily: "Poppins",
                  fontWeight: 600,
                }}
                variant="footerText"
              >
                Mail: support@lambdagaming.com
              </Typography>
              <Typography
                style={{
                  fontFamily: "Poppins",
                  fontWeight: 600,
                }}
                variant="footerText"
              >
                Phone +91 73560 33055
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="column" spacing={2}>
            <Typography
              variant="footerHead"
              style={{
                textAlign: "left",
                color: "#01FFFF",
              }}
            >
              VISIT US
            </Typography>
            <Stack sx={{ color: "white" }}>
              <Typography
                style={{
                  fontFamily: "Poppins",
                  fontWeight: 600,
                }}
                variant="footerText"
              >
                Lambda Gaming, Third Floor, HiLITE Mall, Kozhikode, Kerala, India, PIN - 673014
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        {/* right end  */}
      </Stack>
      <Stack
        direction="row"
        sx={{
          paddingX: {
            mobile: "10px",
            xs: "10px",
            sm: "40px",
            md: "70px",
            lg: "95px",
            xl: "109px",
          },
          bgcolor: "black",
          paddingTop: "28px",
          width: "100%",
          paddingBottom: "78px",
        }}
        alignItems={"center"}
        spacing={4}
        pt={2}
      >
        <Link style={{ color: "#fff" }} to="#">
          <Typography
            style={{
              fontFamily: "Poppins",
              fontWeight: 600,
            }}
            variant="footerText"
          >
            {" "}
            Privacy Policy
          </Typography>
        </Link>
        <Link style={{ color: "#fff" }} to="#">
          <Typography
            style={{
              fontFamily: "Poppins",
              fontWeight: 600,
            }}
            variant="footerText"
          >
            {" "}
            Terms & Conditions
          </Typography>
        </Link>
      </Stack>
    </Stack>
  );
}

import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import useResponsive from "../../../utils/useResponsive";
import HomeMainText from "./HomeMainText";
import HomeBanner from "../../../assets/img/HomeMain.jpg"
import HomeBannerMobile from "../../../assets/img/HomeMainMobile.jpg"

export default function HomeMainImage() {
  const mdDown = useResponsive("up", "lg");

  return (
    <Stack
      sx={{
        width: "100%",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        position: "relative",
        flexDirection: mdDown ? "column" : "row", 
        height: "auto",
      }}
    >
      <Box
        component={"img"}
        src={!mdDown ? HomeBannerMobile : HomeBanner}
        sx={{
          width: "100%",
          height: "auto",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          left: !mdDown ? "3%" : "10%",
          top: "0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          maxWidth: {
            mobile: "100%",
            xs: "100%",
            sm: "100%",
            md: "700px",
            lg: "900px",
            xl: "1100px",
          },
          height: "100%",
        }}
      >
        <HomeMainText />
      </Box>
    </Stack>
  );
}

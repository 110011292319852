import { ArrowDropDown, CloseSharp, ExpandMore, SearchSharp } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Stack,
  Menu,
  MenuItem,
  Button,
  Pagination,
  Typography,
  Checkbox,
  FormControl,
  FormGroup,
  FormControlLabel,
  Divider,
  Radio,
  RadioGroup,
} from "@mui/material";
import axios from "axios";
import React from "react";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import useResponsive from "../../utils/useResponsive";
import ProductCard from "../ProductCard/ProductCard";
import { formatCase } from "../../utils/stringStyle";

export default function ProductsSection({ category, categories }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const categoryId = searchParams.get("tab") || "all";
  const productType = searchParams.get("type") || "brand-new";
  const searchName = searchParams.get("name");
  console.log(searchName);
  const offer = searchParams.get("offer");
  const uninterceptedAxiosInstance = axios.create();
  const [products, setProducts] = useState([]);
  const [options, setOptions] = useState({
    page: 0,
    size: 9,
    category: categoryId,
    productType: productType,
    brands: [],
    offerIds: offer ? [offer] : [],
    name: "",
    search: searchName,
  });

  const [categoryCount, setCategoryCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const inputRef = useRef();
  const [brands, setBrands] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [offers, setOffers] = useState([]);
  const [selectedOffers, setSelectedOffers] = useState([]);
  const [priceSort, setPriceSort] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [anchorEl3, setAnchorEl3] = React.useState(null);
  const open = Boolean(anchorEl);
  const open1 = Boolean(anchorEl1);
  const open2 = Boolean(anchorEl2);
  const open3 = Boolean(anchorEl3);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClick3 = (event) => {
    setAnchorEl3(event.currentTarget);
  };
  const handleClose3 = () => {
    setAnchorEl3(null);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const handleCategoryChange = (e, id) => {
    const temp = { tab: id, type: productType };
    if (searchName) {
      temp.name = searchName;
    }
    setSearchParams(temp);
    setOptions({ ...options, brands: [], offerIds: [] });
    setAnchorEl3(null);
  };

  const getProducts = async (options) => {
    try {
      setIsLoading(true);
      const res = await uninterceptedAxiosInstance.get(
        `${process.env.REACT_APP_API_URL}/products/user/all`,
        { params: options }
      );
      // console.log("products", res);
      if (!res.data.isError) {
        setProducts(res.data.data.products);
        setCategoryCount(res.data.data.maxRecords);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const getBrands = async () => {
    try {
      setBrands([]);
      setSelectedBrands([]);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/brand/user/product-category`, {
        params: { category: categoryId, productType },
      });
      if (!res.data.isError) {
        setBrands(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleBrandCheckBoxChange = (e, brandId) => {
    let temp = { ...options };
    if (e.target.checked) {
      setSelectedBrands([...selectedBrands, brandId]);
      temp = { ...options, brands: [...selectedBrands, brandId] };
    } else if (!e.target.checked) {
      setSelectedBrands(selectedBrands.filter((id) => id !== brandId));
      temp = {
        ...options,
        brands: selectedBrands.filter((id) => id !== brandId),
      };
    }
    handleClose();
    setOptions(temp);
    getProducts(temp);
  };

  const getOffers = async () => {
    try {
      setOffers([]);
      setSelectedOffers([]);
      const res = await uninterceptedAxiosInstance.get(
        `${process.env.REACT_APP_API_URL}/offer/user/all`,
        { params: { productType } }
      );
      console.log("offersssss", res);
      if (!res.data.isError) {
        setOffers(res.data.data.offers);
        if (offer) {
          const selectedOffer = res.data.data.offers.find((o) => o._id === offer);
          if (selectedOffer) {
            setSelectedOffers([offer]);
            setOptions({ ...options, offerIds: [offer] });
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOfferCheckBoxChange = (e, offerId) => {
    let temp = { ...options };
    if (e.target.checked) {
      setSelectedOffers([...selectedOffers, offerId]);
      temp = { ...options, offerIds: [...selectedOffers, offerId] };
    } else if (!e.target.checked) {
      setSelectedOffers(selectedOffers.filter((id) => id !== offerId));
      temp = {
        ...options,
        offerIds: selectedOffers.filter((id) => id !== offerId),
      };
    }
    handleClose1();
    setOptions(temp);
    getProducts(temp);
  };

  const handlePriceSortCheckBoxChange = (e) => {
    let temp = { ...options };
    if (e.target.name === "desc" && e.target.checked) {
      setPriceSort("-1");
      temp = { ...options, sort: "-1" };
    } else if (e.target.name === "asc" && e.target.checked) {
      setPriceSort("1");
      temp = { ...options, sort: "1" };
    } else if (e.target.name === "none" && e.target.checked) {
      setPriceSort("");
      temp = { ...options, sort: "" };
    } else {
      setPriceSort("");
      temp = { ...options, sort: "" };
    }
    handleClose2();
    setOptions(temp);
    getProducts(temp);
  };

  const handlePageChange = (e, page) => {
    const temp = { ...options, page: page - 1 };
    setOptions(temp);
    setAnchorEl(null);
    getProducts(temp);
  };

  useEffect(() => {
    const temp = {
      ...options,
      productType: productType,
      category: categoryId,
      search: searchName,
      offerIds: new Set([...options.offerIds, offer]),
    };
    setOptions({ ...temp, offerIds: Array.from(temp.offerIds) });

    getBrands();
    console.log("options", options);
    getProducts({ ...temp, offerIds: Array.from(temp.offerIds) });
    getOffers();
    setPriceSort("");
  }, [productType, categoryId, offer, searchName]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [products]);
  return (
    <>
      <Grid
        rowGap={{
          mobile: "20px",
          xs: "20px",
        }}
        container
        maxWidth="1300px"
        alignItems="center"
        justifyContent={"flex-start"}
        paddingX={{ mobile: 2, xs: 2, sm: 2, md: 5, lg: 5 }}
        spacing={3}
        sx={{
          marginBottom: {
            mobile: "40px",
            xs: "50px",
            sm: "60px",
            md: "70px",
            lg: "80px",
            xl: "100px",
          },
        }}
      >
        <Grid item mobile={12} mt={2}>
          <Stack
            my={{ mobile: 1, md: 1 }}
            direction={{ mobile: "column", xs: "column", sm: "column", md: "row" }}
            justifyContent="center"
            alignItems="center"
            spacing={3}
            sx={{ width: "100%" }}
          >
            <Stack
              flex={1}
              sx={{ height: "80px" }}
              direction="row"
              alignItems="center"
              justifyContent={{ mobile: "center", xs: "center", sm: "flex-end" }}
              spacing={{ mobile: 1, xs: 1, sm: 3, lg: 4, xl: 5 }}
              marginTop={{ mobile: 2, xs: 1, sm: 1, md: 0 }}
            >
              <Stack
                sx={{
                  cursor: "pointer",
                  color: "white",
                  backgroundColor: "#464646",
                  borderRadius: "3px",
                  // ...(selectedBrands.length > 0 && {
                  //   boxShadow: "0px 0px 2px 2px #01FFFF",
                  // }),
                }}
              >
                <Stack
                  onClick={handleClick3}
                  direction="row"
                  sx={{
                    width: {
                      mobile: "120px",
                      xs: "140px",
                      sm: "140px",
                      md: "140px",
                      lg: "150px",
                      xl: "150px",
                    },
                  }}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="selectLabel" sx={{ marginX: "5px", width: "100%" }}>
                    {formatCase(category)}
                  </Typography>
                  <Divider orientation="vertical" flexItem />
                  <Stack
                    sx={{ width: "25px", height: "100%" }}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <ExpandMore
                      sx={{ padding: 0, fontSize: { mobile: "16px", xs: "22px", sm: "24px" } }}
                    />
                  </Stack>
                </Stack>
                <Menu
                  sx={{
                    color: "black",
                    "& .MuiPaper-root": { backgroundColor: "#1D2326" },
                  }}
                  id="basic-menu"
                  anchorEl={anchorEl3}
                  open={open3}
                  onClose={handleClose3}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      bgcolor: "#1D2326",
                      width: "160px",
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                    },
                  }}
                >
                  <MenuItem
                    selected={category === "all"}
                    onClick={(e) => handleCategoryChange(e, "all")}
                  >
                    <Typography variant="selectLabel" sx={{ marginX: "5px", width: "100%" }}>
                      All
                    </Typography>
                  </MenuItem>
                  {!!categories.length &&
                    categories.map((item) => {
                      return (
                        <MenuItem
                          selected={item.name === category}
                          onClick={(e) => handleCategoryChange(e, item._id)}
                        >
                          <Typography variant="selectLabel" sx={{ marginX: "5px", width: "100%" }}>
                            {formatCase(item.name)}{" "}
                          </Typography>
                        </MenuItem>
                      );
                    })}
                </Menu>
              </Stack>

              <Stack
                sx={{
                  cursor: "pointer",
                  color: "white",
                  backgroundColor: "#464646",
                  borderRadius: "3px",
                  ...(selectedBrands.length > 0 && {
                    boxShadow: "0px 0px 2px 2px #01FFFF",
                  }),
                }}
              >
                <Stack
                  onClick={handleClick}
                  direction="row"
                  sx={{}}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="selectLabel" sx={{ marginX: "5px" }}>
                    Brand
                  </Typography>
                  <Divider orientation="vertical" flexItem />
                  <Stack
                    sx={{ width: "100%", height: "100%" }}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <ExpandMore
                      sx={{ padding: 0, fontSize: { mobile: "16px", xs: "22px", sm: "24px" } }}
                    />
                  </Stack>
                </Stack>
                <Menu
                  sx={{
                    color: "black",
                    "& .MuiPaper-root": { backgroundColor: "#1D2326" },
                  }}
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  {!!brands.length && (
                    <MenuItem sx={{ color: "#fff" }}>
                      <FormControl>
                        <FormGroup>
                          {brands.map((brand) => {
                            return (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    sx={{
                                      color: "#01FFFF",
                                      "&.Mui-checked": { color: "#01FFFF" },
                                    }}
                                    checked={selectedBrands.includes(brand._id)}
                                    onChange={(e) => handleBrandCheckBoxChange(e, brand._id)}
                                    name={brand.name}
                                  />
                                }
                                label={brand.name}
                              />
                            );
                          })}
                        </FormGroup>
                      </FormControl>
                    </MenuItem>
                  )}
                  {!brands.length && (
                    <MenuItem sx={{ color: "#fff" }}>
                      <Typography sx={{ fontFamily: "Roboto", fontWeight: 500 }}>
                        No Brands Found
                      </Typography>
                    </MenuItem>
                  )}
                </Menu>
              </Stack>

              <Stack
                sx={{
                  cursor: "pointer",
                  color: "white",
                  backgroundColor: "#464646",
                  borderRadius: "3px",
                  ...(selectedOffers.length > 0 && {
                    boxShadow: "0px 0px 2px 2px #01FFFF",
                  }),
                }}
              >
                <Stack
                  onClick={handleClick1}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="selectLabel" sx={{ fontSize: "10px", marginX: "5px" }}>
                    Offers
                  </Typography>
                  <Divider orientation="vertical" flexItem />
                  <Stack
                    sx={{ width: "100%", height: "100%" }}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <ExpandMore
                      sx={{ padding: 0, fontSize: { mobile: "16px", xs: "22px", sm: "24px" } }}
                    />
                  </Stack>
                </Stack>
                <Menu
                  sx={{
                    color: "black",
                    "& .MuiPaper-root": { backgroundColor: "#1D2326" },
                  }}
                  id="basic-menu"
                  anchorEl={anchorEl1}
                  open={open1}
                  onClose={handleClose1}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  {!!offers.length && (
                    <MenuItem sx={{ color: "#fff" }}>
                      <FormControl>
                        <FormGroup>
                          {offers.map((offer) => {
                            return (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    sx={{
                                      color: "#01FFFF",
                                      "&.Mui-checked": { color: "#01FFFF" },
                                    }}
                                    checked={selectedOffers.includes(offer._id)}
                                    onChange={(e) => handleOfferCheckBoxChange(e, offer._id)}
                                    name={offer.shortTitle}
                                  />
                                }
                                label={offer.shortTitle}
                              />
                            );
                          })}
                        </FormGroup>
                      </FormControl>
                    </MenuItem>
                  )}
                  {!offers.length && (
                    <MenuItem sx={{ color: "#fff" }}>
                      <Typography sx={{ fontFamily: "Roboto", fontWeight: 500 }}>
                        No Offers Available
                      </Typography>
                    </MenuItem>
                  )}
                </Menu>
              </Stack>
              <Stack
                sx={{
                  cursor: "pointer",
                  color: "white",
                  backgroundColor: "#464646",
                  borderRadius: "3px",
                  ...((priceSort === "1" || priceSort === "-1") && {
                    boxShadow: "0px 0px 2px 2px #01FFFF",
                  }),
                }}
              >
                <Stack
                  onClick={handleClick2}
                  direction="row"
                  sx={{}}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="selectLabel" sx={{ fontSize: "10px", marginX: "5px" }}>
                    {" "}
                    Sort
                  </Typography>
                  <Divider orientation="vertical" flexItem />
                  <Stack
                    sx={{ width: "100%", height: "100%" }}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <ExpandMore
                      sx={{ padding: 0, fontSize: { mobile: "16px", xs: "22px", sm: "24px" } }}
                    />
                  </Stack>
                </Stack>
                <Menu
                  sx={{
                    color: "black",
                    "& .MuiPaper-root": { backgroundColor: "#1D2326" },
                  }}
                  id="basic-menu"
                  anchorEl={anchorEl2}
                  open={open2}
                  onClose={handleClose2}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem sx={{ color: "#fff" }}>
                    <FormControl>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{
                                color: "#01FFFF",
                                "&.Mui-checked": { color: "#01FFFF" },
                              }}
                              checked={priceSort === "1"}
                              onChange={(e) => handlePriceSortCheckBoxChange(e)}
                              name={"asc"}
                            />
                          }
                          label={"Price Low to High"}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{
                                color: "#01FFFF",
                                "&.Mui-checked": { color: "#01FFFF" },
                              }}
                              checked={priceSort === "-1"}
                              onChange={(e) => handlePriceSortCheckBoxChange(e)}
                              name={"desc"}
                            />
                          }
                          label={"Price High to Low"}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{
                                color: "#01FFFF",
                                "&.Mui-checked": { color: "#01FFFF" },
                              }}
                              checked={!priceSort}
                              onChange={(e) => handlePriceSortCheckBoxChange(e)}
                              name={"none"}
                            />
                          }
                          label={"None"}
                        />
                      </FormGroup>
                    </FormControl>
                  </MenuItem>
                </Menu>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "50vh",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            {!!products.length &&
              products.map((item, key) => {
                return (
                  <Grid item key={key} lg={4} md={6} sm={6} xs={12} mobile={12}>
                    <Stack justifyContent="center" alignItems="center" sx={{ width: "100%" }}>
                      <ProductCard product={item} />
                    </Stack>
                  </Grid>
                );
              })}
            {!products.length && (
              <Grid item mobile={12}>
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  sx={{ width: "100%", height: "20vh" }}
                >
                  <Typography variant="body1" sx={{ fontSize: "22px" }}>
                    No Products Found
                  </Typography>
                </Stack>
              </Grid>
            )}
          </>
        )}
        <Grid item mobile={12}>
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{
              width: "100%",
              marginTop: {
                mobile: "20px",
                xs: "30px",
                sm: "40px",
                md: "60px",
                lg: "80px",
                xl: "80px",
              },
            }}
          >
            <Pagination
              variant="text"
              sx={{
                color: "white",
                "& .MuiPaginationItem-root": { color: "white" },
              }}
              color="primary"
              count={Math.ceil(categoryCount / options.size)}
              page={options.page + 1}
              onChange={handlePageChange}
            />
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}

import { MenuItem, Typography } from "@mui/material";
import React from "react";
import { formatCase } from "../../utils/stringStyle";
import { useLocation, useNavigate } from "react-router-dom";

export default function NavItem({ name, path, menu, menuItem }) {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <MenuItem
      onClick={() => {
        !menu && navigate(path);
      }}
      sx={{
        mr: 1,
        display: "block",
        border: "2px solid #000000",
        "&:hover": {
          transform: "scale(1.07)",
        },
        "&.Mui-selected": {
          borderBottom: "2px solid #01FFFF",
          backgroundColor: "#000000",
        },
      }}
      selected={(location.pathname.startsWith(path) && path !== "/") || location.pathname === path}
    >
      <Typography
        variant="h5"
        style={{
          fontFamily: "Roboto, sans-serif",
          fontWeight: 600,
          fontSize: "18px",
          color: "#01FFFF",
        }}
      >
        {menu ? menuItem : formatCase(name, "upper")}
      </Typography>
    </MenuItem>
  );
}

import { Box, CircularProgress, Container, Grid, Stack } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import CategoryCard from "../../CategoryCard/CategoryCard";
import allCategoryImg from "../../../assets/img/category.svg";

export default function ShopByCategory({ productType }) {
  const [categories, setCategories] = useState([]);
  const uninterceptedAxiosInstance = axios.create();
  const [isLoading, setIsLoading] = useState(true);

  const allCategoryImage = {
    fileUrl: allCategoryImg,
  };

  const allCategory = {
    name: "All Categories",
    image: allCategoryImage,
    _id: "all",
    productType: productType,
  };

  const getCategories = async () => {
    try {
      setIsLoading(true);
      const res = await uninterceptedAxiosInstance.get(
        `${process.env.REACT_APP_API_URL}/category/all/active`,
        {
          params: {
            productType,
          },
        }
      );
      console.log(res.data.data);
      if (!res.data.isError) {
        setCategories(res.data.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCategories();
  }, [productType]);

  return (
    <Box
      sx={{
        width: "100%",
        padding: "20px 0px",
      }}
    >
      <Container maxWidth="xl">
        <Stack direction="column" alignItems="center" justifyContent="center" spacing={3}>
          {/* <Typography
            sx={{
              fontFamily: "poppins",
              color: "#01FFFF",
              fontSize: "30px",
              fontWeight: 600,
            }}
          >
            Shop By Category
          </Typography> */}
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "50vh",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <Grid
              container
              direction="row"
              alignItems={"center"}
              rowGap={{
                mobile: "10px",
                xs: "15px",
                md: "30px",
                lg: "30px",
              }}
            >
              {categories.length &&
                categories.map((item, key) => (
                  <Grid item mobile={4} sm={4} md={3} lg={2.4} xl={2.4} key={key}>
                    <Stack justifyContent="center" alignItems="center" sx={{ width: "100%" }}>
                      <CategoryCard key={key} category={item} />
                    </Stack>
                  </Grid>
                ))}
              <Grid item mobile={4} sm={4} md={3} lg={2.4} xl={2.4}>
                <Stack justifyContent="center" alignItems="center" sx={{ width: "100%" }}>
                  <CategoryCard category={allCategory} />
                </Stack>
              </Grid>
            </Grid>
          )}
        </Stack>
      </Container>
    </Box>
  );
}

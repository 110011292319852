import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import DialogTitle from "@mui/material/DialogTitle";
import replacementIcon from "../../assets/icon/replacement.png";

import Slide from "@mui/material/Slide";
import { Box, Stack, styled, TextField, Typography } from "@mui/material";
import moment from "moment";
import { titleCase } from "../../utils/stringStyle";
import { toast } from "react-hot-toast";
import axios from "axios";
import { LoadingButton } from "@mui/lab";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledDialog = styled(Dialog)`
  & .MuiDialog-paper {
    background-color: #1D2326 !important;
  }
`;

export default function ReturnDialog({ product, orderId, getAllOrders }) {
  const [open, setOpen] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [returnReason, setReturnReason] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleApplyReturn = () => {
    setIsSubmitting(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/order/user/request-return`, {
        orderId: orderId,
        invoiceId: product._id,
        returnReason,
      })
      .then((res) => {
        toast.success(res.data.message);
        console.log("res", res);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        console.log("err", err);
      })
      .finally(() => {
        setIsSubmitting(false);
        handleClose();
        getAllOrders();
      });
  };

  return (
    <div>
      <Typography
        variant="body1"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "4px",
          flexDirection: { mobile: "column", xs: "column", md: "row" },
          fontSize: { mobile: "12px", xs: "12px", md: "16px" },
          textAlign: "center",
          cursor: "pointer",
        }}
        onClick={handleClickOpen}
      >
        Return Valid Till {""}
        {moment(product.returnValidTill).format("DD-MM-YYYY")}
        <img src={replacementIcon} width="28px" style={{ marginLeft: "10px" }} />
      </Typography>

      <StyledDialog
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Request for a return</DialogTitle>

        <DialogContent>
          <DialogContentText color="white">
            You are requested for a return of {titleCase(product.productShortName)}.
          </DialogContentText>
          <Stack
            justifyContent="center"
            alignItems="center"
            direction="row"
            spacing={1.5}
            m={2}
            sx={{ padding: "20px", height: "50px" }}
          >
            <Box
              component={"img"}
              src={product.image}
              sx={{ width: "100px", height: "90px", objectFit: "contain" }}
            />
            <Typography
              flex={5}
              sx={{
                color: "#FFFFFF",
                fontFamily: "inter",
                fontWeight: 500,
                fontSize: "14px",
                width: "100%",
                overflow: "ellipsis",
                wordBreak: "break-word",
              }}
            >
              {product?.productShortName}.
            </Typography>
          </Stack>
          <TextField
            focused
            margin="dense"
            id="name"
            label="Enter your reason"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => setReturnReason(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton loading={isSubmitting} onClick={handleApplyReturn}>
            Request
          </LoadingButton>
        </DialogActions>
      </StyledDialog>
    </div>
  );
}

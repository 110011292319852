import { Box, Typography } from "@mui/material";
import React from "react";
import { formatCase } from "../utils/stringStyle";
import preOwnedTag from "../assets/img/preOwnedTagR.png";

export default function PreOwnedTag() {
  return (
    <Box
      sx={{
        position: "relative",
        width: "120px",
        height: "30px",
        backgroundImage: `url(${preOwnedTag})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography
        sx={{
          fontSize: "16px",
          fontWeight: 400,
          width: "100%",
          fontFamily: "Anton, sans-serif",
          color: "#fff",
          zIndex: 1,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
        }}
      >
        {formatCase("pre-owned")}
      </Typography>
    </Box>
  );
}

import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import PreOwnedTag from "../PreOwnedTag";

export default function NewArrivalsCard({ product }) {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        width: { xs: "375px", mobile: "100%", sm: "350px" },
        height: "450px",
        bgcolor: "rgba(0, 0, 0, 0.4)",
        marginY: "20px",
        borderRadius: "20px",
      }}
    >
      <Stack
        direction="column"
        alignItems="center"
        spacing={2}
        sx={{
          height: "100%",
          width: "100%",
          position: "relative",
        }}
      >
        {product?.productType === "pre-owned" && (
          <Box
            sx={{
              position: "absolute",
              right: "15px",
              top: "14px",
              zIndex: 3,
            }}
          >
            <PreOwnedTag />
          </Box>
        )}
        <Stack>
          <img
            style={{
              objectFit: "contain",
              maxWidth: "300px",
              height: "283px",
              paddingTop: "35px",
            }}
            src={product.image.fileUrl}
            alt="monitor"
            loading="lazy"
          />
        </Stack>
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ paddingBottom: "40px", width: "100%" }}
        >
          <Typography
            variant="h4"
            sx={{
              width: "100%",
              whiteSpace: "nowrap",
              textAlign: "center",
              overflow: "hidden",
              textOverflow: "ellipsis",
              paddingX: "20px",
              fontFamily: "Roboto",
            }}
          >
            {product.shortName}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              width: "100%",
              whiteSpace: "nowrap",
              textAlign: "center",
              overflow: "hidden",
              textOverflow: "ellipsis",
              paddingX: "20px",
              fontFamily: "Roboto",
            }}
          >
            {product.shortDescription}
          </Typography>
          <Button
            sx={{
              marginTop: "20px",
              backgroundColor: "#01FFFF",
              color: "#000",
              width: "120px",
              height: "30px",
              borderRadius: "5px",
              "&:hover": {
                backgroundColor: "#01FFFF",
              },
            }}
            onClick={() => navigate(`/store/product/${product._id}`)}
          >
            Buy Now
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
}

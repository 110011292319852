import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import PreOwnedTag from "../../PreOwnedTag";

export default function LatestDealCard({ product }) {
  const navigate = useNavigate();
  return (
    <Stack
      direction={{ mobile: "column", xs: "column", sm: "row", md: "row", lg: "row", xl: "row" }}
      alignItems={{ mobile: "center", xs: "center", sm: "end", md: "end" }}
      sx={{
        width: {
          mobile: "250px",
          xs: "450px",
          sm: "450px",
          md: "450px",
          lg: "450px",
          xl: "450px",
        },
        height: {
          mobile: "400px",
          xs: "400px",
          sm: "200px",
          md: "200px",
          lg: "200px",
          xl: "200px",
        },
        marginX: "10px",
        marginY: "5px",
        position: "relative",
      }}
    >
      {product?.productType === "pre-owned" && (
        <Box
          sx={{
            position: "absolute",
            right: "15px",
            top: "14px",
            zIndex: 3,
          }}
        >
          <PreOwnedTag />
        </Box>
      )}
      <Box
        component={"img"}
        src={product.image.fileUrl}
        sx={{
          width: { mobile: "100%", xs: "100%", sm: "40%", md: "40%", lg: "40%", xl: "40%" },
          height: { mobile: "50%", xs: "50%", sm: "100%", md: "100%", lg: "100%", xl: "100%" },
          objectFit: "contain",
          marginTop: { mobile: "40px", xs: "40px", sm: "0px", md: "0px", lg: "0px", xl: "0px" },
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          width: { mobile: "100%", xs: "100%", sm: "60%", md: "60%", lg: "60%", xl: "60%" },
        }}
      >
        <Stack
          direction="column"
          alignItems={{
            mobile: "center",
            xs: "center",
            sm: "start",
            md: "start",
            lg: "start",
            xl: "start",
          }}
          justifyContent="center"
          sx={{ paddingY: "20px", paddingX: "20px", width: "100%" }}
          spacing={1}
        >
          <Typography
            variant="h4"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              textAlign: {
                mobile: "center",
                xs: "center",
                sm: "left",
                md: "left",
                lg: "left",
                xl: "left",
              },
              width: "100%",
            }}
          >
            {product.shortName}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              textAlign: {
                mobile: "center",
                xs: "center",
                sm: "left",
                md: "left",
                lg: "left",
                xl: "left",
              },
              width: "100%",
            }}
          >
            {product.shortDescription}
          </Typography>
          <Button
            sx={{
              backgroundColor: "#01FFFF",
              color: "#000",
              width: "120px",
              height: "30px",
              borderRadius: "5px",
              "&:hover": {
                backgroundColor: "#01FFFF",
              },
            }}
            onClick={() => navigate(`/store/product/${product._id}`)}
          >
            Buy Now
          </Button>
        </Stack>
      </Box>
    </Stack>
  );
}

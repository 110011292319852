import {
  Box,
  Button,
  Card,
  CardMedia,
  Divider,
  Grid,
  IconButton,
  Stack,
  Step,
  StepButton,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import React from "react";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import { styled } from "@mui/material/styles";
import Check from "@mui/icons-material/Check";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import DownloadIcon from "@mui/icons-material/Download";

import ReplayIcon from "@mui/icons-material/Replay";
import { titleCase } from "../../utils/stringStyle";
import moment from "moment";
import axios from "axios";
import FormDialog from "../Dialog/ReplacementDialog";
import AlertDialog from "../Dialog/ReturnDialog";
import { toast } from "react-hot-toast";
import ReasonDialog from "../Dialog/ReasonDialog";
import ReturnDialog from "../Dialog/ReturnDialog";
import ReplacementDialog from "../Dialog/ReplacementDialog";
import { useNavigate } from "react-router-dom";
import ApprovedImg from "../../assets/icon/approve.png";

const OrderCards = ({ order, getAllOrders }) => {
  const steps = ["PENDING", "SHIPPED", "DELIVERED"];
  const navigate = useNavigate();
  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: "calc(-50% + 16px)",
      right: "calc(50% + 16px)",
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#784af4",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#784af4",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
      borderTopWidth: 3,
      borderRadius: 1,
    },
  }));

  const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
    ...(ownerState.active && {
      color: "#784af4",
    }),
    "& .QontoStepIcon-completedIcon": {
      color: "#784af4",
      zIndex: 1,
      fontSize: 18,
    },
    "& .QontoStepIcon-circle": {
      width: 8,
      height: 8,
      borderRadius: "50%",
      backgroundColor: "currentColor",
    },
  }));

  function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <Check className="QontoStepIcon-completedIcon" />
        ) : (
          <div className="QontoStepIcon-circle" />
        )}
      </QontoStepIconRoot>
    );
  }

  const getInvoiceFile = (invoiceNumber) => {
    return order.invoiceFiles.find((invoiceFile) => invoiceFile.invoiceNumber === invoiceNumber);
  };

  console.log(getInvoiceFile());

  const handleDownload = (url) => {
    var link = document.createElement("a");
    link.href = url;
    link.download = "invoice";
    link.click();
  };
  return (
    <>
      <Card
        sx={{
          background: "#1D2326",
          border: "1px solid black",
          boxShadow: "0 0 10px #000",
        }}
      >
        <Stack
          p={2}
          direction={{ mobile: "column", xs: "column", md: "row" }}
          justifyContent={"space-between"}
        >
          <Box
            sx={{
              display: { mobile: "flex", xs: "flex", md: "block" },
              justifyContent: "space-between",
            }}
          >
            <Typography variant="body2" fontWeight="bold">
              Order ID
            </Typography>

            <Typography variant="body2">{order.orderId}</Typography>
          </Box>
          <Box
            sx={{
              display: { mobile: "flex", xs: "flex", md: "block" },
              justifyContent: "space-between",
            }}
          >
            <Typography variant="body2" fontWeight="bold">
              Date Placed
            </Typography>

            <Typography variant="body2">{moment(order?.createdAt).format("DD-MM-YYYY")}</Typography>
          </Box>
        </Stack>
        {order.invoices?.map((orderItem, index) => {
          return (
            <Grid container border="1px solid black">
              <Grid item mobile={12} sm={3} md={3} lg={3} xl={3}>
                <CardMedia
                  component="img"
                  sx={{
                    padding: "20px",
                    //   minWidth: { xs: "100%", sm: 200, md: 260 },
                    //   maxWidth: { xs: "100%", sm: 200, md: 260 },
                    //   maxWidth: { xs: "100%", sm: 200, md: 260 },
                    //   maxWidth: { xs: "100%", sm: 200, md: 260 },
                    maxHeight: "340px",
                    minHeight: "340px",
                    objectFit: "contain",
                    cursor: "pointer",
                  }}
                  image={orderItem.image}
                  alt="product"
                  loading="lazy"
                  onClick={() => navigate(`/store/product/${orderItem.productId}`)}
                />{" "}
              </Grid>
              {/* <Divider
        orientation="vertical"
        flexItem
        sx={{ marginLeft: "20px", border: "3px solid black" }}
      /> */}
              <Grid
                item
                mobile={12}
                sm={9}
                md={9}
                lg={9}
                xl={9}
                color="white"
                borderLeft="1px solid black"
              >
                <Grid container>
                  <Grid mobile={12} pb={{ mobile: 1, xs: 1, md: "94px" }}>
                    <Stack
                      direction={{ mobile: "column", xs: "column", md: "row" }}
                      justifyContent="space-between"
                      p={3}
                    >
                      <Box flex={3}>
                        <Typography variant="h4">{orderItem.productShortName}</Typography>
                        <Stack direction={"row"} spacing={1} mb={2}>
                          <Typography variant="body1" textAlign={"right"} color="#01FFFF">
                            &#8377; {orderItem.sellingPrice}
                          </Typography>
                          <Typography
                            variant="body1"
                            textAlign={"right"}
                            sx={{ textDecoration: "line-through" }}
                          >
                            &#8377; {orderItem.mrp}
                          </Typography>
                        </Stack>
                        {/* <Box sx={{ width: "100%" }}>
                          <Stepper
                            nonLinear
                            activeStep={steps.indexOf(orderItem.shippingStatus)}
                            connector={<QontoConnector />}
                          >
                            {steps.map((label, index) => (
                              <Step
                                key={label}
                                completed={
                                  orderItem.shippingStatus === "DELIVERED"
                                }
                              >
                                <StepButton color="inherit">
                                  {label === "PENDING"
                                    ? "Processing"
                                    : label === "SHIPPED"
                                    ? "Shipped"
                                    : label === "DELIVERED"
                                    ? "Ordered Delivered"
                                    : label}
                                </StepButton>
                              </Step>
                            ))}
                          </Stepper>
                        </Box> */}
                        <Stack
                          direction={{ mobile: "column", xs: "column", md: "row" }}
                          spacing={{ mobile: 0, xs: 0, md: 10 }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant="body1" gutterBottom letterSpacing={1}>
                              Payment {titleCase(orderItem.paymentStatus)}
                            </Typography>
                            <Typography variant="body1" letterSpacing={1} gutterBottom>
                              Payment Mode {orderItem.paymentMode}
                            </Typography>
                          </Box>
                          {orderItem.shippingStatus !== "NA" && (
                            <Divider
                              orientation="vertical"
                              flexItem
                              variant="inset"
                              sx={{
                                border: "1px solid black",
                                display: { mobile: "none", xs: "none", md: "flex" },
                              }}
                            />
                          )}
                          <Box>
                            {orderItem.shippingStatus !== "NA" && (
                              <Typography
                                variant="body1"
                                fontSize={{ mobile: "16px", xs: "16px", md: "18px" }}
                                gutterBottom
                                letterSpacing={1}
                                color="primary"
                              >
                                {orderItem.shippingStatus === "PENDING"
                                  ? "Delivery Pending"
                                  : titleCase(orderItem.shippingStatus)}
                              </Typography>
                            )}

                            {orderItem.invoiceNumber && (
                              <Typography
                                variant="body1"
                                fontSize={{ mobile: "16px", xs: "16px", md: "18px" }}
                                letterSpacing={1}
                              >
                                Invoice Number {orderItem.invoiceNumber}
                              </Typography>
                            )}
                            {orderItem.deliveryDate && (
                              <Typography
                                variant="body1"
                                fontSize={{ mobile: "16px", xs: "16px", md: "18px" }}
                                letterSpacing={1}
                              >
                                Delivered Date {moment(orderItem.deliveryDate).format("DD-MM-YYYY")}
                              </Typography>
                            )}
                            {orderItem.returnDate && (
                              <Typography
                                variant="body1"
                                fontSize={{ mobile: "16px", xs: "16px", md: "18px" }}
                                letterSpacing={1}
                              >
                                Returned Date {moment(orderItem.returnDate).format("DD-MM-YYYY")}
                              </Typography>
                            )}
                            {orderItem.refundAmount && (
                              <Typography
                                variant="body1"
                                fontSize={{ mobile: "16px", xs: "16px", md: "18px" }}
                                letterSpacing={1}
                              >
                                Refund amount &#8377;{orderItem.refundAmount}
                              </Typography>
                            )}
                            {orderItem.replacementDate && (
                              <Typography
                                variant="body1"
                                fontSize={{ mobile: "16px", xs: "16px", md: "18px" }}
                                letterSpacing={1}
                              >
                                Replacement Date{" "}
                                {moment(orderItem.replacementDate).format("DD-MM-YYYY")}
                              </Typography>
                            )}
                          </Box>
                        </Stack>
                      </Box>
                      <Box flex={1}>
                        <Stack
                          gap={4}
                          direction={{ mobile: "row", xs: "row", md: "column" }}
                          alignItems={{ mobile: "center", xs: "center", md: "end" }}
                        >
                          {getInvoiceFile(orderItem.invoiceNumber) && (
                            <Box display="flex">
                              <Button
                                variant="outlined"
                                startIcon={<DownloadIcon />}
                                onClick={() =>
                                  handleDownload(
                                    getInvoiceFile(orderItem.invoiceNumber).downloadURL
                                  )
                                }
                              >
                                Invoice
                              </Button>
                            </Box>
                          )}
                        </Stack>
                      </Box>
                    </Stack>
                  </Grid>
                  {orderItem.shippingStatus !== "PENDING" && orderItem.shippingStatus !== "NA" && (
                    <>
                      {/* replaacement  */}

                      {orderItem.returnStatus === "NA" && (
                        <Grid
                          mobile={orderItem.replacementStatus === "NA" ? 6 : 12}
                          p={2}
                          borderTop="1px solid black"
                          borderRight="1px solid black"
                        >
                          <Box>
                            {orderItem.shippingStatus === "SHIPPED" && orderItem.courierId && (
                              <Typography
                                variant="body1"
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  gap: "4px",
                                  flexDirection: {
                                    mobile: "column",
                                    xs: "column",
                                    md: "row",
                                  },
                                  fontSize: { mobile: "12px", xs: "12px", md: "16px" },
                                  color: "#01FFFF",
                                }}
                              >
                                TRACKING ID {orderItem.courierId}
                              </Typography>
                            )}

                            {orderItem.shippingStatus === "DELIVERED" &&
                              (orderItem.replacementStatus === "NA" ? (
                                moment(orderItem.replacementValidTill).isBefore(
                                  moment().endOf("day")
                                ) ? (
                                  <Typography
                                    variant="button"
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      gap: "4px",
                                      flexDirection: {
                                        mobile: "column",
                                        xs: "column",
                                        md: "row",
                                      },
                                      fontSize: { mobile: "12px", xs: "12px", md: "16px" },
                                      textAlign: "center",
                                      cursor: "pointer",
                                    }}
                                  >
                                    Replacement Period Expired
                                  </Typography>
                                ) : (
                                  <ReplacementDialog
                                    product={orderItem}
                                    orderId={order.orderId}
                                    getAllOrders={getAllOrders}
                                  />
                                )
                              ) : orderItem.replacementStatus === "REJECTED" ? (
                                <ReasonDialog product={orderItem} isReplacement={true} />
                              ) : (
                                <Typography
                                  variant="button"
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    gap: "4px",
                                    flexDirection: {
                                      mobile: "column",
                                      xs: "column",
                                      md: "row",
                                    },
                                    fontSize: { mobile: "12px", xs: "12px", md: "16px" },
                                    textAlign: "center",
                                  }}
                                >
                                  Replacement Status {orderItem.replacementStatus}
                                </Typography>
                              ))}
                          </Box>
                        </Grid>
                      )}

                      {/* return  */}

                      {orderItem.replacementStatus === "NA" && (
                        <Grid
                          mobile={orderItem.returnStatus === "NA" ? 6 : 12}
                          p={2}
                          borderTop="1px solid black"
                        >
                          <Box>
                            {orderItem.shippingStatus === "SHIPPED" && orderItem.courierId && (
                              <Typography
                                variant="button"
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  gap: "4px",
                                  flexDirection: {
                                    mobile: "column",
                                    xs: "column",
                                    md: "row",
                                  },
                                  fontSize: { mobile: "12px", xs: "12px", md: "16px" },
                                  color: "#01FFFF",
                                  cursor: "pointer",
                                }}
                              >
                                TRACK YOUR ORDER
                              </Typography>
                            )}

                            {orderItem.shippingStatus === "DELIVERED" &&
                              (orderItem.returnStatus === "NA" ? (
                                moment(orderItem.returnValidTill).isBefore(
                                  moment().endOf("day")
                                ) ? (
                                  <Typography
                                    variant="button"
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      gap: "4px",
                                      flexDirection: {
                                        mobile: "column",
                                        xs: "column",
                                        md: "row",
                                      },
                                      fontSize: { mobile: "12px", xs: "12px", md: "16px" },
                                      textAlign: "center",
                                      cursor: "pointer",
                                    }}
                                  >
                                    Return Period Expired
                                  </Typography>
                                ) : (
                                  <ReturnDialog
                                    product={orderItem}
                                    orderId={order.orderId}
                                    getAllOrders={getAllOrders}
                                  />
                                )
                              ) : orderItem.returnStatus === "REJECTED" ? (
                                <ReasonDialog product={orderItem} isReturn={true} />
                              ) : (
                                <Typography
                                  variant="button"
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    gap: "4px",
                                    flexDirection: {
                                      mobile: "column",
                                      xs: "column",
                                      md: "row",
                                    },
                                    fontSize: { mobile: "12px",xs: "12px", md: "16px" },
                                    textAlign: "center",
                                  }}
                                >
                                  Return status {orderItem.returnStatus}
                                  <img
                                    src={ApprovedImg}
                                    width="24px"
                                    style={{ marginLeft: "5px" }}
                                  />
                                </Typography>
                              ))}
                          </Box>
                        </Grid>
                      )}
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Card>
    </>
  );
};

export default OrderCards;

import { Box, Container, Stack, Typography } from "@mui/material";
import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import BreadCrumbHeader from "../../components/BreadCrumb/BreadCrumbHeader";
import CartProCard from "../../components/Cart/CartProCard";
import CartTotal from "../../components/Cart/CartTotal";
import { getCart, getLocalcart } from "../../redux/apis";
import localCart from "../../utils/localCart";
import Page from "../../utils/Page";

const CartPage = () => {
  const dispatch = useDispatch();
  const token = localStorage.getItem("accessToken");
  const cartItems = useSelector((state) => state.cart.cartItems) || [];
  const cartPrice = useSelector((state) => state.cart.cartPrice) || {};
  const localCartItems = useSelector((state) => state.cart.localCartItems) || [];
  const localCartPrice = useSelector((state) => state.cart.localCartPrice) || {};

  const userInfo = useSelector((state) => state.user.userInfo);
  console.log("cartItems", cartItems);

  const [cartItemsState, setCartItemsState] = useState(cartItems);
  const [cartPriceState, setCartPriceState] = useState(cartPrice);
  const [thresholdShipping, setThresholdShipping] = useState({
    value: "",
    status: null,
  });

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    // getShipping();
    if (userInfo.fullname && token) {
      getCart(dispatch);
    } else {
      getLocalcart(dispatch);
    }
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (localCartItems.length && !token) {
      setCartItemsState(localCartItems);
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/configuration/user/shipping-charge
      `
        )
        .then((response) => {
          setThresholdShipping({
            value: response.data.data.value,
            status: response.data.data.isBlocked,
          });
          if (localCartPrice.subTotal > response.data.data.value && !response.data.data.isBlocked) {
            setCartPriceState({ ...localCartPrice, shippingCharge: 0 });
          } else {
            if (cartItemsState.length) {
              const highestShippingCharge = Math.max(
                ...cartItemsState.map((item) => item.shippingCharge).filter((val) => val)
              );
              setCartPriceState({
                ...localCartPrice,
                shippingCharge: highestShippingCharge,
              });
            }
          }
        })

        .catch((err) => console.log("err", err));
      // setCartPriceState(localCartPrice);
    } else if (!localCartItems.length && !token) {
      setCartItemsState([]);
      setCartPriceState({});
    }
  }, [localCartItems, localCartPrice, thresholdShipping.value, thresholdShipping.status]);

  useEffect(() => {
    if (cartItems.length && token) {
      setCartItemsState(cartItems);
      setCartPriceState(cartPrice);
    } else if (!cartItems.length && token) {
      setCartItemsState([]);
      setCartPriceState({});
    }
  }, [cartItems, cartPrice]);

  const items = [];
  const currentPage = "Cart";

  console.log("highestShipping", cartItemsState);

  // const getShipping = () => {
  //   const response = axios
  //     .get(
  //       `${process.env.REACT_APP_API_URL}/configuration/user/shipping-charge
  //     `
  //     )
  //     .then((response) => {
  //       setThresholdShipping({
  //         value: response.data.data.value,
  //         status: response.data.data.isBlocked,
  //       });
  //       if (localCartPrice.subTotal > response.data.data.value) {
  //         setCartPriceState({ ...cartPriceState, shippingCharge: 0 });
  //       } else {
  //         setCartPriceState({ ...cartPriceState, shippingCharge: 100 });
  //       }
  //     })

  //     .catch((err) => console.log("err", err));
  // };

  return (
    <Page title="Cart">
      <BreadCrumbHeader items={items} currentPage={currentPage} />
      <Container maxWidth="xl">
        {!!cartItemsState.length && (
          <Stack
            direction={{ mobile: "column", xs: "column", md: "row" }}
            justifyContent="space-between"
            sx={{
              padding: {
                mobile: "10px",
                xs: "10px",
                sm: "10px",
                md: "20px",
                lg: "40px",
                xl: "50px",
              },
            }}
            spacing={3}
          >
            <Box flex={{ md: 2, lg: 3 }}>
              {cartItemsState?.map((item, key) => (
                <CartProCard key={key} item={item} />
              ))}
            </Box>
            <Box flex={1}>
              <CartTotal cartPrice={cartPriceState} />
            </Box>
          </Stack>
        )}
        {!cartItems.length && !localCartItems.length && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "65vh",
              width: "100%",
            }}
          >
            <Typography variant="h5" textAlign={"center"}>
              Cart is Empty
            </Typography>
          </Box>
        )}
      </Container>
    </Page>
  );
};

export default CartPage;

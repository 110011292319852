import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import cloudImage from "../../../assets/img/Rectangle 229.png";
import logo from "../../../assets/img/logo.png";
import { ArrowForward } from "@mui/icons-material";
import useResponsive from "../../../utils/useResponsive";

export default function CloudGaming() {
  const xsUp = useResponsive("up", "sm");
  return (
    <Box sx={{ width: "100%" }}>
      <Stack direction={{ mobile: "row", xs: "row", sm: "row" }}>
        <Box
          component="img"
          src={cloudImage}
          sx={{ maxHeight: "500px", maxWidth: { mobile: "170px", xs: "200px", md: "none" } }}
        />
        <Stack
          direction="column"
          sx={{
            width: "100%",
            flex: 1,
            background:
              "linear-gradient(91.52deg, #1E7D8D 1.4%, #1D3E44 39.29%, #1D3D42 42.97%, #1D2B2E 55.94%, #0D1010 77.5%, #0C0E0E 80.88%)",
          }}
          alignItems="center"
          justifyContent="center"
        >
          <Stack
            direction="column"
            sx={{ maxWidth: "600px", height: { xs: "300px", sm: "100%" } }}
            alignItems="left"
            justifyContent="center"
            spacing={{
              mobile: 0,
              xs: 0,
              sm: 2,
              m: 2,
              lg: 2,
              xl: 2,
            }}
            paddingX={{ mobile: "10px", xs: "30px", sm: "0px" }}
          >
            {/* <Box
              component="img"
              src={logo}
              sx={{
                width: {
                  mobile: "75px",
                  xs: "100px",
                  sm: "120px",
                  md: "140px",
                  lg: "150px",
                },
              }}
            /> */}

            <Typography
              variant="cloudHead"
              sx={{
                display: { mobile: "none", xs: "none", sm: "block", md: "block", lg: "block" },
              }}
            >
              Lambda Loading...
            </Typography>
            <Typography
              variant="cloudContent"
              sx={{
                textOverflow: "ellipsis",
              }}
            >
              We do our best to bring you major news (unbiased and verified), along with any useful
              info related to the gaming industry. So, be sure to check out all our channels for
              relevant and exciting updates.
            </Typography>
            {/* <Button
              endIcon={<ArrowForward />}
              variant="contained"
              sx={{
                bgcolor: "#000",
                borderRadius: "18px",
                "&:hover": {
                  backgroundColor: "#000",
                  opacity: "0.8",
                },
                fontFamily: "Roboto",
                color: "#00ffff",
              }}
            >
              View More
            </Button> */}
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
}

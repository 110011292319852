import { Box, CardMedia, Container, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import lg from "../../../assets/img/Esports.png";
import { formatCase } from "../../../utils/stringStyle";
const GameLambda = () => {
  return (
    <Container maxWidth="lg">
      <Grid
        container
        marginY={{
          mobile: 2,
          xs: 2.5,
          sm: 3,
          md: 3.5,
          lg: 4,
          xl: 4,
        }}
        p={{ mobile: 1, xs: 1, md: 4 }}
        spacing={2}
      >
        <Grid item mobile={12} xs={12} md={6}>
          <Stack justifyContent={"center"} alignItems={"center"} sx={{ width: "100%" }}>
            <CardMedia
              component="img"
              sx={{ maxWidth: { mobile: "200px", xs: "80%", sm: 400, md: 450 } }}
              image={lg}
              alt="Live from space album cover"
            />
          </Stack>
        </Grid>
        <Grid item mobile={12} xs={12} md={6}>
          <Stack
            sx={{
              width: "100%",
              height: "100%",
              maxHeight: { mobile: "100%", xs: "100%", md: 450 },
            }}
            direction="column"
            justifyContent="center"
            spacing={2}
          >
            <Typography
              gutterBottom
              color="primary"
              variant="h2"
              textAlign={{ mobile: "center", sm: "initial" }}
            >
              {formatCase("Lambda Gaming")}
              {/* THE BEST PLAY STATION{" "}
              <Box component={"br"} sx={{ display: { xs: "none", md: "block" } }} />
              GAMES OF 2022 */}
            </Typography>
            <Typography
              variant="gameLambdaContent"
              textAlign={{ mobile: "initial", md: "initial" }}
            >
              We are constantly pushing for promoting esports within the region across all
              platforms. We've hosted a multitude of local esports events over the past few years,
              and have collaborated with well known brands for many national tournaments. We aren't
              bound by genres or platforms: Our events range from FIFA tournaments on the PS4 and
              Valorant events on the PC, straight to Time Trial events on simulators!
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};

export default GameLambda;

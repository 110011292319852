import { Box, Button, CardMedia, Container, Stack, Typography } from "@mui/material";
import React from "react";
import pc from "../../../assets/img/pc.png";
import ListComponent from "./ListComponent";
import { formatCase } from "../../../utils/stringStyle";
import nvidiaLogo from "../../../assets/img/nvidia.png";
const PromoSection = () => {
  return (
    <Box
      sx={{
        background: "linear-gradient(180deg, #262626 28.45%, #000000 100%)",
        paddingY: {
          mobile: 2,
          xs: 2,
          sm: 2.5,
          md: 3,
          lg: 4,
          xl: 4,
        },
      }}
    >
      <Container maxWidth="xl">
        <Stack
          alignItems={"center"}
          justifyContent="center"
          p={2}
          marginY={{
            mobile: 2,
            xs: 2,
            sm: 2.5,
            md: 3,
            lg: 4,
            xl: 4,
          }}
          spacing={{
            mobile: 3,
            xs: 3,
            sm: 3.5,
            md: 4,
            lg: 5,
            xl: 5,
          }}
        >
          <Box
            component={"img"}
            src={nvidiaLogo}
            sx={{
              height: { mobile: "40px", xs: "50px", sm: "60px", md: "70px", lg: "100px" },
              marginBottom: { mobile: "10px", xs: "10px", sm: "20px", md: "25px", lg: "30px" },
            }}
          />
          <CardMedia
            component={"img"}
            mt={{ mobile: 1, xs: 1, sm: 1, md: 2, lg: 3 }}
            image={pc}
            sx={{ width: { mobile: "100%", xs: "100%", sm: "80%", md: "75%", lg: 700 } }}
          />
          <Typography variant="promoSectionHead">
            {formatCase("Get Machines Tailored To Your Use")}
          </Typography>
          <Stack
            direction={"row"}
            flexWrap={"wrap"}
            alignItems={"center"}
            gap={{
              mobile: 1,
              xs: 1,
              sm: 2,
              md: 2,
              lg: 2,
              xl: 2,
            }}
          >
            <ListComponent text={"High Quality machines built using curated parts"} />
            <ListComponent text={"Community Support"} />
            <ListComponent text={"Tech advice/support for builds"} />
            <ListComponent text={"Hassle-free service and returns"} />
            <ListComponent text={"Stress-tested and quality controlled builds"} />
          </Stack>{" "}
          <Button
            sx={{
              marginTop: "20px",
              backgroundColor: "#01FFFF",
              color: "#1b1b1b",
              height: { mobile: "22px", xs: "26px", sm: "30px", md: "36px", lg: "38px" },
              borderRadius: { mobile: "5px", xs: "6px", sm: "8px", md: "9px", lg: "10px" },
              "&:hover": {
                backgroundColor: "#01FFFF",
              },
              fontFamily: "Poppins",
              fontWeight: 600,
              fontSize: { mobile: "14px", xs: "16px", sm: "17px", md: "18px", lg: "19px" },
              textTransform: "none",
            }}
          >
            View More
          </Button>
        </Stack>
      </Container>
    </Box>
  );
};

export default PromoSection;

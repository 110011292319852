import { Box, Breadcrumbs, Link, Typography } from "@mui/material";
import React from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate, Link as RouterLink, Router } from "react-router-dom";

const BreadCrumbHeader = ({ items, currentPage }) => {
  return (
    <Box
      sx={{
        mt: 2,
        // background: "#36454F",
        bgcolor: " rgba(0, 0, 0, 0.4)",
        p: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" sx={{ color: "#bfbfbf" }} />}
        aria-label="breadcrumb"
      >
        {" "}
        {/* <Link underline="none" variant="subtitle1" style={{ color: "#bfbfbf", cursor: "pointer" }}>
          <Typography marginY={1}> Store</Typography>
        </Link> */}
        {items.map((item) => (
          <Link
            component={RouterLink}
            variant="subtitle1"
            // color="inherit"
            style={{ color: "#bfbfbf" }}
            to={`${item.link}`}
            underline="hover"
          >
            <Typography marginY={1}> {item.text}</Typography>
          </Link>
        ))}
        <Link
          color="primary"
          variant="subtitle1"
          sx={{ cursor: "pointer", textDecoration: "none" }}
          aria-current="page"
        >
          <Typography marginY={1}> {currentPage}</Typography>
        </Link>
      </Breadcrumbs>
    </Box>
  );
};

export default BreadCrumbHeader;

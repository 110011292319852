import { Box, Container, Grid, Pagination, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import BreadCrumbHeader from "../../components/BreadCrumb/BreadCrumbHeader";
import WishlistCard from "../../components/Wishlist/WishlistCard";
import Page from "../../utils/Page";

const Wishlist = () => {
  const wishlist = useSelector((state) => state.wishlist.wishlistItems) || [];
  const wishlistCount = useSelector((state) => state.wishlist.wishlistCount) || 0;
  const productCount = 4;
  const items = [];
  const currentPage = "Wishlist";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Page title="Wishlist">
      <BreadCrumbHeader items={items} currentPage={currentPage} />
      <Container maxWidth={"xl"}>
        {!!wishlistCount && (
          <Stack direction={"row"} spacing={2} sx={{ textAlign: "initial" }} alignItems="center">
            <Typography variant="h6" sx={{ fontWeight: "bold", my: 2 }}>
              My Wishlist
            </Typography>
            <Typography variant="subtitle1">{wishlistCount && `${wishlistCount} items`}</Typography>
          </Stack>
        )}
        <Grid container spacing={2} padding={{ mobile: "5px", lg: "20px" }}>
          {wishlist?.map((item, index) => {
            return (
              <Grid item mobile={12} sm={12} md={12} lg={12} xl={6}>
                <WishlistCard item={item} />
              </Grid>
            );
          })}
          {!wishlist.length && (
            <Grid item mobile={12} sm={12} md={12} lg={12} xl={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "65vh",
                  width: "100%",
                }}
              >
                <Typography variant="h5" textAlign={"center"}>
                  Wishlist is Empty
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
        {/* <Stack justifyContent={"center"} alignItems="center" padding={5}>
        <Pagination
            onChange={handlePagination}
            count={Math.ceil(productCount / pageSize())}
          shape="rounded"
            page={page}
        />
      </Stack> */}
      </Container>
    </Page>
  );
};

export default Wishlist;

import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { fCurrency,  } from "../../utils/stringStyle";
import PreOwnedTag from "../PreOwnedTag";

export default function SimpleProductCard({ product }) {
  const navigate = useNavigate();
  const navigateToProduct = () => {
    navigate(`/store/product/${product._id}`);
  };
  const discountPercentage = (sellingPrice, mrp) => {
    return Math.round((1 - sellingPrice / mrp) * 100);
  };
  return (
    <Box
      sx={{
        width: "355px",
        height: "539px",
        bgcolor: "rgba(0, 0, 0, 0.4)",
        boxShadow: "0px 0px 7px 6px rgba(0, 0, 0, 0.15)",
        borderRadius: "20px",
      }}
    >
      <Stack
        direction="column"
        alignItems="center"
        sx={{
          margin: "0px 0px 29px 0px",
          height: "100%",
          width: "100%",
          position: "relative",
        }}
      >
        {product?.productType === "pre-owned" && (
          <Box
            sx={{
              position: "absolute",
              right: "15px",
              top: "14px",
              zIndex: 3,
            }}
          >
            <PreOwnedTag />
          </Box>
        )}
        <Stack>
          <img
            style={{
              objectFit: "contain",
              width: "350px",
              height: "320px",
              paddingTop: "29px",
              position: "absolute",
              left: 0,
              right: 0,
              top: 0,
              Bottom: 0,
              margin: "auto",
              zIndex: 2,
            }}
            src={
              (product.images && product?.images[0]?.fileUrl) ||
              (product.image && product?.image?.fileUrl)
            }
            alt="product"
            loading="lazy"
          />
        </Stack>

        <Stack
          direction="column"
          alignItems="center"
          sx={{
            position: "absolute",
            left: 0,
            right: 0,
            top: "340px",
            Bottom: 0,
            margin: "auto",
          }}
        >
          <Typography
            variant="h3"
            sx={{
              color: "#FFFFFF",
              zIndex: 1,
            }}
          >
            {product?.shortName?.split(" ").splice(0, 2).join(" ").toUpperCase()}
          </Typography>
          <Stack direction="column" justifyContent="center" alignItems="center">
            <Typography
              variant="h4"
              sx={{
                color: "#01ffff",

                marginY: "2px",
              }}
            >
              ₹ {fCurrency(product?.sellingPrice)}
            </Typography>
            <Stack direction="row" justifyContent="center" spacing={1} alignItems="center">
              <Typography
                variant="h4"
                sx={{
                  color: "#ffffff",

                  opacity: 0.7,
                  textDecoration: "line-through",
                }}
              >
                ₹ {fCurrency(product?.mrp)}
              </Typography>
              <Typography
                variant="h4"
                sx={{
                  color: "#ffffff",
                  opacity: 0.7,
                }}
              >
                {discountPercentage(product?.sellingPrice, product?.mrp)}% off
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Box sx={{ paddingBottom: "25px", position: "absolute", bottom: "30px" }}>
          <button
            onClick={navigateToProduct}
            style={{
              width: "180px",
              height: "45px",
              borderRadius: "5px",
              backgroundColor: "#01FFFF",
              fontSize: "20px",
              fontWeight: 500,
              color: "black",
              cursor: "pointer",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                color: "#000",
              }}
            >
              View More
            </Typography>
          </button>
        </Box>
      </Stack>
    </Box>
  );
}

import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import call from "../../assets/img/call.svg";
import email from "../../assets/img/mail.svg";
import location from "../../assets/img/location_on.svg";
import facebook from "../../assets/icon/facebbook.png";
import instagram from "../../assets/icon/instagram.png";
import twitter from "../../assets/icon/twitter.png";
import discord from "../../assets/icon/discord.png";
import useResponsive from "../../utils/useResponsive";

export default function GetInTouchWithUs() {
  const contactData = [
    {
      image: call,
      title: "Contact Us",
      content: "+91 73560 33055",
    },
    {
      image: location,
      title: "Address",
      content: [
        "Lambda Gaming, Third Floor,",
        "HiLITE Mall, Kozhikode,",
        "Kerala, India,",
        "PIN - 673014",
      ],
    },
    {
      image: email,
      title: "Email",
      content: "support@lambdagaming.com",
    },
  ];

  const xsDown = useResponsive("down", "xs");

  return (
    <>
      <Container maxWidth="lg">
        <Stack
          py={{
            mobile: 3,
            xs: 4,
            sm: 5,
            md: 6,
            lg: 7,
            xl: 8,
          }}
          justifyContent="center"
        >
          <Typography variant="cloudHead" textAlign="center">
            Get in touch with us
          </Typography>
        </Stack>
        <Grid
          container
          direction="row"
          rowGap={3}
          sx={{ bgcolor: "#000" }}
          marginBottom={{
            mobile: 3,
            xs: 4,
            sm: 5,
            md: 6,
            lg: 7,
            xl: 8,
          }}
          padding={{
            mobile: 2,
            xs: 2,
            sm: 3,
            md: 4,
            lg: 5,
          }}
        >
          {contactData.map((data, index) => (
            <Grid item mobile={12} xs={12} sm={6} md={index === 0 ? 3 : 4.5} lg={4} key={index}>
              <Stack
                direction="row"
                justifyContent="start"
                spacing={3}
                sx={{ width: "100%", height: "100%" }}
              >
                <Stack
                  direction="row"
                  justifyContent={{
                    mobile: "start",
                    xs: "start",
                    sm: "start",
                    md: "center",
                    lg: "center",
                    xl: "center",
                  }}
                  alignItems="start"
                  spacing={{
                    mobile: 1,
                    xs: 1,
                    sm: 1,
                    md: 1,
                    lg: 2,
                    xl: 2,
                  }}
                  sx={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <Box
                    component="img"
                    src={data.image}
                    sx={{
                      width: {
                        mobile: "20px",
                        xs: "22px",
                        sm: "24px",
                        md: "26px",
                        lg: "30px",
                        xl: "30px",
                      },
                      height: {
                        mobile: "20px",
                        xs: "25px",
                        sm: "28px",
                        md: "30px",
                        lg: "34px",
                        xl: "34px",
                      },
                    }}
                  />
                  <Stack>
                    <Typography variant="footerHead" mb={1} color="#01ffff">
                      {data.title}
                    </Typography>
                    {Array.isArray(data.content) ? (
                      data.content.map((text, idx) => (
                        <Typography key={idx} variant="contactUsText" color="#fff">
                          {text}
                        </Typography>
                      ))
                    ) : (
                      <Typography variant="contactUsText" color="#fff">
                        {data.content}
                      </Typography>
                    )}
                  </Stack>
                </Stack>
              </Stack>
            </Grid>
          ))}
        </Grid>
        <Stack
          justifyContent="center"
          textAlign="center"
          sx={{ width: "100%" }}
          mb={{ mobile: 4, xs: 5, sm: 6, md: 7, lg: 8, xl: 9 }}
          spacing={{
            mobile: 1.25,
            xs: 1.5,
            sm: 2,
            md: 2.5,
            lg: 3,
            xl: 3,
          }}
        >
          <Typography
            color="#fff"
            sx={{
              fontSize: {
                mobile: "14px",
                xs: "16px",
                sm: "18px",
                md: "20px",
                lg: "22px",
                xl: "22px",
              },
              fontFamily: "Poppins",
              fontWeight: 600,
            }}
          >
            JOIN US
          </Typography>
          <Stack direction="row" spacing={3} justifyContent={"center"}>
            {/* <Instagram sx={{ color: "white" }} /> */}
            <Box
              component={"img"}
              src={instagram}
              alt="instagram"
              sx={{
                cursor: "pointer",
                height: {
                  mobile: "20px",
                  xs: "24px",
                  sm: "26px",
                  md: "28px",
                  lg: "30px",
                  xl: "30px",
                },
              }}
              onClick={() => {
                window.open("https://www.instagram.com/lambda.gaming", "_blank");
              }}
            />
            <Box
              component={"img"}
              src={facebook}
              alt="facebook"
              sx={{
                cursor: "pointer",
                height: {
                  mobile: "20px",
                  xs: "24px",
                  sm: "26px",
                  md: "28px",
                  lg: "30px",
                  xl: "30px",
                },
              }}
              onClick={() => {
                window.open("https://www.facebook.com/zonelambda", "_blank");
              }}
            />
            <Box
              component={"img"}
              src={twitter}
              alt="twitter"
              sx={{
                cursor: "pointer",
                height: {
                  mobile: "20px",
                  xs: "24px",
                  sm: "26px",
                  md: "28px",
                  lg: "30px",
                  xl: "30px",
                },
              }}
              onClick={() => {
                window.open("https://twitter.com/lambda_gaming", "_blank");
              }}
            />
            {/* <Box component={"img"} src={discord} alt="discord" style={{ cursor: "pointer" }} /> */}
          </Stack>
        </Stack>
      </Container>
      {!xsDown && (
        <Container maxWidth="md">
          <Box
            mb={{
              mobile: 3,
              xs: 4,
              sm: 5,
              md: 6,
              lg: 7,
              xl: 8,
            }}
          >
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3913.172546264244!2d75.83034307360097!3d11.248714250307671!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba65bbfcdaa5cfb%3A0x1760ff4405b4be65!2sLambda%20Gaming!5e0!3m2!1sen!2sin!4v1686027929140!5m2!1sen!2sin"
              width="100%"
              height={xsDown ? "250px" : "400px"}
              style={{ border: "0" }}
              allowFullScreen="false"
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </Box>
        </Container>
      )}
      {xsDown && (
        <Box>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3913.172546264244!2d75.83034307360097!3d11.248714250307671!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba65bbfcdaa5cfb%3A0x1760ff4405b4be65!2sLambda%20Gaming!5e0!3m2!1sen!2sin!4v1686027929140!5m2!1sen!2sin"
            width="100%"
            height={xsDown ? "250px" : "400px"}
            style={{ border: "0", margin: 0, padding: 0 }}
            allowFullScreen="false"
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </Box>
      )}
    </>
  );
}

import * as Yup from "yup";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
// material
import {
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Typography,
  Box,
  Container,
  styled,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { CloseSharp, Visibility, VisibilityOff } from "@mui/icons-material";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#FFFFFF",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#FFFFFF",
  },
  "& .MuiInputBase-input": {
    color: "#FFFFFF",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#FFFFFF",
    },
    "&:hover fieldset": {
      borderColor: "#46fafa",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#00eff7",
    },
  },
});

export default function Register() {
  const navigate = useNavigate();
  const uninterceptedAxiosInstance = axios.create();
  const [showPassword, setShowPassword] = useState(false);
  const [mailsent, setMailsent] = useState(false);
  const RegisterSchema = Yup.object().shape({
    email: Yup.string().email("Email must be a valid email address").required("Email is required"),
    fullname: Yup.string().min(4, "Too Short!").required("Fullname required"),
    password: Yup.string().min(6, "Too Short!").max(20, "Too Long!").required("Password required"),
  });
  const formik = useFormik({
    initialValues: {
      fullname: "",
      email: "",
      password: "",
    },
    validationSchema: RegisterSchema,
    onSubmit: async () => {
      try {
        const res = await uninterceptedAxiosInstance.post(
          `${process.env.REACT_APP_API_URL}/auth/user/register`,
          values
        );
        if (res.data.isError) {
          console.log(res.data.message);
        } else {
          setMailsent(true);
          window.scrollTo(0, 0);
        }
      } catch (error) {
        console.log(error);
        alert(error.response.data.message);
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box sx={{ width: "100%", minHeight: "100vh", bgcolor: "#1D2326" }}>
      <Container maxWidth="xl">
        <Stack justifyContent="center" alignItems="center">
          {!mailsent && (
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{
                width: {mobile:"99%", xs: "99%", sm: "600px", md: "700px" },
                bgcolor: "#000000",
                margin: "50px",
                borderRadius: "30px",
              }}
            >
              <Stack justifyContent="center" padding={2} alignItems="end" sx={{ width: "100%" }}>
                <IconButton onClick={() => navigate(-1)}>
                  <CloseSharp sx={{ color: "#f2f2f2" }} />
                </IconButton>
              </Stack>
              <Box
                onClick={() => navigate("/store")}
                component="img"
                src="/images/logo.png"
                alt="logo"
                sx={{ width: "200px", marginBottom: "80px", cursor: "pointer", marginTop: "40px" }}
              />
              <FormikProvider value={formik}>
                <Form
                  autoComplete="off"
                  style={{ width: "100%", paddingBottom: "100px" }}
                  noValidate
                  onSubmit={handleSubmit}
                >
                  <Stack
                    sx={{ width: "100%", paddingX: {mobile:"20px", xs: "20px", sm: "50px", md: "100px" } }}
                    justifyContent="center"
                    alignItems="center"
                    spacing={5}
                  >
                    <CssTextField
                      fullWidth
                      autoComplete="name"
                      type="text"
                      InputLabelProps={{
                        style: { color: "#f2f2f2" },
                      }}
                      label="Enter Your Fullname"
                      {...getFieldProps("fullname")}
                      error={Boolean(touched.fullname && errors.fullname)}
                      helperText={touched.fullname && errors.fullname}
                    />
                    <CssTextField
                      fullWidth
                      autoComplete="username"
                      type="email"
                      InputLabelProps={{
                        style: { color: "#f2f2f2" },
                      }}
                      label="Enter Your Email"
                      {...getFieldProps("email")}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                    />

                    <CssTextField
                      fullWidth
                      autoComplete="current-password"
                      type={showPassword ? "text" : "password"}
                      label="Password"
                      InputLabelProps={{
                        style: { color: "#f2f2f2" },
                      }}
                      {...getFieldProps("password")}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleShowPassword} edge="end">
                              {showPassword ? (
                                <Visibility sx={{ color: "#01FFFF" }} />
                              ) : (
                                <VisibilityOff sx={{ color: "#01FFFF" }} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      error={Boolean(touched.password && errors.password)}
                      helperText={touched.password && errors.password}
                    />
                    <LoadingButton
                      fullWidth
                      size="large"
                      style={{
                        marginTop: "70px",
                        width: "150px",
                        backgroundColor: "#01FFFF",
                        color: "#1D2326",
                        fontFamily: "inter",
                        fontWeight: "700",
                      }}
                      type="submit"
                      variant="contained"
                      loading={isSubmitting}
                    >
                      REGISTER
                    </LoadingButton>
                    <Link style={{ textDecoration: "none" }} to="/login">
                      <Typography variant="subtitle1" sx={{ color: "#f2f2f2", cursor: "pointer", fontSize:"15px", fontWeight:400 }}>
                        Already have an account?  &nbsp;
                        <span style={{ color: "#01FFFF", fontWeight: 500 , fontSize:"16px" }}>Login here.</span>
                      </Typography>
                    </Link>
                  </Stack>
                </Form>
              </FormikProvider>
            </Stack>
          )}
          {mailsent && (
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{
                width: {mobile:"99%", xs: "99%", sm: "600px", md: "700px" },
                bgcolor: "#1D2326",
                margin: "50px",
                borderRadius: "30px",
                paddingX: {mobile:'10px', xs: "10px", sm: "50px", lg: "100px" },
                height: "60%",
              }}
            >
              {/* <Typography
                sx={{
                  fontFamily: "inter",
                  fontWeight: 400,
                  fontSize: "20px",
                  color: "#01FFFF",
                  marginTop: "70px",
                  marginBottom: "20px",
                }}
              >
                MAIL SENT
              </Typography> */}
              <Typography
                sx={{
                  fontFamily: "inter",
                  fontWeight: 400,
                  fontSize: "14px",
                  color: "#f2f2f2",
                  marginY: "150px",
                }}
              >
                A verification mail has been sent to <span>{values.email}</span>. Please check your
                email to complete the registration.
              </Typography>
            </Stack>
          )}
        </Stack>
      </Container>
    </Box>
  );
}

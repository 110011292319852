import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import outlet1 from "../../../assets/img/outletGrid1.png";
import outlet2 from "../../../assets/img/outletGrid2.png";
import outlet3 from "../../../assets/img/outletGrid3.png";
import outlet4 from "../../../assets/img/outletGrid4.png";
import ImageCard from "./ImageCard";
import background from "../../../assets/img/outletBackground.png";

export default function VisitOutlet() {
  return (
    <Box
      sx={{
        backgroundImage: `url(${background})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        backgroundPosition: "center",
      }}
      paddingBottom={{
        mobile: "30px",
        xs: "40px",
        sm: "50px",
        md: "60px",
        lg: "70px",
      }}
    >
      <Container maxWidth="lg" sx={{}}>
        {/* <Typography
          variant="h2"
          textAlign={"center"}
          style={{
            color: "#01ffff",
            fontSize: "38px",
            maxWidth: "600px",
            margin: "auto",
            marginBottom: "20px",
          }}
        >
          Drop By Our Outlets With Your Freinds!
        </Typography> */}
        {/* <Typography
          textAlign={"center"}
          style={{
            color: "#fff",
            fontSize: "18px",
            fontWeight: 300,
            fontFamily: "Roboto",
            maxWidth: "950px",
            margin: "auto",
            marginBottom: "40px",
          }}
        >
          It is a long established fact that a reader will be distracted by the readable content of
          a page when looking at its layout. The point of using Lorem Ipsum is that it has a
          more-or-less normal distribution of letters, as opposed to using 'Content here, content
          here', making it look like readable English.
        </Typography> */}
        <Grid
          container
          spacing={{ mobile: 1, xs: 2, lg: 4, xl: 5 }}
          padding={2}
          sx={{}}
          // rowGap={{ mobile: 2, xs: 2, sm: 2, md: 0 }}
        >
          <ImageCard outlet={outlet1} />
          <ImageCard outlet={outlet3} />
          <ImageCard outlet={outlet4} />
          <ImageCard outlet={outlet2} />
        </Grid>
      </Container>
    </Box>
  );
}

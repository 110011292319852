import { Box, Paper, Stack, Typography, Button, Divider } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const CartTotal = ({ cartPrice }) => {
  const userInfo = useSelector((state) => state.user.userInfo);
  const accessToken = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  return (
    <Box sx={{ flex: 1 }}>
      <Paper
        elevation={5}
        sx={{
          backgroundColor: "#1D2326",
          marginBottom: "12px",
          minHeight: "300px",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontSize: { mobile: "18px", xs: "18px", md: "20px " },
            fontWeight: "Bold",
            padding: "10px",
          }}
          textAlign="center"
        >
          PRICE DETAILS
        </Typography>
        <Divider color="#00FFFF" />
        <Box padding={"20px"}>
          <Stack direction={"row"} justifyContent="space-between" p={0.5}>
            <Typography
              variant="h6"
              sx={{
                fontSize: { mobile: "16px", xs: "16px", md: "18px " },
              }}
            >
              Total MRP
            </Typography>
            <Typography
              variant="h6"
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: { mobile: "16px", xs: "16px", md: "18px " },
              }}
            >
              &#8377; {cartPrice?.maxPrice}
            </Typography>
          </Stack>
          <Stack direction={"row"} justifyContent="space-between" p={0.5}>
            <Typography
              variant="h6"
              sx={{
                fontSize: { mobile: "16px", xs: "16px", md: "18px " },
              }}
            >
              Discount on MRP
            </Typography>
            <Typography
              variant="h6"
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: { mobile: "16px", xs: "16px", md: "18px " },
              }}
            >
              &#8377; {cartPrice?.discount}
            </Typography>
          </Stack>
          <Stack direction={"row"} justifyContent="space-between" p={0.5}>
            <Typography
              variant="h6"
              sx={{
                fontSize: { mobile: "16px", xs: "16px", md: "18px " },
              }}
            >
              Shipping Charge
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontSize: { mobile: "16px", xs: "16px", md: "18px " },
              }}
            >
              {cartPrice?.shippingCharge === 0 ? "Free" : "₹" + cartPrice?.shippingCharge}
            </Typography>
          </Stack>
          <Divider />
          <Stack direction={"row"} justifyContent="space-between" p={0.5}>
            <Typography
              variant="h6"
              sx={{
                fontSize: { mobile: "16px", xs: "16px", md: "20px " },
              }}
            >
              Grand Total
            </Typography>
            <Typography
              variant="h6"
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: { mobile: "16px", xs: "16px", md: "20px " },
              }}
            >
              &#8377; {cartPrice?.grandTotal}
            </Typography>
          </Stack>
          <Box pt={2}>
            <Button
              variant="contained"
              sx={{ width: "100%" }}
              onClick={() => {
                if (userInfo.fullname && accessToken) {
                  navigate("/store/order");
                } else {
                  navigate("/login");
                }
              }}
            >
              PLACE ORDER
            </Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default CartTotal;

import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NewArrivalsCard from "../../NewArrivalsCard/NewArrivalsCard";
import LatestDealCard from "./LatestDealsCard";
import { Box, useMediaQuery } from "@mui/material";

export default function Slider1({ latestDeals }) {
  const matches = useMediaQuery("(max-width:750px)");
  const settings = {
    dots: true,
    infinite: true,
    centerMode: true,
    centerPadding: "10px",
    speed: 500,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: 3,
    slidesToScroll: 1,
    focusOnSelect: true,
    arrows: false,
    variableWidth: matches,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1324,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,

        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 750,
        settings: {
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: true,
        },
      },
    ],
  };
  return (
    <Box sx={{ padding: "20px" }}>
      <Slider {...settings} adaptiveHeight={true} className="Latest_slick">
        {latestDeals?.map((product, index) => {
          return <LatestDealCard product={product} />;
        })}
      </Slider>
    </Box>
  );
}

import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./userSlice";
import navSelectionReducer from "./navSelectionSlice";
import cartReducer from "./cartSlice";
import wishlistReducer from "./wishlistSlice";

export default configureStore({
  reducer: {
    user: userReducer,
    navSelection: navSelectionReducer,
    cart: cartReducer,
    wishlist: wishlistReducer,
  },
});

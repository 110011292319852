import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";

export default function Image({ image, caption }) {
  return (
    <Grid item mobile={4}>
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{ width: "100%", height: "120px" }}
        spacing={{
          mobile: 1,
          xs: 1,
          sm: 1.5,
          md: 2,
          lg: 3,
          xl: 3,
        }}
      >
        <Box
          component="img"
          src={image}
          alt={caption}
          sx={{
            height: {
              xl: "120px",
              lg: "110px",
              md: "90px",
              sm: "40px",
              xs: "30px",
              mobile: "26px",
            },
            width: "auto",
          }}
        />
        <Typography variant="body2">{caption}</Typography>
      </Stack>
    </Grid>
  );
}

import { Container, Stack } from "@mui/material";
import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import ProfileCard from "../../components/Profile/ProfileCard";

const Profile = () => {
  const [profile, setProfile] = useState();
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/member/user/profile`)
      .then((res) => setProfile(res.data.data))
      .catch((err) => console.log(err));
  }, []);

  return (
    <Container maxWidth="xl" sx={{ minHeight: "60vh" }}>
      <Stack alignItems={"center"} mt={5}>
        {profile && <ProfileCard profile={profile} />}
      </Stack>
    </Container>
  );
};

export default Profile;

import * as Yup from "yup";
import axios from "axios";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
// material
import {
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Typography,
  Box,
  Container,
  styled,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { CloseSharp, TagFacesTwoTone, Visibility, VisibilityOff } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { removeUser, updateUser } from "../../redux/userSlice";
import toast, { Toaster } from "react-hot-toast";
import { useEffect } from "react";
import { addToCartOnLogin } from "../../redux/apis";
import Page from "../../utils/Page";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#FFFFFF",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#FFFFFF",
  },
  "& .MuiInputBase-input": {
    color: "#FFFFFF",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#FFFFFF",
    },
    "&:hover fieldset": {
      borderColor: "#46fafa",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#00eff7",
    },
  },
});

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const uninterceptedAxiosInstance = axios.create();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [timer, setTimer] = useState(60);
  const [buttonActive, setButtonActive] = useState(false);
  const [authRecId, setAuthRecId] = useState("");

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email("Email must be a valid email address").required("Email is required"),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: LoginSchema,
    onSubmit: async () => {
      try {
        const res = await uninterceptedAxiosInstance.post(
          `${process.env.REACT_APP_API_URL}/auth//user/reset-password`,
          values
        );
        if (res.data.isError) {
          toast.error(res.data.message, {
            style: {
              border: "1px solid #713200",
              padding: "16px",
              color: "#713200",
            },
          });
        } else {
          toast.success(res.data.message, {
            style: {
              border: "1px solid #713200",
              padding: "16px",
              color: "#713200",
            },
          });
          setAuthRecId(res.data.data.id);
          setIsSubmitted(true);
        }
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message, {
          style: {
            border: "1px solid #713200",
            padding: "16px",
            color: "#713200",
          },
        });
      }
    },
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  let interval;
  useEffect(() => {
    interval = setInterval(() => {
      setTimer((timer) => timer - 1);
    }, 1000);
    window.scrollTo(0, 0);
    return () => clearInterval(interval);
  }, [interval, timer]);

  useEffect(() => {
    if (timer === 0) {
      setButtonActive(true);
    }
  }, [timer]);

  const handleResendClick = async () => {
    try {
      const res = await uninterceptedAxiosInstance.post(
        ` ${process.env.REACT_APP_API_URL}/auth/user/reset-password/resend`,
        { id: authRecId }
      );
      if (res.data.isError) {
        toast.error(res.data.message, {
          style: {
            border: "1px solid #713200",
            padding: "16px",
            color: "#713200",
          },
        });
      } else {
        toast.success(res.data.message, {
          style: {
            border: "1px solid #713200",
            padding: "16px",
            color: "#713200",
          },
        });
        setTimer(60);
        setButtonActive(false);
      }
    } catch (e) {
      console.log(e);
      toast.error(e.response.data.message, {
        style: {
          border: "1px solid #713200",
          padding: "16px",
          color: "#713200",
        },
      });
    }
  };

  return (
    <Page title="Forgot">
      <Box sx={{ width: "100%", bgcolor: "#1D2326", minHeight: "100vh" }}>
        <Container maxWidth="xl">
          <Stack justifyContent="center" alignItems="center">
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{
                width: { mobile: "99%", xs: "95%", sm: "600px", md: "700px" },
                bgcolor: "#000000",
                marginY: "50px",
                borderRadius: "30px",
              }}
            >
              {!isSubmitted && (
                <Stack justifyContent="center" padding={2} alignItems="end" sx={{ width: "100%" }}>
                  <IconButton onClick={() => navigate(-1)}>
                    <CloseSharp sx={{ color: "#f2f2f2" }} />
                  </IconButton>
                </Stack>
              )}

              <Box
                onClick={() => navigate("/store")}
                component="img"
                src="/images/logo.png"
                alt="logo"
                sx={{
                  width: "200px",
                  marginBottom: "20px",
                  cursor: "pointer",
                  marginTop: "40px",
                }}
              />
              {!isSubmitted ? (
                <FormikProvider value={formik}>
                  <Form
                    autoComplete="off"
                    style={{ width: "100%", paddingBottom: "100px" }}
                    noValidate
                    onSubmit={handleSubmit}
                  >
                    <Stack
                      sx={{
                        width: "100%",
                        paddingX: { mobile: "15px", xs: "20px", sm: "50px", md: "100px" },
                      }}
                      justifyContent="center"
                      alignItems="center"
                      spacing={4}
                    >
                      <Typography textAlign={"center"} sx={{ fontWeight: 500 }}>
                        Please enter the email address associated with your account and We will
                        email you a link to reset your password.
                      </Typography>
                      <CssTextField
                        fullWidth
                        autoComplete="username"
                        type="email"
                        InputLabelProps={{
                          style: { color: "#f2f2f2" },
                        }}
                        inputProps={{
                          autocomplete: "new-password",
                          form: {
                            autocomplete: "off",
                          },
                        }}
                        label="Enter Your Email"
                        {...getFieldProps("email")}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                      />

                      <LoadingButton
                        fullWidth
                        size="large"
                        style={{
                          width: "150px",
                          backgroundColor: "#01FFFF",
                          color: "#1D2326",
                          fontFamily: "inter",
                          fontWeight: "700",
                        }}
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                      >
                        Submit
                      </LoadingButton>
                      <Link style={{ textDecoration: "none" }} to="/login">
                        <Typography
                          variant="subtitle1"
                          sx={{
                            color: "#f2f2f2",
                            cursor: "pointer",
                            fontSize: "15px",
                            fontWeight: 400,
                          }}
                        >
                          <span style={{ color: "#01FFFF", fontWeight: 500 }}>
                            Remember Password?
                          </span>
                        </Typography>
                      </Link>
                    </Stack>
                  </Form>
                </FormikProvider>
              ) : (
                <Stack mt={5} mb={20} spacing={3} px={3} alignItems={"center"}>
                  <Typography
                    textAlign={"center"}
                    sx={{
                      color: "#01ffff",
                      fontSize: "48px !important",
                      fontWeight: "800 !important",
                    }}
                  >
                    Check Your Emaild
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{ color: "#01FFFF", fontWeight: 500 }}
                    textAlign={{ mobile: "center", xs: "center", sm: "unset" }}
                  >
                    <span style={{ color: "#01FFFF", fontWeight: 500 }}>
                      We have sent you an email with a link to reset your password
                    </span>
                  </Typography>
                  <Stack alignItems={"center"} spacing={2}>
                    {!buttonActive && (
                      <Typography sx={{ color: "#01FFFF", fontWeight: 500 }}>
                        You can resend the link in : {timer}s{" "}
                      </Typography>
                    )}
                    <LoadingButton
                      onClick={handleResendClick}
                      disabled={!buttonActive}
                      fullWidth
                      size="large"
                      sx={{
                        width: "150px",
                        backgroundColor: `${buttonActive ? "#01FFFF" : "#1D2326"}`,
                        color: "#1D2326",
                        fontFamily: "inter",
                        fontWeight: "700",
                      }}
                      type="submit"
                      variant="contained"
                    >
                      Resend
                    </LoadingButton>
                  </Stack>
                </Stack>
              )}
            </Stack>
          </Stack>
        </Container>
      </Box>
    </Page>
  );
}

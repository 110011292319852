import { Favorite } from "@mui/icons-material";
import { Box, Button, Stack, Typography } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getWishlist } from "../../redux/apis";
import { fCurrency, formatCase } from "../../utils/stringStyle";
import PreOwnedTag from "../PreOwnedTag";

export default function ProductCard({ product }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const wishlist = useSelector((state) => state.wishlist.wishlistItems);
  const [buttinLoading, setButtonLoading] = useState(false);
  const discountPercentage = (sellingPrice, mrp) => {
    return Math.round((1 - sellingPrice / mrp) * 100);
  };

  const addToWishlist = async () => {
    setButtonLoading(true);
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/wishlist`, {
        productId: product?._id,
      });
      console.log(res.data.data);
      setButtonLoading(false);
      if (!res.data.isError) {
        console.log("added to wishlist");
        toast.success("Added to wishlist");
        getWishlist(dispatch);
      }
    } catch (error) {
      console.log(error);
      setButtonLoading(false);
    }
  };

  const removeFromWishlist = async () => {
    setButtonLoading(true);
    try {
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/wishlist/remove`, {
        productId: product?._id,
      });
      console.log(res.data.data);
      setButtonLoading(false);
      if (!res.data.isError) {
        console.log("removed from wishlist");
        toast.success("Removed from wishlist");
        getWishlist(dispatch);
      }
    } catch (error) {
      console.log(error);
      setButtonLoading(false);
    }
  };

  const checkWishlist = () => {
    console.log(wishlist);
    if (!wishlist) return false;
    const isWishlist = wishlist.find((item) => item._id === product._id);
    if (isWishlist) return true;
    return false;
  };

  return (
    <Box
      sx={{
        width: {
          xs: "99%",
          mobile: "99%",
          sm: "365px",
          md: "375px",
          lg: "375px",
        },
        height: "800px",
        bgcolor: " rgba(0, 0, 0, 0.4)",
        boxShadow: "0px 0px 7px 6px rgba(0, 0, 0, 0.15)",
        borderRadius: "20px",
      }}
    >
      <Stack
        direction="column"
        alignItems="center"
        justifyContent={"space-between"}
        sx={{
          height: "100%",
          width: "100%",
          position: "relative",
        }}
      >
        <Stack>
          <img
            style={{
              objectFit: "contain",
              width: "315px",
              height: "330px",
              paddingTop: "55px",
              position: "absolute",
              left: 0,
              right: 0,
              top: 0,
              Bottom: 0,
              margin: "auto",
              zIndex: 2,
            }}
            src={product?.image?.fileUrl}
            alt={product?.name}
          />
        </Stack>
        <Stack>
          {product.offer && (
            <>
              <Box
                component="img"
                src={"/images/offer_tag.png"}
                alt="offer"
                sx={{
                  position: "absolute",
                  left: "25px",
                  top: "-8px",
                  margin: "auto",
                  width: "60px",
                  zIndex: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                loading="lazy"
              />
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 600,
                  fontFamily: "Roboto, sans-serif",
                  color: "#000",
                  zIndex: 3,
                  position: "absolute",
                  left: "30px",
                  top: 15,
                  margin: "auto",
                  textShadow: "2px 0px 2px #00cccc",
                }}
              >
                {discountPercentage(product?.sellingPrice, product?.mrp)}%
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 600,
                  fontFamily: "Roboto, sans-serif",
                  color: "#000",
                  zIndex: 3,
                  position: "absolute",
                  left: "30px",
                  top: 35,
                  margin: "auto",
                  textShadow: "2px 0px 2px #00cccc",
                }}
              >
                OFF
              </Typography>
            </>
          )}
          {product?.productType === "pre-owned" && (
            <Box
              sx={{
                position: "absolute",
                right: "55px",
                top: "17px",
                zIndex: 3,
              }}
            >
              <PreOwnedTag />
            </Box>
          )}
          <Box sx={{ position: "absolute", top: "15px", right: "15px", zIndex: 3 }}>
            {checkWishlist() ? (
              <Favorite
                sx={{ color: "#01FFFF", fontSize: "35px", cursor: "pointer" }}
                onClick={removeFromWishlist}
              />
            ) : (
              <Favorite
                sx={{ color: "#505051", fontSize: "35px", cursor: "pointer" }}
                onClick={addToWishlist}
              />
            )}
          </Box>
        </Stack>
        <Stack
          direction="column"
          alignItems="center"
          sx={{
            position: "absolute",
            left: 0,
            right: 0,
            top: "350px",
            margin: "auto",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              color: "#01FFFF",
              zIndex: 1,
            }}
          >
            {product?.shortName?.split(" ").splice(0, 2).join(" ")}
          </Typography>
          <Stack justifyContent="center" alignItems="center">
            <Typography
              variant="h4"
              sx={{
                color: "#01FFFF",
                padding: 0,
                zIndex: 1,
              }}
            >
              {`₹${fCurrency(product.sellingPrice)}`}
            </Typography>
            <Stack direction="row" justifyContent="center" spacing={1} alignItems="center">
              <Typography
                variant="h4"
                sx={{
                  color: "#FFFFFF",
                  padding: 0,
                  zIndex: 1,
                  textDecoration: "line-through",
                }}
              >
                {`₹${fCurrency(product.mrp)}`}
              </Typography>
              <Typography
                variant="h4"
                sx={{
                  color: "#FFFFFF",
                  padding: 0,
                  zIndex: 1,
                }}
              >
                {`${discountPercentage(product.sellingPrice, product.mrp)}% OFF`}
              </Typography>
            </Stack>
          </Stack>
          <Box sx={{ marginTop: "15px" }}>
            <Button
              onClick={() => navigate(`/store/product/${product._id}`)}
              style={{
                width: "200px",
                height: "45px",
                borderRadius: "5px",
                backgroundColor: "#01FFFF",
                fontSize: "20px",
                color: "#000",
              }}
            >
              View More
            </Button>
          </Box>
          <Stack
            sx={{
              width: "100%",
              marginTop: "15px",
              marginBottom: "15px",
              overflowY: "hidden",
              height: "255px",
              padding: "10px 10px 10px 5px",
            }}
          >
            <ul
              style={{
                color: "white",
                fontFamily: "Roboto",
                fontWeight: 400,
                padding: "0px 10px 0px 30px",
              }}
            >
              {product?.specifications?.map((item) => {
                return (
                  <li>
                    <Typography variant="subtitle1">{item}</Typography>
                  </li>
                );
              })}
            </ul>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
}

import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { styled } from "@mui/material";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  th: {
    border: 0,
    borderBottom: "1px solid #505051",
  },
  td: {
    border: 0,
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    border: 0,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
  },
}));

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <StyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <StyledTableCell
          component="th"
          scope="row"
          align="center"
          sx={{
            color: "#01FFFF",
            fontSize: "18px",
            fontWeight: 600,
          }}
        >
          {row.title?.toUpperCase()}
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
            sx={{
              color: "#01FFFF",
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell>
      </StyledTableRow>
      <StyledTableRow>
        <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableBody>
                  {row.descriptions.map((description, key) => (
                    <TableRow key={key}>
                      <StyledTableCell
                        sx={{
                          width: "50%",
                        }}
                      >
                        {description.key.toUpperCase()}
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{
                          width: "50%",
                        }}
                      >
                        {description.value}
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </StyledTableCell>
      </StyledTableRow>
    </React.Fragment>
  );
}

export default function SpecTable({ tableData }) {
  return (
    <TableContainer component={Paper} sx={{ backgroundColor: "#15161A" }}>
      <Table aria-label="collapsible table" size="small" sx={{ border: 0 }}>
        <TableBody sx={{ border: 0 }}>
          {tableData.map((row, key) => (
            <Row key={key} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

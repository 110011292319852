import * as Yup from "yup";
import axios from "axios";
import { useState } from "react";
import { useFormik, Form, FormikProvider } from "formik";
// material
import {
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Typography,
  Box,
  Container,
  styled,
  Grid,
  FormControl,
  Select,
  MenuItem,
  Radio,
  Button,
  CircularProgress,
  Autocomplete,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { getCart } from "../../redux/apis";
import { useEffect } from "react";
import { Cancel, Save } from "@mui/icons-material";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#01FFFF",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#01FFFF",
    borderWidth: "0.5px",
    borderRadius: "5px",
    boxShadow: "0px 0px 10.798px 6.64491px rgba(0, 0, 0, 0.35)",
  },
  "& .MuiInputBase-input": {
    color: "#01FFFF",
    borderWidth: "0.5px",
    borderRadius: "5px",
    boxShadow: "0px 0px 10.798px 6.64491px rgba(0, 0, 0, 0.35)",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#01FFFF",
      borderWidth: "0.5px",
      borderRadius: "5px",
      boxShadow: "0px 0px 10.798px 6.64491px rgba(0, 0, 0, 0.35)",
    },
    "&:hover fieldset": {
      borderColor: "#46fafa",
      borderWidth: "0.5px",
      borderRadius: "5px",
      boxShadow: "0px 0px 10.798px 6.64491px rgba(0, 0, 0, 0.35)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#00eff7",
      borderWidth: "0.5px",
      borderRadius: "5px",
      boxShadow: "0px 0px 10.798px 6.64491px rgba(0, 0, 0, 0.35)",
    },
  },
});

const CssTextField1 = styled(TextField)({
  "& label.Mui-focused": {
    color: "#01FFFF",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#01FFFF",
    borderWidth: "0.5px",
    borderRadius: "5px",
  },
  "& .MuiInputBase-input": {
    color: "#01FFFF",
    borderWidth: "0.5px",
    borderRadius: "5px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#01FFFF",
      borderWidth: "0.5px",
      borderRadius: "5px",
      boxShadow: "0px 0px 10.798px 6.64491px rgba(0, 0, 0, 0.35)",
    },
    "&:hover fieldset": {
      borderColor: "#46fafa",
      borderWidth: "0.5px",
      borderRadius: "5px",
      boxShadow: "0px 0px 10.798px 6.64491px rgba(0, 0, 0, 0.35)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#00eff7",
      borderWidth: "0.5px",
      borderRadius: "5px",
      boxShadow: "0px 0px 10.798px 6.64491px rgba(0, 0, 0, 0.35)",
    },
  },
});

const CssAutocomplete = styled(Autocomplete)({
  "& .MuiAutocomplete-listbox": {
    color: "#FFFFFF",
  },
  "& .MuiAutocomplete-inputRoot": {
    color: "#FFFFFF",
  },
  "& .MuiAutocomplete-input": {
    color: "#FFFFFF",
  },
});

export default function AddressAddForm({ getAddresses, setAddNewAddress }) {
  const [states, setStates] = useState([]);

  function capitalize(word) {
    return word.charAt(0).toUpperCase() + word.substring(1);
  }

  function capitalizeWords(sentence) {
    return sentence
      .split(" ")
      .map((word) => capitalize(word))
      .join(" ");
  }

  const getStates = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/state/all/indian`
      );
      console.log("state==>", res.data);
      setStates(res.data.data.states);
    } catch (error) {
      console.log(error);
    }
  };

  const mobileRegex = /^[5-9]\d{9}$/;
  const addressSchema = Yup.object().shape({
    fullname: Yup.string().required("Name is required"),
    mobile: Yup.string()
      .required("Mobile number is required")
      .matches(mobileRegex, "Mobile number is not valid"),
    postCode: Yup.string().required("Post Code is required"),
    locality: Yup.string().required("Locality is required"),
    address: Yup.string().required("Address is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required").nullable(),
    landmark: Yup.string().required("Landmark is required"),
    alternatePhone: Yup.string()
      .optional("Alternate phone number is required")
      .matches(mobileRegex, "Mobile number is not valid"),
  });

  const formik = useFormik({
    initialValues: {
      fullname: "",
      mobile: "",
      postCode: "",
      locality: "",
      address: "",
      city: "",
      state: "",
      landmark: "",
      alternatePhone: "",
    },
    validationSchema: addressSchema,
    onSubmit: async (values) => {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/member/user/address`,
          values
        );
        console.log(res);
        toast.success("Address added successfully");
        getAddresses();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  useEffect(() => {
    getStates();
  }, []);

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  return (
    <FormikProvider value={formik}>
      <Form
        autoComplete="off"
        style={{ width: "100%" }}
        noValidate
        onSubmit={handleSubmit}
      >
        <Box
          sx={{
            width: "100%",
            boxShadow: "0px 0px 10.798px 6.64491px rgba(0, 0, 0, 0.35)",
            padding: {
              xs: "10px",
              sm: "10px",
              md: "20px",
              lg: "50px",
              xl: "40px",
            },
          }}
        >
          <Grid container spacing={4}>
            <Grid item mobile={12}>
              <Typography
                sx={{
                  fontFamily: "inter",
                  fontWeight: 500,
                  fontSize: "25px",
                  color: "white",
                  textAlign: "center",
                }}
              >
                DELIVERY ADDRESS
              </Typography>
            </Grid>
            <Grid item mobile={12} sm={6} md={6} lg={6} xl={6}>
              <CssTextField
                fullWidth
                required
                label="Full Name"
                InputLabelProps={{
                  style: {
                    color: "#FFFFFF",
                  },
                }}
                {...getFieldProps("fullname")}
                error={Boolean(touched.fullname && errors.fullname)}
                helperText={touched.fullname && errors.fullname}
              />
            </Grid>
            <Grid item mobile={12} sm={6} md={6} lg={6} xl={6}>
              <CssTextField
                fullWidth
                required
                label="Mobile Number"
                InputLabelProps={{
                  style: {
                    color: "#FFFFFF",
                    fontWeight: 300,
                    fontFamily: "roboto",
                  },
                }}
                {...getFieldProps("mobile")}
                error={Boolean(touched.mobile && errors.mobile)}
                helperText={touched.mobile && errors.mobile}
              />
            </Grid>
            <Grid item mobile={12} sm={6} md={6} lg={6} xl={6}>
              <CssTextField
                fullWidth
                required
                label="Pin Code"
                InputLabelProps={{
                  style: {
                    color: "#FFFFFF",
                    fontWeight: 300,
                    fontFamily: "roboto",
                  },
                }}
                {...getFieldProps("postCode")}
                error={Boolean(touched.postCode && errors.postCode)}
                helperText={touched.postCode && errors.postCode}
              />
            </Grid>
            <Grid item mobile={12} sm={6} md={6} lg={6} xl={6}>
              <CssTextField
                fullWidth
                required
                label="Locality"
                InputLabelProps={{
                  style: {
                    color: "#FFFFFF",
                    fontWeight: 300,
                    fontFamily: "roboto",
                  },
                }}
                {...getFieldProps("locality")}
                error={Boolean(touched.locality && errors.locality)}
                helperText={touched.locality && errors.locality}
              />
            </Grid>
            <Grid item mobile={12} sm={12} md={12} lg={12} xl={12}>
              <CssTextField
                fullWidth
                required
                label="Address"
                InputLabelProps={{
                  style: {
                    color: "#FFFFFF",
                    fontWeight: 300,
                    fontFamily: "roboto",
                  },
                }}
                {...getFieldProps("address")}
                error={Boolean(touched.address && errors.address)}
                helperText={touched.address && errors.address}
              />
            </Grid>
            <Grid item mobile={12} sm={6} md={6} lg={6} xl={6}>
              <CssTextField
                fullWidth
                required
                label="City"
                InputLabelProps={{
                  style: {
                    color: "#FFFFFF",
                    fontWeight: 300,
                    fontFamily: "roboto",
                  },
                }}
                {...getFieldProps("city")}
                error={Boolean(touched.city && errors.city)}
                helperText={touched.city && errors.city}
              />
            </Grid>
            <Grid item mobile={12} sm={6} md={6} lg={6} xl={6}>
              <CssAutocomplete
                disabled={states?.length ? false : true}
                id="country-select-demo"
                options={states}
                onChange={(event, newValue) => {
                  formik.setFieldValue("state", newValue);
                  formik.setFieldTouched("state", false);
                }}
                getOptionLabel={(option) => option}
                renderOption={(props, option) => (
                  <>
                    {states.length && (
                      <Box component="li" {...props} sx={{ color: "#1D2326" }}>
                        {capitalizeWords(option)}
                      </Box>
                    )}
                  </>
                )}
                renderInput={(params) => (
                  <CssTextField1
                    {...params}
                    label="State"
                    InputLabelProps={{
                      style: {
                        color: "#FFFFFF",
                        fontWeight: 300,
                        fontFamily: "roboto",
                      },
                    }}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                    sx={{
                      textTransform: "capitalize",
                    }}
                    required
                    error={Boolean(touched.state && errors.state)}
                    helperText={touched.state && errors.state}
                  />
                )}
              />
            </Grid>

            <Grid item mobile={12} sm={6} md={6} lg={6} xl={6}>
              <CssTextField
                fullWidth
                required
                label="Landmark"
                InputLabelProps={{
                  style: {
                    color: "#FFFFFF",
                    fontWeight: 300,
                    fontFamily: "roboto",
                  },
                }}
                {...getFieldProps("landmark")}
                error={Boolean(touched.landmark && errors.landmark)}
                helperText={touched.landmark && errors.landmark}
              />
            </Grid>
            <Grid item mobile={12} sm={6} md={6} lg={6} xl={6}>
              <CssTextField
                fullWidth
                label="Alternate Phone"
                InputLabelProps={{
                  style: {
                    color: "#FFFFFF",
                    fontWeight: 300,
                    fontFamily: "roboto",
                  },
                }}
                {...getFieldProps("alternatePhone")}
                error={Boolean(touched.alternatePhone && errors.alternatePhone)}
                helperText={touched.alternatePhone && errors.alternatePhone}
              />
            </Grid>
            <Grid item mobile={12} sm={12} md={12} lg={12} xl={12}>
              <Stack
                justifyContent="end"
                direction="row"
                alignItems="center"
                spacing={2}
                sx={{ width: "100%" }}
              >
                <Button
                  sx={{
                    backgroundColor: "#01FFFF",
                    color: "#000000",
                    "&:hover": {
                      backgroundColor: "#016b6b",
                      color: "#000000",
                      boxShadow:
                        "0px 0px 10.798px 6.64491px rgba(0, 0, 0, 0.35)",
                    },
                  }}
                  startIcon={<Cancel />}
                  onClick={() => setAddNewAddress(false)}
                >
                  CANCEL
                </Button>
                <LoadingButton
                  sx={{
                    backgroundColor: "#01FFFF",
                    color: "#000000",
                    "&:hover": {
                      backgroundColor: "#016b6b",
                      color: "#000000",
                      boxShadow:
                        "0px 0px 10.798px 6.64491px rgba(0, 0, 0, 0.35)",
                    },
                    "&:loading": {
                      backgroundColor: "#016b6b",
                      color: "#000000",
                      boxShadow:
                        "0px 0px 10.798px 6.64491px rgba(0, 0, 0, 0.35)",
                    },
                  }}
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  startIcon={<Save />}
                >
                  Save
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Form>
    </FormikProvider>
  );
}

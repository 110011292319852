import { Box, Stack } from "@mui/material";
import Banner from "../../components/CategorySections/Banner/Banner";
import ProductsSection from "../../components/CategorySections/ProductsSection";
import ProductBrand from "../../components/CategorySections/ProductBrand/ProductBrand";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import Page from "../../utils/Page";
import BreadCrumbHeader from "../../components/BreadCrumb/BreadCrumbHeader";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { formatCase } from "../../utils/stringStyle";

export default function Categories() {
  const { pathname, search } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const productType = searchParams.get("type");
  const categoryId = searchParams.get("tab");
  const navigate = useNavigate();
  const [category, setCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const unInterceptedAxios = axios.create();

  useEffect(() => {
    if (pathname === "/store/products" && !productType) {
      navigate("/store/products?type=brand-new");
    }
  }, [pathname, search, navigate]);

  const getCategory = async () => {
    try {
      const res = await unInterceptedAxios.get(`${process.env.REACT_APP_API_URL}/category/single`, {
        params: {
          id: categoryId,
        },
      });
      setCategory(res.data.data.name);
    } catch (error) {
      console.log(error);
    }
  };

  const getCategories = async () => {
    try {
      const res = await unInterceptedAxios.get(
        `${process.env.REACT_APP_API_URL}/category/all/active`,
        {
          params: {
            productType,
          },
        }
      );
      console.log(res.data.data);
      if (!res.data.isError) {
        setCategories(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (categoryId && categoryId !== "all") {
      getCategory();
    } else {
      setCategory("all");
    }
  }, [categoryId]);

  useEffect(() => {
    getCategories();
  }, []);

  const items = [
    {
      text: productType ? formatCase(productType, "sentence") : "Products",
      link: `/store?type=${productType || "brand-new"}`,
    },
  ];
  const currentPage = category && formatCase(category, "sentence");

  return (
    <Page title="Products">
      <Stack direcrion="row" justifyContent="center" paddingBottom="100px" alignItems="center">
        {/* <Banner /> */}

        <BreadCrumbHeader items={items} currentPage={currentPage} />
        <ProductsSection category={category} categories={categories} />
        <ProductBrand />
      </Stack>
    </Page>
  );
}

import { Box, Typography } from "@mui/material";
import React from "react";
import contactUsBanner from "../../assets/img/contactUsBanner.png";
import { formatCase } from "../../utils/stringStyle";

export default function ContactUsBanner() {
  return (
    <Box
      sx={{
        backgroundImage: `url(${contactUsBanner})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        position: "relative",
        height: {
          mobile: "110px",
          xs: "140px",
          sm: "160px",
          md: "220px",
          lg: "290px",
          xl: "350px",
        },
      }}
    >
      <Typography
        variant="homeBannerHead"
        sx={{
          textAlign: "center",
          color: "#fff",
          fontWeight: "bold",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width:"100%",
          textShadow: "1px 4px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
        {formatCase("contact us", "sentence")}
      </Typography>
    </Box>
  );
}

import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import Logout from "@mui/icons-material/Logout";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { AccountCircle, FavoriteRounded } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export default function MenuComponent({ handleLogout }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton
        onClick={handleClick}
        size="small"
        sx={{ ml: 2 }}
        aria-controls={open ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        <AccountCircle sx={{ color: "#01ffff", fontSize: "24px", cursor: "pointer" }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            bgcolor: "#1D2326",
            color: "#01ffff",
            width: "160px",
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "#1D2326",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "initial",
          }}
          onClick={() => navigate("/store/profile")}
        >
          <ListItemIcon>
            <AccountCircle sx={{ color: "#01FFFF" }} fontSize="medium" />
          </ListItemIcon>
          My Profile
        </MenuItem>
        <MenuItem
          onClick={() => navigate("/store/wishlist")}
          sx={{
            display: { mobile: "flex", xs: "flex", md: "none" },
            alignItems: "center",
            justifyContent: "initial",
          }}
        >
          <ListItemIcon>
            <FavoriteRounded sx={{ color: "#01ffff", fontSize: "24px" }} />
          </ListItemIcon>
          Wishlist
        </MenuItem>
        <MenuItem
          onClick={() => navigate("/store/orders/view")}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "initial",
          }}
        >
          <ListItemIcon>
            <LocalShippingIcon sx={{ color: "#01FFFF" }} fontSize="medium" />
          </ListItemIcon>
          My Orders
        </MenuItem>

        <MenuItem
          onClick={handleLogout}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "initial",
          }}
        >
          <ListItemIcon>
            <Logout sx={{ color: "#01FFFF" }} fontSize="medium" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
}

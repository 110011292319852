import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";

export default function ProductsInOrder({ cartItems }) {
  return (
    <Box
      sx={{
        width: "100%",
        boxShadow: "0px 0px 10.798px 6.64491px rgba(0, 0, 0, 0.35)",
        paddingX: "10px",
        paddingY: "20px",
      }}
    >
      <Stack spacing={3}>
        {cartItems.map((item) => {
          return (
            <Stack
              justifyContent="center"
              alignItems="center"
              direction="row"
              spacing={1.5}
              sx={{ paddingX: { mobile: "5px", xs: "5px", sm: "10px", lg: "20px" } }}
            >
              <Box
                component={"img"}
                src={item.image.fileUrl}
                sx={{
                  width: { xs: "50px", mobile: "60px", sm: "90px", md: "70px", lg: "100px" },
                  height: "90px",
                  objectFit: "contain",
                }}
                loading="lazy"
              />
              <Typography
                flex={{ xs: 6, mobile: 5 }}
                sx={{
                  color: "#FFFFFF",
                  fontFamily: "inter",
                  fontWeight: 500,
                  fontSize: { mobile: "12px", xs: "12px", sm: "14px" },
                  width: "100%",
                  overflow: "ellipsis",
                  wordBreak: "break-word",
                }}
              >
                {item?.name.slice(0, 40)}
                {item?.name.length > 40 ? "..." : ""}
              </Typography>
              <Typography
                flex={1}
                sx={{
                  color: "#01FFFF",
                  fontFamily: "inter",
                  fontWeight: 500,
                  fontSize: { mobile: "12px", xs: "12px", sm: "14px" },
                  width: "100%",
                  textAlign: "right",
                }}
              >
                x {item.quantity}
              </Typography>

              <Typography
                flex={2}
                sx={{
                  color: "#01FFFF",
                  fontFamily: "inter",
                  fontWeight: 500,
                  fontSize: { mobile: "12px", xs: "12px", sm: "14px" },
                  width: "100%",
                  textAlign: "right",
                }}
              >
                ₹{`${item.sellingPrice * item.quantity}`}
              </Typography>
            </Stack>
          );
        })}
      </Stack>
    </Box>
  );
}

import { Avatar, Box, Card, Stack, Typography } from "@mui/material";
import React from "react";
import { titleCase } from "../../utils/stringStyle";

const ProfileCard = ({ profile }) => {
  return (
    <Card sx={{ background: "#0d0d0f", p: 4 }}>
      <Stack alignItems={"center"} spacing={2}>
        <Box>
          <Avatar
            alt={titleCase(profile?.fullname)}
            src="/static/images/avatar/1.jpg"
            sx={{ width: 112, height: 112 }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">{titleCase(profile?.fullname)}</Typography>
          <Typography variant="h6">{profile?.email}</Typography>
        </Box>
      </Stack>
    </Card>
  );
};

export default ProfileCard;

// {
//     "_id": "63b903ca1eb35c155c978e8b",
//     "email": "iamkiran2831@gmail.com",
//     "fullname": "kiran",
//     "isBlocked": false,
//     "defaultAuth": true,
//     "googleAuth": false,
//     "shippingAddresses": [
//         {
//             "fullname": "Kiran",
//             "mobile": 8138953981,
//             "country": "india",
//             "state": "kerala",
//             "city": "kerala",
//             "locality": "kozhikode",
//             "address": "ul cyberpark kozhikode",
//             "postCode": 673028,
//             "landmark": "cyberpark",
//             "_id": "63b9057e5a9e02a58991fa89",
//             "createdAt": "2023-01-07T05:39:10.315Z",
//             "updatedAt": "2023-01-07T05:39:10.315Z"
//         }
//     ],
//     "createdAt": "2023-01-07T05:31:54.845Z",
//     "updatedAt": "2023-01-16T07:01:24.916Z",
//     "__v": 0
// }

import { createSlice } from "@reduxjs/toolkit";

export const nevSelectionSlice = createSlice({
  name: "navSelection",
  initialState: {
   selected:null
  },

  reducers: {
    changeNavSelection: (state, action) => {
      state.selected = action.payload;
    },

  },
});

export const { changeNavSelection } = nevSelectionSlice.actions;
export default nevSelectionSlice.reducer;
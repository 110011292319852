import { Box, Stack, Typography } from "@mui/material";
import axios from "axios";
import React, { useState, useEffect } from "react";
import ImageGallery from "react-image-gallery";
import { Link, useNavigate } from "react-router-dom";
// import BannerText from "./BannerText";
import "./probannerSlider.css";

export default function OfferBanner({ productType }) {
  const navigate = useNavigate();
  const [images, setImages] = useState([]);

  const getOffers = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/offer/user/all`, {
        params: {
          productType,
        },
      });
      console.log("offers", res.data.data);
      if (res.data.data.offers.length > 0) {
        const images = res.data.data.offers.map((item) => {
          return {
            original: item.images[0],
            _id: item._id,
          };
        });
        setImages(images);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getOffers();
  }, [productType]);

  return (
    <Box
      className="probanner"
      sx={{
        width: "100%",
      }}
    >
      {/* <Stack direction="column" alignItems="center" justifyContent="center" m={5}>
        <Box
          sx={{
            // marginBottom: "60px",
            border: "2px solid #01FFFF",
            borderRadius: "5px",
            paddingX: "10px",
            paddingY: "5px",
            bgcolor: "#1D2326",
            boxShadow: "0px 0px 32px rgba(1, 255, 255, 0.25)",
          }}
        >
          <Typography variant="h2">OFFERS</Typography>
        </Box>
      </Stack> */}

      <ImageGallery
        items={images}
        autoPlay={true}
        showThumbnails={false}
        showBullets={true}
        showFullscreenButton={false}
        showPlayButton={false}
        showNav={true}
        infinite={true}
        slideDuration={800}
        slideRobotoval={5000}
        additionalClass="promobanner"
        renderItem={(item) => {
          return (
            <img
              src={item.original}
              alt="offer"
              data-id={item._id}
              onClick={() => navigate(`/store/products?offer=${item._id}&type=${productType}`)}
              style={{ cursor: "pointer" }}
            />
          );
        }}
      />
    </Box>
  );
}

import { Stack, Typography } from "@mui/material";
import React from "react";
import ProductBrand from "../../components/CategorySections/ProductBrand/ProductBrand";
import GameBanner from "../../components/Home/GameBanner/GameBanner";
import GameLambda from "../../components/Home/GameLambda/GameLambda";
import GameNews from "../../components/Home/GameNews/GameNews";
// import HomeBanner from "../../components/Home/HomeBanner/HomeBanner";
import HomeOfferBanner from "../../components/Home/HomeOffer/HomeOfferBanner";
import PromoBanner from "../../components/Home/HomePromoBanner/PromoBanner";
import Outlet from "../../components/Home/Outlets/Outlet";
import OutletUpdate from "../../components/Home/OutletUpdate/OutletUpdate";
import PromoSection from "../../components/Home/PromoSection/PromoSection";
import DeliverySupport from "../../components/StoreSections/DeliverySupport/DeliverySupport";
// import ProductBrand from "../../components/StoreSections/ProductBrand/ProductBrand";
import ShopByCategory from "../../components/StoreSections/ShopByCategory/ShopByCategory";
import HomeMainImage from "../../components/Home/HomeMainImage/HomeMainImage";
import CloudGaming from "../../components/Home/CloudGaming/CloudGaming";
import VisitOutlet from "../../components/Home/VisitOutlet/VisitOutlet";
import Page from "../../utils/Page";

export default function Home() {
  return (
    <Page title="Home">
      {/* <HomeBanner /> */}
      <HomeMainImage />
      <ShopByCategory productType={"brand-new"} />
      <PromoSection />
      <CloudGaming />
      <Outlet />
      <PromoBanner />
      {/* <GameBanner /> */}
      <GameNews />
      <HomeOfferBanner />
      <GameLambda />
      <VisitOutlet />
      <DeliverySupport />
      <ProductBrand />
    </Page>
  );
}

import { East } from "@mui/icons-material";
import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import axios from "axios";
import React, { useState, useEffect } from "react";
import SimpleProductCard from "../../SimpleProductCard/SimpleProductCard";
import { formatCase } from "../../../utils/stringStyle";

export default function RecentlyViewed() {
  const [recentlyViewed, setRecentlyViewed] = useState([]);
  const [viewedAll, setViewedAll] = useState(false);

  const getRecentlyViewed = (count) => {
    const recentlyViewedItems = JSON.parse(localStorage.getItem("recentlyViewed"));
    if (recentlyViewedItems?.length > 0) {
      setRecentlyViewed(recentlyViewedItems.slice(0, count));
      if (recentlyViewedItems.length <= count) {
        setViewedAll(true);
      }
    } else {
      setViewedAll(true);
    }
    if (count === 9) {
      setViewedAll(true);
    }
  };

  useEffect(() => {
    getRecentlyViewed(3);
  }, []);
  return (
    <Box
      sx={{
        width: "100%",
        backgroundSize: "cover",
        backgroundRepeat: "repeat-y",
        position: "relative",
        paddingBottom: "50px",
      }}
    >
      <Container maxWidth="xl">
        <Box sx={{}}>
          <Stack direction="column" alignItems="center" justifyContent="center" spacing={5}>
            <Box
              sx={{
                border: "2px solid #01FFFF",
                borderRadius: "5px",
                padding: "5px 10px",
                bgcolor: "#1D2326",
                boxShadow: "0px 0px 32px rgba(1, 255, 255, 0.25)",
              }}
            >
              <Typography variant="h2">{formatCase("Recently Viewed")}</Typography>
            </Box>
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{
                maxWidth: {
                  xl: "1400px",
                  lg: "1200px",
                  md: "900px",
                  sm: "800px",
                  xs: "500px",
                },
                minWidth: {
                  xl: "1400px",
                  lg: "1200px",
                  md: "900px",
                  sm: "600px",
                  xs: "400px",
                  mobile: "98%",
                },
              }}
            >
              <Grid container spacing={5} direction="row">
                {recentlyViewed.map((item) => {
                  return (
                    <Grid item mobile={12} sm={6} md={6} lg={4} xl={4}>
                      <Stack justifyContent="center" alignItems="center">
                        <SimpleProductCard product={item} />
                      </Stack>
                    </Grid>
                  );
                })}
                {!viewedAll && (
                  <Grid item mobile={12} sx={{ color: "white" }} alignItems="end">
                    <Stack
                      onClick={() => getRecentlyViewed(9)}
                      direction="row"
                      justifyContent={{ mobile: "center", xs: "center", sm: "end" }}
                      alignItems="center"
                      sx={{ width: "100%" }}
                    >
                      <Stack
                        direction="row"
                        spacing={1}
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                          width: "164px",
                          height: "50px",
                          border: "1px solid #01ffff",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                      >
                        <Typography sx={{ color: "white", fontSize: "24px" }}>View All</Typography>
                        <East />
                      </Stack>
                    </Stack>
                  </Grid>
                )}
              </Grid>
            </Stack>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
}

import { East } from "@mui/icons-material";
import { Box, CircularProgress, Container, Grid, Stack, Typography } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import SimpleProductCard from "../../SimpleProductCard/SimpleProductCard";
import { formatCase } from "../../../utils/stringStyle";

export default function SimiliarProducts({
  similiarProducts,
  getSimiliarProducts,
  loading,
  viewAllClicked,
}) {
  const uninterceptedAxiosInstance = axios.create();
  const [LatestDeals, setLatestDeals] = useState([]);

  return (
    <Box
      sx={{
        width: "100%",
        backgroundSize: "cover",
        backgroundRepeat: "repeat-y",
        position: "relative",
        paddingBottom: "80px",
      }}
    >
      <Container maxWidth="xl">
        <Box sx={{}}>
          <Stack direction="column" alignItems="center" justifyContent="center" spacing={5}>
            <Box
              sx={{
                border: "2px solid #01FFFF",
                borderRadius: "5px",
                padding: "5px 10px",
                bgcolor: "#1D2326",
                boxShadow: "0px 0px 32px rgba(1, 255, 255, 0.25)",
              }}
            >
              <Typography variant="h2">{formatCase("similiar products")}</Typography>
            </Box>
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{
                maxWidth: {
                  xl: "1400px",
                  lg: "1200px",
                  md: "900px",
                  sm: "800px",
                  xs: "500px",
                },
                minWidth: {
                  xl: "1400px",
                  lg: "1200px",
                  md: "900px",
                  sm: "600px",
                  xs: "400px",
                  mobile: "98%",
                },
              }}
            >
              <>
                {loading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: "669px",
                    }}
                  >
                    <CircularProgress />
                  </div>
                ) : (
                  <Grid container spacing={5} direction="row">
                    {similiarProducts.map((item) => {
                      return (
                        <Grid item mobile={12} sm={6} md={6} lg={4} xl={4}>
                          <Stack justifyContent="center" alignItems="center">
                            <SimpleProductCard product={item} />
                          </Stack>
                        </Grid>
                      );
                    })}
                    {!viewAllClicked && (
                      <Grid item mobile={12} sx={{ color: "white" }} alignItems="end">
                        <Stack
                          direction="row"
                          justifyContent={{ mobile: "center", xs: "center", sm: "end" }}
                          alignItems="center"
                          sx={{ width: "100%" }}
                        >
                          <Stack
                            onClick={() => getSimiliarProducts(9)}
                            direction="row"
                            spacing={1}
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                              width: "164px",
                              height: "50px",
                              border: "1px solid #01ffff",
                              borderRadius: "5px",
                              cursor: "pointer",
                            }}
                          >
                            <Typography sx={{ color: "white", fontSize: "24px" }}>
                              View All
                            </Typography>
                            <East />
                          </Stack>
                        </Stack>
                      </Grid>
                    )}
                  </Grid>
                )}
              </>
            </Stack>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
}

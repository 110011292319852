import React from "react";
import "./index.css";
import axios from "axios";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import store from "./redux/store";
import App from "./App";
import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from "./utils/theme";
import { Toaster } from "react-hot-toast";
import { HelmetProvider } from "react-helmet-async";

// axios intercepters
axios.interceptors.request.use((request) => {
  const token = localStorage.getItem("accessToken");
  request.headers.Authorization = `Bearer ${token}`;
  return request;
});
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.clear();
      window.location = "/login";
    } else return Promise.reject(error);
  }
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <HelmetProvider>
        <CssBaseline />
        <Provider store={store}>
          <Toaster
            position="bottom-center"
            reverseOrder={false}
            gutter={8}
            containerStyle={{
              top: 150,
            }}
          />
          <App />
        </Provider>
      </HelmetProvider>
    </ThemeProvider>
  </React.StrictMode>
);

import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import Image from "./Image";

export default function DeliverySupport() {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{
        width: "100%",
        bgcolor: "rgba(15, 16, 19, 0.80)",
        marginBottom: {
          mobile: "20px",
          xs: "30px",
          sm: "40px",
          md: "45px",
          lg: "50px",
          xl: "50px",
        },
      }}
    >
      <Grid
        container
        maxWidth={{
          xl: "lg",
          lg: "1100px",
          md: "800px",
          sm: "500px",
          xs: "450px",
          mobile: "98%",
        }}
        sx={{
          marginY: {
            mobile: "30px",
            xs: "50px",
            sm: "70px",
            md: "80px",
            lg: "90px",
            xl: "100px",
          },
        }}
        direction="row"
      >
        <Image image="/images/delivery.png" caption="Home Delivery" />
        <Image image="/images/support.png" caption="24/7 Support" />
        <Image image="/images/affliliates.png" caption="Affiliates" />
      </Grid>
    </Stack>
  );
}

import axios from "axios";
import localCart from "../utils/localCart";
import {
  emptyLocalCart,
  updateCartFailure,
  updateCartStart,
  updateCartSuccess,
  updateLocalCartSuccess,
} from "./cartSlice";
import { updateWishlistFailure, updateWishlistStart, updateWishlistSuccess } from "./wishlistSlice";

export const getCart = async (dispatch) => {
  dispatch(updateCartStart());
  try {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/cart`);
    dispatch(updateCartSuccess(res.data.data));
    console.log(res.data.data);
  } catch (error) {
    console.log(error);
    dispatch(updateCartFailure());
  }
};

export const getLocalcart = (dispatch) => {
  try {
    const cartItems = JSON.parse(localStorage.getItem("cartItems"));
    if (cartItems && cartItems.length) {
      const createLocalCart = localCart(cartItems);
      dispatch(updateLocalCartSuccess(createLocalCart));
    } else {
      dispatch(emptyLocalCart());
    }
  } catch (error) {
    console.log(error);
  }
};

export const addToCartOnLogin = async (dispatch) => {
  try {
    const cartItems = JSON.parse(localStorage.getItem("cartItems"));
    console.log(cartItems);
    if (cartItems.length > 0) {
      await axios.post(`${process.env.REACT_APP_API_URL}/cart/local-cart`, { cartItems });
    }
    localStorage.removeItem("cartItems");
    getCart(dispatch);
  } catch (error) {
    console.log(error);
  }
};

export const getWishlist = async (dispatch) => {
  dispatch(updateWishlistStart());
  try {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/wishlist`);
    dispatch(updateWishlistSuccess(res.data.data));
  } catch (error) {
    console.log(error);
    dispatch(updateWishlistFailure());
  }
};

import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import GameNewsCard from "./GameNewsCard";
import arrowLeft from "../../../assets/img/arrowLeft.png";
import arrowRight from "../../../assets/img/arrowRight.png";
import gameNews1 from "../../../assets/img/gameNews1.png";
import gameNews2 from "../../../assets/img/gameNews2.png";
import gameNews3 from "../../../assets/img/gameNews3.png";
import { ArrowForward } from "@mui/icons-material";

const GameNews = () => {
  return (
    <Box
      sx={{
        width: "100%",
        background: "#1D2326",
        paddingY: { mobile: "40px", xs: "50px", sm: "50px", md: "50px", lg: "70px" },
      }}
    >
      <Container maxWidth="xl">
        <Stack marginBottom={{ mobile: "30px", xs: "40px", sm: "40px", md: "40px", lg: "55px" }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={2}
            marginBottom={{ mobile: "40px", xs: "50px", sm: "50px", md: "50px", lg: "70px" }}
          >
            <Box
              component={"img"}
              src={arrowLeft}
              sx={{
                width: { mobile: "20px", xs: "24px", sm: "30px", md: "36px", lg: "40px" },
                height: "auto",
              }}
            />
            <Typography variant="sectionHead">Gaming News</Typography>
            <Box
              component={"img"}
              src={arrowRight}
              sx={{
                width: { mobile: "20px", xs: "24px", sm: "30px", md: "36px", lg: "40px" },
                height: "auto",
              }}
            />
          </Stack>
          <Grid container spacing={2} rowGap={2}>
            <Grid item mobile={12} sm={6} md={4}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                sx={{ width: "100%" }}
              >
                <GameNewsCard
                  img={gameNews1}
                  title={"Mortal Kombat "}
                  content={`Yes, it's a bit confusing. In fact a previous cryptic teaser had recently hinted that the next game will not be called Mortal Kombat 12. Is it a reboot? Sort of, but not in the traditional sense. `}
                />
              </Stack>
            </Grid>
            <Grid item mobile={12} sm={6} md={4}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                sx={{ width: "100%" }}
              >
                <GameNewsCard
                  img={gameNews3}
                  title={"Modern Warfare II"}
                  content={`The next Call Of Duty title could arrive earlier than expected. And instead of alternating to the "Black Ops" series, the next one could be a sequel to the recently released "Modern Warfare II"`}
                />
              </Stack>
            </Grid>
            <Grid item mobile={12} sm={6} md={4}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                sx={{ width: "100%" }}
              >
                <GameNewsCard
                  img={gameNews2}
                  title={"Twisted Metal Series"}
                  content={`The Twisted Metal series based on the iconic PlayStation franchise is getting closer to a release, as Peacock has revealed a very cool looking poster via IGN.`}
                />
              </Stack>
            </Grid>
          </Grid>{" "}
        </Stack>
        <Box sx={{ width: "100%", textAlign: "center" }}>
          <Button
            endIcon={<ArrowForward />}
            variant="contained"
            sx={{
              bgcolor: "#01ffff",
              borderRadius: "18px",
              height: "36px",
              width: "180px",
              "&:hover": {
                backgroundColor: "#01ffff",
                opacity: "0.8",
              },
              fontFamily: "Roboto",
              color: "#000",
            }}
          >
            View More
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default GameNews;

import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Outlet,
  Navigate,
  redirect,
} from "react-router-dom";
import "./app.css";
import Categories from "./pages/Categories/Categories";
import Home from "./pages/Home/Home";
import Store from "./pages/Store/Store";
import NavBar from "./components/NavBar/NavBar";
import Footer from "./components/Footer/Footer";
import Login from "./pages/Login/Login";
import Register from "./pages/Register/Register";
import VerifyRegister from "./pages/Register/VerifyRegister";
import ProductView from "./pages/ProductView/ProductView";
import CartPage from "./pages/Cart/CartPage";
import Order from "./pages/Order/Order";
import Wishlist from "./pages/Wishlist/Wishlist";
import OrderView from "./pages/OrderView/OrderView";
import ScrollToTop from "./utils/ScrollToTop";
import Profile from "./pages/Profile/Profile";
import Checkout from "./pages/Order/Checkout";
import ForgotPassword from "./pages/forgot-password/ForgotPassword";
import ResetPassword from "./pages/reset-password/ResetPassword";
import ContactUs from "./pages/ContactUs/ContactUs";

const accessToken = localStorage.getItem("accessToken");

const LayoutWithoutNav = () => {
  return (
    <div className="app">
      {/* <NavBar /> */}
      <Outlet />
      {/* <Footer /> */}
    </div>
  );
};
const HomeLayout = () => {
  return (
    <div className="app">
      <NavBar />
      <ScrollToTop>
        <Outlet />
      </ScrollToTop>
      <Footer />
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomeLayout />,
    children: [
      { path: "/", element: <Home /> },
      { path: "verify-signup", element: <VerifyRegister /> },
      { path: "register", element: <Register /> },
      { path: "login", element: <Login /> },
      { path: "forgot-password", element: <ForgotPassword /> },
      { path: "reset-pass", element: <ResetPassword /> },
      { path: "contact-us", element: <ContactUs /> },
    ],
  },
  {
    path: "/store",
    element: <HomeLayout />,
    children: [
      { path: "/store", element: <Store /> },
      { path: "products", element: <Categories /> },
      { path: "product/:id", element: <ProductView /> },
      { path: "profile", element: <Profile /> },
      { path: "cart", element: <CartPage /> },
      { path: "order", element: <Checkout /> },
      { path: "orders/view", element: <OrderView /> },
      { path: "wishlist", element: <Wishlist /> },
    ],
  },
]);

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;

import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import ImageGallery from "react-image-gallery";
import newProductsBanner from "../../../assets/img/newProducts.png";
import preOwnedBanner from "../../../assets/img/preOwnedBanner.png";
import preOwnedBannerMobile from "../../../assets/img/preownedMob.png";
import newProductsBannerMobile from "../../../assets/img/newProductsMob.png";
import useResponsive from "../../../utils/useResponsive";
import BannerText from "./BannerText";
import "./imageSlider.css";
import { useLocation, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";

export default function Banner() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [image, setImage] = useState("");
  const productType = searchParams.get("type");
  const smUp = useResponsive("up", "sm");

  useEffect(() => {
    if (productType === "brand-new") {
      if (smUp) {
        setImage(newProductsBanner);
      } else {
        setImage(newProductsBannerMobile);
      }
    } else {
      if (smUp) {
        setImage(preOwnedBanner);
      } else {
        setImage(preOwnedBannerMobile);
      }
    }
  }, [productType]);

  return (
    <>
      <Box component={"img"} src={image} sx={{ width: "100%", height: "auto" }} />
    </>
  );
}

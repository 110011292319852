import React from 'react'
import BreadCrumbHeader from '../../components/BreadCrumb/BreadCrumbHeader';
import Order from './Order';

function Checkout() {
    const items = [{ text: "Cart", link: "/store/cart" }];
    const currentPage = "Checkout";
    return (
        <>
            <BreadCrumbHeader items={items} currentPage={currentPage} />
            <Order />
        </>
    )
}

export default Checkout
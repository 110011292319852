import { Box, Stack, Typography } from "@mui/material";
import axios from "axios";
import React, { useState, useEffect } from "react";
import ImageGallery from "react-image-gallery";
import { Link, useNavigate } from "react-router-dom";
// import BannerText from "./BannerText";
// import "./probannerSlider.css";

export default function HomeOfferBanner() {
  const navigate = useNavigate();
  const [images, setImages] = useState([]);

  const getOffers = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/offer/user/all`);
      console.log("offerss", res.data.data);
      if (res.data.data.offers.length > 0) {
        const images = res.data.data.offers.map((item) => {
          return {
            original: item.images[0],
            _id: item._id,
            productType: item.productType,
          };
        });
        setImages(images);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getOffers();
  }, []);

  return (
    <Box
      className="probanner"
      sx={{
        width: "100%",
      }}
    >
      <ImageGallery
        items={images}
        autoPlay={true}
        showThumbnails={false}
        showBullets={images.length > 1 ? true : false}
        showFullscreenButton={false}
        showPlayButton={false}
        showNav={true}
        infinite={true}
        slideDuration={800}
        slideRobotoval={5000}
        additionalClass="promobanner"
        renderItem={(item) => {
          return (
            <img
              src={item.original}
              alt="offer"
              data-id={item._id}
              onClick={() =>
                navigate(`/store/products?offer=${item._id}&type=${item.productType}&tab=all`)
              }
              style={{ cursor: "pointer" }}
            />
          );
        }}
      />
    </Box>
  );
}

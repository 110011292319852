import { Box, Grid } from "@mui/material";
import React from "react";

export default function ImageCard({ outlet }) {
  return (
    <Grid item mobile={6} sm={6} md={6}>
      <Box
        component={"img"}
        src={outlet}
        sx={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          borderRadius: "10px",
          border: {
            mobile: "2px solid rgba(255, 255, 255, 0.5)",
            xs: "2px solid rgba(255, 255, 255, 0.5)",
            sm: "2.5px solid rgba(255, 255, 255, 0.5)",
            md: "3px solid rgba(255, 255, 255, 0.5)",
            lg: "4px solid rgba(255, 255, 255, 0.5)",
          },
        }}
      />
    </Grid>
  );
}

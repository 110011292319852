import { AddCircleRounded, RemoveCircleRounded } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardMedia,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import { getWishlist } from "../../redux/apis";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const WishlistCard = ({ item }) => {
  console.log("wisodfsf", item);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [count, setCount] = useState(1);

  const removeFromWishlist = async () => {
    try {
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/wishlist/remove`, {
        productId: item._id,
      });
      console.log(res.data.data);

      if (!res.data.isError) {
        console.log("Removed from wishlist", res);
        toast.success("Remove from wishlist");
        getWishlist(dispatch);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const discountPercentage = (sellingPrice, mrp) => {
    return Math.round((1 - sellingPrice / mrp) * 100);
  };

  return (
    <Paper elevation={5} sx={{ padding: "20px", backgroundColor: "#1D2326", marginBottom: "10px" }}>
      <Stack direction={{ mobile: "column", xs: "column", md: "row" }} spacing={2}>
        <Stack alignItems={"center"} spacing={1}>
          <Card
            sx={{
              minWidth: { mobile: "100%", xs: "100%", sm: 200, md: 260 },
              maxWidth: { mobile: "100%", xs: "100%", sm: 200, md: 260 },
              margin: "0 auto",
              backgroundColor: "#0D0D0F",
              cursor: "pointer",
            }}
          >
            <CardActionArea>
              {" "}
              <CardMedia
                onClick={() => {
                  navigate(`/store/product/${item._id}`);
                }}
                component="img"
                sx={{
                  maxHeight: { mobile: 260, xs: 260, sm: 260, md: 320 },
                  minHeight: { mobile: 260, xs: 260, sm: 260, md: 320 },

                  objectFit: "contain",
                }}
                image={item.image.fileUrl}
                alt="product"
              />{" "}
              <Box
                sx={{
                  position: "absolute",
                  right: "0",
                  top: "0",
                  cursor: "pointer",
                  display: { mobile: "flex", xs: "flex", sm: "none", md: "none" },
                }}
              >
                <IconButton onClick={removeFromWishlist}>
                  <HighlightOffRoundedIcon fontSize="inherit" color="primary" />
                </IconButton>
              </Box>
            </CardActionArea>
          </Card>
        </Stack>
        <Stack>
          <Typography variant="h4" color="white">
            {item.name.slice(0, 70)}
            {item.name.length > 70 && "..."}
          </Typography>
          <Typography variant="h5" color="#00FFFF">
            &#8377; {item.sellingPrice}
          </Typography>
          <Stack direction={"row"} spacing={1} mb={2}>
            <Typography variant="body2" color="white" sx={{ textDecorationLine: "line-through" }}>
              &#8377; {item.mrp}
            </Typography>
            <Typography variant="body2" color="white" fontWeight={"bold"}>
              {`${discountPercentage(item.sellingPrice, item.mrp)}% OFF`}
            </Typography>
          </Stack>
          {/* specifications  */}
          <Typography
            variant="subtitle1"
            color="white"
            sx={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              height: { mobile: "200px", xs: "200px", sm: "172px", md: "172px" },
            }}
          >
            <ul
              style={{
                margin: "0",
                padding: "0px 0px 0px 20px",
              }}
            >
              {item.specifications.map((specification) => {
                return <li>{specification}</li>;
              })}
            </ul>
          </Typography>
          <Box
            sx={{
              display: { mobile: "none", xs: "none", sm: "flex", md: "flex" },
              justifyContent: "end",
            }}
          ></Box>
        </Stack>
      </Stack>
      <Stack
        justifyContent="center"
        alignItems="end"
        sx={{
          width: "100%",
          marginTop: "10px",
          display: { mobile: "none", xs: "none", sm: "felx", md: "flex" },
        }}
      >
        <Button
          size="large"
          variant="contained"
          sx={{ width: "260px", backgroundColor: "#01FFFF", color: "#000" }}
          onClick={removeFromWishlist}
        >
          Remove
        </Button>
      </Stack>
    </Paper>
  );
};

export default WishlistCard;

import { createSlice } from "@reduxjs/toolkit";

export const wishlistSlice = createSlice({
  name: "wishlist",
  initialState: {
    wishlistItems: [],
    wishlistCount: 0,
    pending: false,
    error: false,
  },

  reducers: {
    updateWishlistStart: (state) => {
      state.pending = true;
    },
    updateWishlistSuccess: (state, action) => {
      state.pending = false;
      state.wishlistItems = action.payload.data;
      state.wishlistCount = action.payload.maxRecord;
    },
    updateWishlistFailure: (state) => {
      state.pending = false;
      state.error = true;
    },
    emptyWishlist: (state) => {
      state.wishlistItems = [];
      state.wishlistCount = 0;
    },
  },
});

export const {
  updateWishlistStart,
  updateWishlistSuccess,
  updateWishlistFailure,
  emptyWishlist,
} = wishlistSlice.actions;
export default wishlistSlice.reducer;

import * as Yup from "yup";
import axios from "axios";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
// material
import {
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Typography,
  Box,
  Container,
  styled,
  Grid,
  FormControl,
  Select,
  MenuItem,
  Radio,
  Button,
  CircularProgress,
  Autocomplete,
  FormLabel,
  RadioGroup,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { getCart } from "../../redux/apis";
import { useEffect } from "react";
import { Add } from "@mui/icons-material";
import AddressAddForm from "../../components/AddressAddForm/AddressAddForm";
import ProductsInOrder from "../../components/ProductsInOrder/ProductsInOrder";
import AddressSelectForm from "../../components/AddressSelectForm/AddressSelectForm";
import BreadCrumbHeader from "../../components/BreadCrumb/BreadCrumbHeader";

export default function Order() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cartPrice = useSelector((state) => state.cart.cartPrice) || {};
  const cartItems = useSelector((state) => state.cart.cartItems) || [];
  const totalItems = cartItems.reduce((acc, item) => acc + item.quantity, 0);
  const [paymentMethod, setPaymentMethod] = useState("ONLINE");
  const [addresses, setAddresses] = useState([]);
  const [addressPresent, setAddressPresent] = useState(false);
  const [addNewAddress, setAddNewAddress] = useState(false);
  const [razorPayClosed, setRazorPayClosed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedAddress, setSelectedAddress] = useState({});
  const [paymentModes, setPaymentModes] = useState({});

  const getPaymentModeSettings = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/configuration/user/payment-mode`
      );
      console.log("payment mode==>", res.data);
      setPaymentModes(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPaymentModeSettings();
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      navigate("/login");
      return;
    }
    window.scrollTo(0, 0);
  }, []);

  const mobileRegex = /^[5-9]\d{9}$/;
  const addressSchema = Yup.object().shape({
    fullname: Yup.string().required("Name is required"),
    mobile: Yup.string()
      .required("Mobile number is required")
      .matches(mobileRegex, "Mobile number is not valid"),
    postCode: Yup.string().required("Post Code is required"),
    locality: Yup.string().required("Locality is required"),
    address: Yup.string().required("Address is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required").nullable(),
    landmark: Yup.string().required("Landmark is required"),
    alternatePhone: Yup.string()
      .optional("Alternate phone number is required")
      .matches(mobileRegex, "Mobile number is not valid"),
  });

  const formik = useFormik({
    initialValues: {
      fullname: "",
      mobile: "",
      postCode: "",
      locality: "",
      address: "",
      city: "",
      state: "",
      landmark: "",
      alternatePhone: "",
    },
    validationSchema: addressSchema,
    onSubmit: async (values) => {
      try {
        if (!["COD", "ONLINE"].includes(paymentMethod)) {
          toast.error("Please select payment method");
          return;
        }

        const res = await axios.post(`${process.env.REACT_APP_API_URL}/order/user`, {
          ...values,
          maxPrice: cartPrice.maxPrice,
          subTotal: cartPrice.subTotal,
          shippingCharge: cartPrice.shippingCharge,
          grandTotal: cartPrice.grandTotal,
          newAddress: Boolean(values.newAddress),
          paymentMode: paymentMethod,
        });
        console.log(res);
        if (res.data.data.paymentMode === "COD") {
          toast.success("Order placed successfully");
          navigate("/store/orders/view");
          getCart(dispatch);
        }

        if (res.data.data.paymentMode === "ONLINE") {
          openPayModal(res.data.data);
          console.log("paymodel", res.data.data);
        }
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
        getCart(dispatch);
      }
    },
  });

  const getAddresses = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/member/user/addresses`);
      console.log("addresses", res);
      if (res.data.data.addressesPresent) {
        setAddresses(res.data.data.addresses);
        setAddressPresent(true);
        setAddNewAddress(false);
        setIsLoading(false);
      } else {
        setAddressPresent(false);
        setAddresses([]);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setAddresses([]);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAddresses();
  }, []);

  useEffect(() => {
    if (selectedAddress._id) {
      formik.setValues({
        fullname: selectedAddress.fullname,
        mobile: selectedAddress.mobile,
        postCode: selectedAddress.postCode,
        locality: selectedAddress.locality,
        address: selectedAddress.address,
        city: selectedAddress.city,
        state: selectedAddress.state,
        landmark: selectedAddress.landmark,
        alternatePhone: selectedAddress.alternatePhone,
        newAddress: false,
      });
    }
  }, [selectedAddress]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (!["COD", "ONLINE"].includes(paymentMethod)) {
      toast.error("Please select payment method");
      return;
    }
    if (!selectedAddress._id) {
      toast.error("Please select an address");
      return;
    }
    formik.handleSubmit();
  };

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  // razorpay modal

  const openPayModal = (orderInfo) => {
    const options = {
      key: orderInfo.key,
      amount: orderInfo.amount, //  = INR 1
      name: orderInfo.name,
      order_id: orderInfo.order_id,
      description: "Test Transaction",
      image: "/images/logo.png",
      handler: function (response) {
        // alert("payment Success", response.razorpay_payment_id);
        getCart(dispatch);
        navigate("/store/orders/view");

        // console.log(response);
      },
      prefill: {
        name: orderInfo?.prefill.name,
        contact: orderInfo?.prefill.contact,
        email: orderInfo?.prefill.email,
      },
      notes: orderInfo.notes,
      theme: {
        color: "#1D2326",
      },
      modal: {
        confirm_close: true,
        ondismiss: function () {
          setRazorPayClosed(true);
          console.log("This code runs when the popup is closed");
        },
      },
    };
    var rzp1 = new window.Razorpay(options);
    rzp1.on("payment.captured", function (response) {
      getCart(dispatch);
      console.log(response);
    });
    rzp1.on("payment.failed", function (response) {
      console.log(response);
      navigate("/order/failure");
    });
    rzp1.open();
  };
  // razorpay modal ends

  return (
    <>
      <Container
        maxWidth="xl"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          mt: { mobile: 2, xs: 2, md: 5 },
        }}
      >
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "80vh",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <Grid
              container
              spacing={{ mobile: 0, xs: 0, sm: 2, md: 3, lg: 5 }}
              sx={{
                width: "100%",
                paddingTop: "30px",
                marginBottom: "50px",
                paddingX: {
                  mobile: "0px",
                  xs: "0px",
                  sm: "10px",
                  md: "20px",
                  lg: "50px",
                  xl: "100px",
                },
              }}
            >
              <Grid item mobile={12} sm={12} md={7} lg={8} xl={8}>
                <Stack spacing={5}>
                  {addressPresent && !addNewAddress && (
                    <AddressSelectForm
                      addresses={addresses}
                      selectedAddress={selectedAddress}
                      setSelectedAddress={setSelectedAddress}
                      setAddNewAddress={setAddNewAddress}
                    />
                  )}
                  {(!addressPresent || addNewAddress) && (
                    <AddressAddForm
                      getAddresses={getAddresses}
                      setAddNewAddress={setAddNewAddress}
                    />
                  )}
                  <ProductsInOrder cartItems={cartItems} />
                </Stack>
              </Grid>
              <Grid item mobile={12} sm={12} md={5} lg={4} xl={4}>
                <FormikProvider value={formik}>
                  <Form
                    autoComplete="off"
                    style={{ width: "100%" }}
                    noValidate
                    onSubmit={handleSubmit}
                  >
                    <Stack spacing={5}>
                      <Box
                        sx={{
                          width: "100%",
                          boxShadow: "0px 0px 10.798px 6.64491px rgba(0, 0, 0, 0.35)",
                          paddingX: "10px",
                          paddingY: "20px",
                        }}
                      >
                        <Stack spacing={3}>
                          <Stack
                            justifyContent="center"
                            alignItems="center"
                            direction="row"
                            sx={{
                              borderBottom: "1px solid #01FFFF",
                              paddingBottom: "10px",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#FFFFFF",
                                fontFamily: "roboto",
                                fontWeight: 500,
                                fontSize: "22px",
                              }}
                            >
                              PRICE DETAILS
                            </Typography>
                          </Stack>

                          <Stack
                            justifyContent="space-between"
                            alignItems="center"
                            direction="row"
                            sx={{ paddingX: "20px" }}
                          >
                            <Typography
                              sx={{
                                color: "#FFFFFF",
                                fontFamily: "inter",
                                fontWeight: 500,
                                fontSize: "14px",
                              }}
                            >
                              Price ({totalItems} items)
                            </Typography>
                            <Typography
                              sx={{
                                color: "#01FFFF",
                                fontFamily: "inter",
                                fontWeight: 500,
                                fontSize: "14px",
                              }}
                            >
                              ₹{cartPrice.maxPrice}
                            </Typography>
                          </Stack>
                          <Stack
                            justifyContent="space-between"
                            alignItems="center"
                            direction="row"
                            sx={{ paddingX: "20px" }}
                          >
                            <Typography
                              sx={{
                                color: "#FFFFFF",
                                fontFamily: "inter",
                                fontWeight: 500,
                                fontSize: "14px",
                              }}
                            >
                              Discount
                            </Typography>
                            <Typography
                              sx={{
                                color: "#01FFFF",
                                fontFamily: "inter",
                                fontWeight: 500,
                                fontSize: "14px",
                              }}
                            >
                              ₹{cartPrice.discount}
                            </Typography>
                          </Stack>
                          <Stack
                            justifyContent="space-between"
                            alignItems="center"
                            direction="row"
                            sx={{ paddingX: "20px" }}
                          >
                            <Typography
                              sx={{
                                color: "#FFFFFF",
                                fontFamily: "inter",
                                fontWeight: 500,
                                fontSize: "14px",
                              }}
                            >
                              Delivery Charges
                            </Typography>
                            <Typography
                              sx={{
                                color: "#01FFFF",
                                fontFamily: "inter",
                                fontWeight: 500,
                                fontSize: "14px",
                              }}
                            >
                              ₹{cartPrice.shippingCharge}
                            </Typography>
                          </Stack>

                          <Stack
                            justifyContent="center"
                            alignItems="center"
                            direction="column"
                            spacing={2}
                            sx={{
                              padding: "20px",
                              borderTop: "1px dashed #01FFFF",
                            }}
                          >
                            <Stack
                              justifyContent="space-between"
                              alignItems="center"
                              direction="row"
                              sx={{ width: "100%" }}
                            >
                              <Typography
                                sx={{
                                  color: "#FFFFFF",
                                  fontFamily: "inter",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                }}
                              >
                                Amount Payable
                              </Typography>
                              <Typography
                                sx={{
                                  color: "#01FFFF",
                                  fontFamily: "inter",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                }}
                              >
                                ₹{cartPrice.grandTotal}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Box>

                      <Box
                        sx={{
                          width: "100%",
                          boxShadow: "0px 0px 10.798px 6.64491px rgba(0, 0, 0, 0.35)",
                          paddingX: "10px",
                          paddingY: "20px",
                        }}
                      >
                        <Stack spacing={2} justifyContent="center" alignItems="center">
                          <Stack
                            justifyContent="space-between"
                            alignItems="center"
                            direction="column"
                            sx={{
                              width: "100%",
                              borderBottom: "1px solid #01FFFF",
                              paddingBottom: "10px",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#FFFFFF",
                                fontFamily: "roboto",
                                fontWeight: 500,
                                fontSize: "22px",
                              }}
                            >
                              PAYMENT METHOD
                            </Typography>
                          </Stack>
                          <Stack
                            justifyContent="center"
                            alignItems="start"
                            sx={{ width: "100%", paddingX: "40px" }}
                          >
                            {/* <Stack
                              direction="row"
                              alignItems="center"
                              justifyContent="center"
                            > */}
                            <FormControl>
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="female"
                                name="radio-buttons-group"
                              >
                                <FormControlLabel
                                  value="male"
                                  control={
                                    <Radio
                                      checked={paymentMethod === "ONLINE"}
                                      onChange={(e) => setPaymentMethod(e.target.value)}
                                      value="ONLINE"
                                      name="radio-buttons"
                                      sx={{
                                        color: "#01FFFF",
                                        "&:disabled": {
                                          color: "#01FFFF",
                                        },
                                      }}
                                      size="small"
                                      disabled={!paymentModes.online}
                                    />
                                  }
                                  label={paymentModes.onlineName}
                                />
                                <FormControlLabel
                                  value="other"
                                  control={
                                    <Radio
                                      checked={paymentMethod === "COD"}
                                      onChange={(e) => setPaymentMethod(e.target.value)}
                                      disabled={!paymentModes.cod}
                                      value="COD"
                                      name="radio-buttons"
                                      inputProps={{ "aria-label": "COD" }}
                                      sx={{ color: "#01FFFF" }}
                                      size="small"
                                    />
                                  }
                                  label={paymentModes.codName}
                                />
                              </RadioGroup>
                            </FormControl>
                            {/* <Radio
                                checked={paymentMethod === "ONLINE"}
                                onChange={(e) =>
                                  setPaymentMethod(e.target.value)
                                }
                                value="ONLINE"
                                name="radio-buttons"
                                sx={{
                                  color: "#01FFFF",
                                  "&:disabled": {
                                    color: "#01FFFF",
                                  },
                                }}
                                size="small"
                                disabled={!paymentModes.online}

                              />
                              <Typography
                                sx={{
                                  color: "#FFFFFF",
                                  fontFamily: "inter",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  ...(!paymentModes.online && { opacity: 0.5 }),
                                }}
                              >
                                Online Payment
                              </Typography> */}
                            {/* </Stack> */}
                            {/* <Stack direction="row" alignItems="center">
                              <Radio
                                checked={paymentMethod === "COD"}
                                onChange={(e) =>
                                  setPaymentMethod(e.target.value)
                                }
                                disabled={!paymentModes.cod}
                                value="COD"
                                name="radio-buttons"
                                inputProps={{ "aria-label": "COD" }}
                                sx={{ color: "#01FFFF" }}
                                size="small"
                              />
                              <Typography
                                sx={{
                                  color: "#FFFFFF",
                                  fontFamily: "inter",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  ...(!paymentModes.cod && { opacity: 0.5 }),
                                }}
                              >
                                Cash On Delivery
                              </Typography>
                            </Stack> */}
                          </Stack>
                          <Stack justifyContent="center" alignItems="center">
                            <LoadingButton
                              sx={{
                                backgroundColor: "#01FFFF",
                                color: "#000000",
                                "&:hover": {
                                  backgroundColor: "#016b6b",
                                  color: "#000000",
                                  boxShadow: "0px 0px 10.798px 6.64491px rgba(0, 0, 0, 0.35)",
                                },
                                "&:loading": {
                                  backgroundColor: "#016b6b",
                                  color: "#000000",
                                  boxShadow: "0px 0px 10.798px 6.64491px rgba(0, 0, 0, 0.35)",
                                },
                              }}
                              onClick={handleFormSubmit}
                              variant="contained"
                              loading={isSubmitting}
                            >
                              Place Order
                            </LoadingButton>
                          </Stack>
                        </Stack>
                      </Box>
                    </Stack>
                  </Form>
                </FormikProvider>
              </Grid>
            </Grid>
          </>
        )}
      </Container>
    </>
  );
}

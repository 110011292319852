import { Close, ExpandMore } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import {
  IconButton,
  InputAdornment,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Typography,
  Divider,
  Menu,
} from "@mui/material";
import InputBase from "@mui/material/InputBase";
import { alpha, styled } from "@mui/material/styles";
import * as React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { formatCase } from "../../utils/stringStyle";

const Search = styled("div")(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
  [theme.breakpoints.up("md")]: {
    width: "auto",
  },
  [theme.breakpoints.up("lg")]: {
    width: "auto",
  },
  display: "flex",
  alignItems: "center",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  height: "100%",
  color: "white",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "white",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1.3, 1.3, 1.3, 0),
    width: "100%",
    textOverflow: "ellipsis",
    [theme.breakpoints.up("mobile")]: {
      fontSize: "13px",
    },
    [theme.breakpoints.up("xs")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "15px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "16px",
    },
  },
  width: "100%",
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  "& .MuiSelect-icon": {
    color: "#fff",
  },
  "& .MuiInputBase-root": {
    "&:before, &:after": {
      display: "none",
    },
  },
  cursor: "pointer",
}));

export default function PrimarySearch({
  handleDrawerToggle,
  mobileOpen,
  showSearch,
  setShowSearch,
}) {
  const [searchTerm, setSearchTerm] = React.useState("");
  const navigate = useNavigate();
  const [productType, setProductType] = React.useState("brand-new");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClearText = () => {
    setShowSearch(false);
    setSearchTerm("");
  };

  const handleSearch = (e) => {
    e.preventDefault();
    navigate(`/store/products?name=${searchTerm}&type=${productType}`);
    setShowSearch(false);
    setSearchTerm("");
    if (mobileOpen) {
      handleDrawerToggle();
    }
  };

  return (
    <Search>
      <SearchIconWrapper>
        <Stack
          sx={{
            cursor: "pointer",
            color: "white",
            backgroundColor: "#464646",
            borderRadius: "3px",
          }}
        >
          <Stack
            onClick={handleClick}
            direction="row"
            sx={{
              width: { mobile: "105px", xs: "130px", sm: "135px", md: "140px" , lg: "145px", xl: "150px"},
              padding: {
                mobile: "5px 1px",
                xs: "5px 4px",
                sm: "5px 5px",
                md: "5px 5px",
                lg: "5px 5px",
                xl: "5px 5px",
              },
              margin: { mobile: "3px", xs: "3px", sm: "4px", md: "4px", lg: "5px" },
            }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="subtitle1">
              {productType === "brand-new" ? "Brand-New" : "Pre-Owned"}
            </Typography>
            <ExpandMore
              sx={{
                fontSize: { mobile: "1.2rem", xs: "1.3rem", sm: "1.4rem", md: "1.5rem" },
                padding: "0px",
                margin: "0px",
              }}
            />
          </Stack>
          <Menu
            sx={{
              color: "black",
              "& .MuiPaper-root": { backgroundColor: "#1D2326" },
            }}
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              sx={{ color: "#fff" }}
              onClick={() => {
                setProductType("brand-new");
                handleClose();
              }}
            >
              <Typography variant="subtitle1" sx={{ marginX: "5px" }}>
                {formatCase("brand-new")}
              </Typography>
            </MenuItem>
            <MenuItem
              sx={{ color: "#fff" }}
              onClick={() => {
                setProductType("pre-owned");
                handleClose();
              }}
            >
              <Typography variant="subtitle1" sx={{ marginX: "5px" }}>
                Pre-Owned
              </Typography>
            </MenuItem>
          </Menu>
        </Stack>
      </SearchIconWrapper>

      <form
        onSubmit={handleSearch}
        style={{
          width: "100%",
        }}
      >
        <StyledInputBase
          fullWidth
          sx={{ width: "100%", marginLeft: "10px" }}
          placeholder="Search for products…"
          inputProps={{ "aria-label": "search" }}
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
          endAdornment={
            showSearch && (
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" onClick={handleClearText}>
                  <Close
                    sx={{
                      color: "white",
                      marginRight: {
                        mobile: "5px",
                        xs: "5px",
                        sm: "6px",
                        md: "7px",
                        lg: "10px",
                        xl: "10px",
                      },
                      fontSize: { mobile: "1.2rem", xs: "1.3rem", sm: "1.4rem", md: "1.5rem" },
                    }}
                  />
                </IconButton>
              </InputAdornment>
            )
          }
        />
      </form>
    </Search>
  );
}

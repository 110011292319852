import {
  AddShoppingCart,
  AddShoppingCartSharp,
  ExpandMore,
  FavoriteBorder,
  FavoriteRounded,
  ShoppingBasketSharp,
  ShoppingCart,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Container,
  Grid,
  Typography,
  Box,
  Rating,
  CircularProgress,
  Button,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { Stack } from "@mui/system";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import toast from "react-hot-toast";
import ImageGallery from "react-image-gallery";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import RecentlyViewed from "../../components/ProductViewSections/RecentlyViewed/RecentlyViewed";
import SimiliarProducts from "../../components/ProductViewSections/SimiliarProducts/SimiliarProducts";
import ProductBrand from "../../components/CategorySections/ProductBrand/ProductBrand";
import { getWishlist, getCart } from "../../redux/apis";
import "./imageSlider.css";
import { updateLocalCartSuccess } from "../../redux/cartSlice";
import localCart from "../../utils/localCart";
import Page from "../../utils/Page";
import BreadCrumbHeader from "../../components/BreadCrumb/BreadCrumbHeader";
import SpecTable from "../../components/SpecTable/SpecTable";
import { fCurrency } from "../../utils/stringStyle";
import PreOwnedTag from "../../components/PreOwnedTag";

export default function ProductView() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.user.userInfo);
  const { id } = useParams();
  const wishlistItems = useSelector((state) => state.wishlist.wishlistItems);
  const cartItems = useSelector((state) => state.cart.cartItems);
  const [product, setProduct] = useState({});
  const [similiarProducts, setSimiliarProducts] = useState([]);
  const [images, setImages] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [viewAllClicked, setViewAllClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [similiarProductsLoading, setSimiliarProductsLoading] = useState(true);
  const [isWishlist, setIsWishlist] = useState(false);
  const [isCart, setIsCart] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const mobile = useMediaQuery("(max-width:600px)");

  const discountPercentage = (sellingPrice, mrp) => {
    return Math.round((1 - sellingPrice / mrp) * 100);
  };

  const getProduct = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/products/user/`, {
        params: { id },
      });
      console.log(res.data.data, "producttttttttttttttttttttt");
      if (!res.data.isError) {
        setProduct(res.data.data);
        setImages(
          res.data.data.images.map((image) => ({
            original: image.fileUrl,
            thumbnail: image.fileUrl,
          }))
        );
        setTableData(res.data.data.tableDescription);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  const getSimiliarProducts = async (size) => {
    try {
      setSimiliarProductsLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/products/user/similiar`, {
        params: { productId: product._id, page: 0, size },
      });
      console.log("similiarProducts", res);
      setSimiliarProducts(res.data.data.products);
      setSimiliarProductsLoading(false);
      if (size === 9 || res.data.data.maxRecords <= size) {
        setViewAllClicked(true);
      }
    } catch (error) {
      console.log(error);
      setSimiliarProductsLoading(false);
    }
  };

  const addToWishlist = async () => {
    // setButtonLoading(true);
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/wishlist`, {
        productId: id,
      });
      console.log(res.data.data);
      // setButtonLoading(false);
      if (!res.data.isError) {
        console.log("added to wishlist");
        toast.success("Added to wishlist");
        getWishlist(dispatch);
      }
    } catch (error) {
      console.log(error);
      // setButtonLoading(false);
    }
  };

  const addToCart = async () => {
    console.log(userInfo.fullname);
    if (userInfo.fullname) {
      setButtonLoading(true);
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/cart/add`, {
          productId: id,
          quantity: 1,
        });
        console.log(res.data.data);
        setButtonLoading(false);
        if (!res.data.isError) {
          console.log("added to cart");
          toast.success("Added to cart");
          getCart(dispatch);
        }
      } catch (error) {
        console.log(error);
        setButtonLoading(false);
      }
    } else {
      const currentCartItems = JSON.parse(localStorage.getItem("cartItems"));
      if (currentCartItems) {
        const item = currentCartItems.find((item) => item.productId === id);
        if (item) {
          const newCartItems = currentCartItems.map((item) => {
            if (item.productId === id) {
              return { ...item, quantity: item.quantity + 1 };
            } else {
              return item;
            }
          });
          localStorage.setItem("cartItems", JSON.stringify(newCartItems));
          updateLocalCartStates(newCartItems);
          toast.success("Added to cart");
          checkifInCart();
        } else {
          const newCartItems = [...currentCartItems, product];
          console.log("newCartItems", newCartItems);
          localStorage.setItem("cartItems", JSON.stringify(newCartItems));
          updateLocalCartStates(newCartItems);
          toast.success("Added to cart");
          checkifInCart();
        }
      } else {
        localStorage.setItem("cartItems", JSON.stringify([product]));
        updateLocalCartStates([product]);
        toast.success("Added to cart");
        checkifInCart();
      }
    }
  };

  const updateLocalCartStates = (cartItems) => {
    const createLocalCart = localCart(cartItems);
    dispatch(updateLocalCartSuccess(createLocalCart));
  };

  const removeFromWishlist = async () => {
    // setButtonLoading(true);
    try {
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/wishlist/remove`, {
        productId: id,
      });
      console.log(res.data.data);
      // setButtonLoading(false);
      if (!res.data.isError) {
        console.log("removed from wishlist");
        toast.success("Removed from wishlist");
        getWishlist(dispatch);
      }
    } catch (error) {
      console.log(error);
      // setButtonLoading(false);
    }
  };

  useEffect(() => {
    getProduct();
    checkifInCart();
    window.scrollTo(0, 0);
  }, [id]);

  useEffect(() => {
    if (product && product._id) {
      getSimiliarProducts(5); // 5 is the number of products to be fetched
    }
  }, [product]);

  useEffect(() => {
    if (wishlistItems?.length > 0) {
      const item = wishlistItems.find((item) => item._id === id);
      if (item) {
        setIsWishlist(true);
        console.log(true);
      } else {
        setIsWishlist(false);
        console.log(false);
      }
    } else {
      setIsWishlist(false);
    }
  }, [wishlistItems]);

  const checkifInCart = () => {
    if (userInfo.fullname && cartItems && cartItems.length > 0) {
      const item = cartItems.find((item) => item.productId === id);
      if (item) {
        setIsCart(true);
      } else {
        setIsCart(false);
      }
    } else if (!userInfo.fullname) {
      const currentCartItems = JSON.parse(localStorage.getItem("cartItems"));
      if (currentCartItems) {
        const item = currentCartItems.find((item) => item._id === id);
        if (item) {
          setIsCart(true);
        } else {
          setIsCart(false);
        }
      } else {
        setIsCart(false);
      }
    }
  };

  useEffect(() => {
    checkifInCart();
  }, [cartItems]);

  useEffect(() => {
    if (product?._id) {
      const recentlyViewed = JSON.parse(localStorage.getItem("recentlyViewed"));
      if (recentlyViewed && recentlyViewed?.length) {
        const item = recentlyViewed.find((item) => item._id === id);
        if (!item) {
          const newRecentlyViewed = [product, ...recentlyViewed];
          localStorage.setItem("recentlyViewed", JSON.stringify(newRecentlyViewed));
        }
      } else {
        localStorage.setItem("recentlyViewed", JSON.stringify([product]));
      }
    }
  }, [product]);

  //  function for sentence case
  const sentenceCase = (str) => {
    if (str === null || str === "" || str === undefined) return false;
    else str = str.toString();
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const items = [
    { text: sentenceCase(product?.productType), link: `/store?type=${product.productType}` },
    {
      text: sentenceCase(product?.category),
      link: `/store/products?tab=${product.categoryId}&type=${product.productType}`,
    },
  ];
  const currentPage = product.shortName;

  return (
    <>
      <Page title="Product">
        <BreadCrumbHeader items={items} currentPage={currentPage} />
        <Container
          maxWidth="xl"
          sx={{
            paddingX: { xl: "100px", lg: "50px" },
            marginBottom: {
              mobile: "50px",
              xs: "60px",
              sm: "70px",
              md: "80px",
              lg: "100px",
              xl: "100px",
            },
            background: "#1D2326",
            marginTop: "10px",
            boxShadow: {
              xs: "none",
              md: "none",
              lg: "0px 16px 51.3281px -21.6719px #000000",
              xl: "0px 16px 51.3281px -21.6719px #000000",
            },
            paddingY: { xs: "20px", sm: "30px", md: "40px", lg: "50px", xl: "50px" },
          }}
        >
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "50vh",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <Grid container sx={{ color: "white" }} columnSpacing={5}>
              <Grid item mobile={12} md={6}>
                <Grid container spacing={2}>
                  <Grid item mobile={12}>
                    <Box width="100%" height="100%" sx={{ position: "relative" }}>
                      <Box
                        sx={{
                          position: "absolute",
                          zIndex: 5,
                          right: 15,
                          top: 15,
                        }}
                      >
                        <FavoriteRounded
                          onClick={() => {
                            isWishlist ? removeFromWishlist() : addToWishlist();
                          }}
                          sx={{
                            color: isWishlist ? "#01FFFF" : "#505051",
                            cursor: "pointer",
                            fontSize: "30px",
                          }}
                        />
                      </Box>
                      {images.length && (
                        <ImageGallery
                          items={images}
                          showPlayButton={false}
                          thumbnailPosition={mobile ? "bottom" : "left"}
                          showNav={false}
                        />
                      )}
                    </Box>
                  </Grid>
                  <Grid item mobile={12}>
                    <Stack
                      direction="row"
                      mt={{
                        mobile: 1,
                        xs: 2,
                        sm: 3,
                      }}
                    >
                      <Stack
                        sx={{
                          width: {
                            mobile: "0px",
                            xs: "0px",
                            sm: "100px",
                            md: "130px",
                          },
                        }}
                      />
                      <Stack direction="row" justifyContent="center" mb={2} sx={{ width: "100%" }}>
                        <Stack
                          direction="row"
                          justifyContent="center"
                          spacing={2}
                          paddingX={{
                            mobile: 0,
                            xs: 0,
                            sm: 2,
                          }}
                          sx={{
                            width: "100%",
                          }}
                        >
                          <LoadingButton
                            loading={buttonLoading}
                            onClick={addToCart}
                            disabled={isCart || product.stock === "OUT_OF_STOCK"}
                            startIcon={<AddShoppingCartSharp />}
                            sx={{
                              width: "50%",
                              height: "36px",
                              background: "#01ffff",
                              fontFamily: "Roboto",
                              fontWeight: 500,
                              fontSize: { mobile: "14px", xs: "14px", sm: "16px", md: "18px" },
                              color: "#000",
                              borderRadius: "0px",
                              "&:hover": {
                                color: "rgba(255, 255, 255, 1)",
                                background: "#035252",
                              },

                              "&:disabled": {
                                background: "transparent",
                                color: "rgba(255, 255, 255, 0.5)",
                                border: "1px solid rgba(255, 255, 255, 0.5)",
                              },
                            }}
                          >
                            ADD TO CART
                          </LoadingButton>
                          <LoadingButton
                            startIcon={<ShoppingBasketSharp />}
                            disabled={!isCart}
                            sx={{
                              width: "50%",
                              height: "36px",
                              background: "#01ffff",
                              fontFamily: "Roboto",
                              fontWeight: 500,
                              fontSize: { mobile: "14px", xs: "14px", sm: "16px", md: "18px" },
                              color: "#000",
                              borderRadius: "0px",
                              "&:hover": {
                                color: "rgba(255, 255, 255, 1)",
                                background: "#035252",
                              },

                              "&:disabled": {
                                background: "transparent",
                                color: "rgba(255, 255, 255, 0.5)",
                                border: "1px solid rgba(255, 255, 255, 0.5)",
                              },
                            }}
                            onClick={() => navigate("/store/cart")}
                          >
                            GO TO CART
                          </LoadingButton>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item mobile={12} md={6}>
                <Stack spacing={3}>
                  <Stack sx={{ width: "100%" }} spacing={1}>
                    {product?.productType === "pre-owned" && (
                      <Box>
                        <PreOwnedTag />
                      </Box>
                    )}
                    <Typography variant="h3">{product.name}</Typography>{" "}
                  </Stack>
                  {product.stock === "IN_STOCK" && (
                    <Stack direction="column" justifyContent="center" alignItems="start">
                      <Typography
                        variant="h4"
                        sx={{
                          color: "#01ffff",

                          marginY: "2px",
                        }}
                      >
                        ₹ {fCurrency(product.sellingPrice)}
                      </Typography>
                      <Stack direction="row" justifyContent="start" spacing={1} alignItems="center">
                        <Typography
                          variant="h4"
                          sx={{
                            color: "#ffffff",

                            opacity: 0.7,
                            textDecorationLine: "line-through",
                          }}
                        >
                          ₹ {fCurrency(product.mrp)}
                        </Typography>
                        <Typography
                          variant="h4"
                          sx={{
                            color: "#01ffff",

                            opacity: 0.7,
                          }}
                        >
                          {discountPercentage(product.sellingPrice, product.mrp)}% off
                        </Typography>
                      </Stack>
                    </Stack>
                  )}
                  {product.stock === "OUT_OF_STOCK" && (
                    <Stack direction="column" justifyContent="center" alignItems="start">
                      <Typography
                        variant="h4"
                        sx={{
                          color: "#FF2E2E",
                        }}
                      >
                        Out of Stock
                      </Typography>
                    </Stack>
                  )}
                  <Stack direction="column" spacing={0.5}>
                    <Accordion
                      defaultExpanded
                      sx={{
                        background: "#15161a",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMore sx={{ color: "white" }} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            color: "#ffffff",
                          }}
                        >
                          Specifications
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography
                          variant="body1"
                          sx={{
                            color: "#ffffff",
                          }}
                        >
                          <ul>
                            {product.specifications.map((specification) => (
                              <li key={specification.id}>
                                <Typography variant="subtitle1">{specification}</Typography>
                              </li>
                            ))}
                          </ul>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      defaultExpanded
                      sx={{
                        background: "#15161a",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMore sx={{ color: "white" }} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            color: "#ffffff",
                          }}
                        >
                          Description
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            color: "#ffffff",
                          }}
                        >
                          {product.description}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>

                    {/* table  */}
                    {tableData && !!tableData.length && <SpecTable tableData={tableData} />}
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          )}
        </Container>
        {!!similiarProducts?.length && (
          <SimiliarProducts
            similiarProducts={similiarProducts}
            loading={similiarProductsLoading}
            getSimiliarProducts={getSimiliarProducts}
            viewAllClicked={viewAllClicked}
          />
        )}
        <RecentlyViewed />
        <ProductBrand />
      </Page>
    </>
  );
}

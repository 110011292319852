import { AddCircleRounded, RemoveCircleRounded } from "@mui/icons-material";
import { Box, Button, Card, CardMedia, IconButton, Paper, Stack, Typography } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getCart, getLocalcart } from "../../redux/apis";
import { fCurrency } from "../../utils/stringStyle";

const CartProCard = ({ item }) => {
  const token = localStorage.getItem("accessToken");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isloading, setIsLoading] = useState(false);

  const addQuantity = async () => {
    try {
      if (token) {
        setIsLoading(true);
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/cart/add`, {
          productId: item?.productId,
        });
        getCart(dispatch);
        setIsLoading(false);
      } else {
        const localCartItems = JSON.parse(localStorage.getItem("cartItems"));
        const newLocalCartItems = localCartItems.map((cartItem) => {
          if (cartItem._id === item.productId) {
            if (cartItem.quantity) {
              if (cartItem.quantity > 9) {
                toast.error("You can't add more than 10 items");
                return cartItem;
              }
              return { ...cartItem, quantity: cartItem.quantity + 1 };
            } else {
              return { ...cartItem, quantity: 2 };
            }
          } else {
            return cartItem;
          }
        });
        localStorage.setItem("cartItems", JSON.stringify(newLocalCartItems));
        getLocalcart(dispatch);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
      getCart(dispatch);
      setIsLoading(false);
    }
  };

  const decreaseQuantity = async () => {
    try {
      if (token) {
        setIsLoading(true);
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/cart/decrease`, {
          productId: item?.productId,
        });
        getCart(dispatch);
        setIsLoading(false);
      } else {
        const localCartItems = JSON.parse(localStorage.getItem("cartItems"));

        const newLocalCartItems = localCartItems.map((cartItem) => {
          if (cartItem._id === item.productId) {
            if (cartItem.quantity && cartItem.quantity > 1) {
              return { ...cartItem, quantity: cartItem.quantity - 1 };
            }
          } else {
            return cartItem;
          }
        });
        localStorage.setItem("cartItems", JSON.stringify(newLocalCartItems));
        getLocalcart(dispatch);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
      getCart(dispatch);
      setIsLoading(false);
    }
  };

  const removeProduct = async () => {
    try {
      if (token) {
        setIsLoading(true);
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/cart/remove`, {
          productId: item?.productId,
        });
        getCart(dispatch);
        setIsLoading(false);
      } else {
        const localCartItems = JSON.parse(localStorage.getItem("cartItems"));

        const newLocalCartItems = localCartItems.filter(
          (cartItem) => cartItem._id !== item.productId
        );
        if (newLocalCartItems.length === 0) localStorage.removeItem("cartItems");
        localStorage.setItem("cartItems", JSON.stringify(newLocalCartItems));
        getLocalcart(dispatch);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
      getCart(dispatch);
      setIsLoading(false);
    }
  };

  const calculateDiscount = (mrp, sellingPrice) => {
    return Math.round(((mrp - sellingPrice) / mrp) * 100);
  };

  return (
    <Paper elevation={5} sx={{ padding: "20px", backgroundColor: "#1D2326", marginBottom: "30px" }}>
      <Stack direction={{ mobile: "column", xs: "column", lg: "row" }} spacing={2}>
        <Stack alignItems={"center"} spacing={2}>
          <Card
            sx={{
              width: "100%",
              margin: "0 auto",
              backgroundColor: "#0D0D0F",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              cursor: "pointer",
            }}
          >
            <CardMedia
              component="img"
              sx={{
                maxHeight: { mobile: 260, xs: 260, sm: 260, md: 320 },
                minHeight: { mobile: 260, xs: 260, sm: 260, md: 320 },
                minWidth: { mobile: "100%", xs: "100%", sm: 200, md: 260 },
                maxWidth: { mobile: "100%", xs: "100%", sm: 200, md: 260 },
                objectFit: "contain",
                cursor: "pointer",
              }}
              image={item?.image?.fileUrl}
              alt="product"
              loading="lazy"
              onClick={() => navigate(`/store/product/${item?.productId}`)}
            />
          </Card>

          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{
              backgroundColor: "#0D0D0F",
              borderRadius: "20px",
              width: "124px",
            }}
          >
            <IconButton
              disabled={isloading || item.quantity < 2}
              aria-label="decrement"
              onClick={decreaseQuantity}
              sx={{
                "&:disabled": {
                  opacity: 0.4,
                },
              }}
            >
              <RemoveCircleRounded sx={{ color: "#01FFFF" }} />
            </IconButton>
            <Typography variant="h6" color={"white"}>
              {item?.quantity}
            </Typography>
            <IconButton
              disabled={isloading}
              aria-label="increment"
              onClick={addQuantity}
              sx={{
                "&:disabled": {
                  opacity: 0.4,
                },
              }}
            >
              <AddCircleRounded sx={{ color: "#01FFFF" }} />
            </IconButton>
          </Stack>
        </Stack>
        <Stack sx={{ width: "100%" }}>
          <Box minHeight={{ lg: 320 }} maxHeight={{ lg: 320 }}>
            <Typography variant="h4" fontWeight={"bold"}>
              {item?.shortName}
            </Typography>
            <Typography variant="h4" color="#00FFFF">
              &#8377; {fCurrency(item?.sellingPrice)}
            </Typography>
            <Stack direction={"row"} spacing={1} mb={2}>
              <Typography variant="h4" color="white" sx={{ textDecorationLine: "line-through" }}>
                &#8377; {item?.mrp}
              </Typography>
              <Typography variant="h4" color="white" fontWeight={"bold"}>
                {calculateDiscount(item?.mrp, item?.sellingPrice)}% off
              </Typography>
            </Stack>
            {/* decription  */}
            <Typography
              variant="subtitle1"
              color="white"
              display={{ mobile: "none", xs: "none", sm: "block" }}
            >
              <ul>
                {item?.specifications.map((spec, index) => {
                  return <li key={index}>{spec}</li>;
                })}
              </ul>
            </Typography>
          </Box>
          <Stack
            direction="row"
            sx={{
              justifyContent: {
                mobile: "center",
                xs: "center",
                sm: "end",
                md: "end",
                lg: "end",
                xl: "end",
              },
              mt: "20px",
              width: "100%",
            }}
          >
            <Button
              disabled={isloading}
              onClick={removeProduct}
              size="large"
              variant="contained"
              color="secondary"
              sx={{ width: "260px", backgroundColor: "#01FFFF", color: "#000" }}
            >
              Remove
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default CartProCard;

import { Box, Stack, Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import viewMore from "../../assets/img/viewMore.png";
import { formatCase } from "../../utils/stringStyle";

export default function CategoryCard({ category, setScroll }) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const categoryId = searchParams.get("tab");
  const productType = searchParams.get("type") || "brand-new";
  const setParams = () => {
    navigate(`/store/products?tab=${category._id}&type=${productType}`);
    if (setScroll) {
      setScroll(true);
    }
  };

  if (category._id !== "all") {
    return (
      <Box
        my={{ mobile: "0.4", xs: 0.5, sm: 1, md: 2, lg: 3 }}
        onClick={setParams}
        className="categoryCard"
        sx={{
          maxWidth: "229px",
          height: {
            mobile: "70px",
            xs: "80px",
            sm: "150px",
            md: "190px",
            lg: "220px",
            xl: "229px",
          },
          borderRadius: "10px",
          cursor: "pointer",
          "&:hover": {
            transform: "scale(1.1)",
            transition: "transform 0.2s",
          },
        }}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Stack
          direction="column"
          alignItems="center"
          justifyContent={"space-between"}
          sx={{
            height: "100%",
            width: "100%",
            // paddingTop: "10px",
          }}
        >
          <Stack
            className="imageWrapper"
            sx={{ maxWidth: "229px" }}
            justifyContent="center"
            alignItems="center"
          >
            <Box
              component="img"
              sx={{
                objectFit: "contain",
                width: {
                  mobile: "50px",
                  xs: "60px",
                  sm: "121px",
                  md: "150px",
                  lg: "165px",
                  xl: "180px",
                },
                height: {
                  mobile: "50px",
                  xs: "60px",
                  sm: "121px",
                  md: "150px",
                  lg: "165px",
                  xl: "180px",
                },
              }}
              src={category?.image?.fileUrl}
              alt="monitor"
              loading="lazy"
            />
          </Stack>
          <Typography
            variant="h4"
            fontSize={{ mobile: "11px", xs: "12px", sm: "15px", md: "18px" }}
          >
            {formatCase(category?.name, "upper")}
          </Typography>
        </Stack>
      </Box>
    );
  } else {
    return (
      <Stack
        onClick={setParams}
        className="categoryCard"
        sx={{
          maxWidth: "229px",
          height: {
            mobile: "100px",
            xs: "130px",
            sm: "150px",
            md: "190px",
            lg: "220px",
            xl: "229px",
          },
          borderRadius: "10px",
          cursor: "pointer",
          "&:hover": {
            transform: "scale(1.1)",
            transition: "transform 0.2s",
          },
        }}
        justifyContent="center"
        alignItems="center"
      >
        <Stack
          sx={{
            background:
              " linear-gradient(180deg, rgba(217, 217, 217, 0.31) 0%, rgba(238, 225, 225, 0.124) 100%)",
            height: "70%",
            width: {
              mobile: "60px",
              xs: "85px",
              sm: "100px",
              md: "120px",
              lg: "130px",
              xl: "135px",
            },
            borderRadius: "10px",
          }}
          justifyContent="center"
          alignItems="center"
          spacing={{
            mobile: 0.4,
            xs: 0.5,
            sm: 0.6,
            md: 0.7,
            lg: 1,
            xl: 1,
          }}
        >
          <Typography
            textAlign={"center"}
            variant="h4"
            fontSize={{ mobile: "11px", xs: "12px", sm: "15px", md: "18px" }}
          >
            View All
          </Typography>
          <Box
            component="img"
            sx={{
              objectFit: "contain",
              width: {
                mobile: "14px",
                xs: "22px",
                sm: "24px",
                md: "28px",
                lg: "30px",
                xl: "32px",
              },
            }}
            src={viewMore}
            alt="monitor"
            loading="lazy"
          />
        </Stack>
      </Stack>
    );
  }
}

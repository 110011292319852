import { createSlice } from "@reduxjs/toolkit";

export const cartSlice = createSlice({
  name: "cart",
  initialState: {
    cartItems: [],
    cartPrice: {},
    localCartItems: [],
    localCartPrice: {},
    pending: false,
    error: false,
  },

  reducers: {
    updateCartStart: (state) => {
      state.pending = true;
    },
    updateCartSuccess: (state, action) => {
      state.pending = false;
      state.cartItems = action.payload?.products;
      state.cartPrice = {
        maxPrice: action.payload?.maxPrice,
        subTotal: action.payload?.subTotal,
        discount: action.payload?.discount,
        shippingCharge: action.payload?.shippingCharge,
        grandTotal: action.payload?.grandTotal,
      };
      state.localCartItems=[]
      state.localCartPrice={}
    },
    updateLocalCartSuccess: (state, action) => {
      state.pending = false;
      state.localCartItems = action.payload.products;
      state.localCartPrice = {
        maxPrice: action.payload?.maxPrice,
        subTotal: action.payload?.subTotal,
        discount: action.payload?.discount,
        shippingCharge: action.payload?.shippingCharge,
        grandTotal: action.payload?.grandTotal,
      };
      state.cartItems=[]
      state.cartPrice={}
    },
    updateCartFailure: (state) => {
      state.pending = false;
      state.error = true;
    },
    emptyCart: (state) => {
      state.cartItems = [];
      state.cartPrice = {};
    },
    emptyLocalCart: (state) => {
      state.localCartItems = [];
      state.localCartPrice = {};
    }
  },
});

export const { updateCartStart, updateCartSuccess,updateLocalCartSuccess, updateCartFailure, emptyCart,emptyLocalCart } =
  cartSlice.actions;
export default cartSlice.reducer;

import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import useResponsive from "../../../utils/useResponsive";

export default function ListComponent({ text }) {
  const smUp = useResponsive("up", "sm");
  return (
    <>
      {smUp && (
        <Typography
          variant="promoSectionContent"
          textAlign={"center"}
          sx={{
            width: "100%",
          }}
        >
          <span>
            <Box
              component={"img"}
              src={"/images/adjust.png"}
              sx={{
                height: {
                  mobile: 8,
                  xs: 10,
                  sm: 12,
                  md: 13,
                  lg: 16,
                },
              }}
            />
          </span>{" "}
          &nbsp;
          {text}
        </Typography>
      )}
      {!smUp && (
        <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
          <Box
            sx={{
              height: "100%",
            }}
          >
            <Box
              component={"img"}
              src={"/images/adjust.png"}
              sx={{
                height: {
                  mobile: 8,
                  xs: 10,
                  sm: 12,
                  md: 13,
                  lg: 16,
                },
              }}
              mt={1}
            />
          </Box>
          <Typography
            variant="promoSectionContent"
            textAlign={"left"}
            sx={{
              width: "100%",
            }}
          >
            {text}
          </Typography>
        </Stack>
      )}
    </>
  );
}

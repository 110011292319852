import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import ImageGallery from "react-image-gallery";
import { Link, useNavigate } from "react-router-dom";
import newProduct from "../../../assets/img/newProductBanner.jpg";
import preOwned from "../../../assets/img/preOwnedBanner.jpg";
// import BannerText from "./BannerText";
import "./probannerSlider.css";

export default function PromoBanner() {
  const images = [
    {
      original: newProduct,
      thumbnail: newProduct,
      navigate: "/store/products?type=brand-new",
    },
    {
      original: preOwned,
      thumbnail: preOwned,
      navigate: "/store/products?type=pre-owned",
    },
  ];

  const navigate = useNavigate();

  const handleImageClick = (index) => {
    console.log(index);
    const image = images[index];
    navigate(image.navigate);
    console.log(image.navigate);
  };

  return (
    <Box className="probanner" sx={{ width: "100%" }}>
      <ImageGallery
        items={images}
        autoPlay={true}
        showThumbnails={false}
        showBullets={true}
        showFullscreenButton={false}
        showPlayButton={false}
        showNav={true}
        infinite={true}
        slideDuration={1600}
        slideInterval={5000}
        additionalClass="promobanner"
        onClick={handleImageClick}
        renderItem={({ original, navigate }) => (
          <Link to={navigate}>
            <img src={original} alt="" />
          </Link>
        )}
      />
    </Box>
  );
}

import {
  AccountCircle,
  CardTravelRounded,
  CloseOutlined,
  ExpandLess,
  ExpandMore,
  FavoriteRounded,
  Login,
  Menu,
  Search,
  ShoppingCart,
} from "@mui/icons-material";
import {
  Badge,
  Box,
  Button,
  ClickAwayListener,
  Collapse,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Slide,
  Stack,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import axios from "axios";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { getCart, getWishlist } from "../../redux/apis";
import { emptyCart, updateCartFailure, updateCartSuccess } from "../../redux/cartSlice";
import useResponsive from "../../utils/useResponsive";
import { changeNavSelection } from "../../redux/navSelectionSlice";
import { logoutUser, removeUser } from "../../redux/userSlice";
import { emptyWishlist } from "../../redux/wishlistSlice";
import PrimarySearch from "../Search/Search";
import MenuComponent from "./MenuComponent";
import StoreMenu from "./StoreMenu";
import facebook from "../../assets/icon/facebbook.png";
import instagram from "../../assets/icon/instagram.png";
import twitter from "../../assets/icon/twitter.png";
import discord from "../../assets/icon/discord.png";
import { formatCase } from "../../utils/stringStyle";
import NavItem from "./NavItem";
import navConfig from "./navConfig";

export default function NavBar() {
  const location = useLocation();
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.user.userInfo);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [storeOpen, setStoreOpen] = useState(true);

  useEffect(() => {
    if (location.pathname.startsWith("/store")) {
      setStoreOpen(true);
    }
  }, [location.pathname]);

  const [showSearch, setShowSearch] = useState(false);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const type = searchParams.get("type");
  const smUp = useResponsive("up", "sm");

  const mdUp = useResponsive("up", "md");

  const handleStoreClick = () => {
    setStoreOpen(!storeOpen);
  };

  const cartItems = useSelector((state) => state.cart.cartItems);
  const localCartItems = useSelector((state) => state.cart.localCartItems);
  const wishlistCount = useSelector((state) => state.wishlist.wishlistCount);

  const handleLogout = () => {
    dispatch(logoutUser());
  };

  const handleButtonClick = (event) => {
    setMobileOpen(!mobileOpen);
  };

  const handleClickAway = () => {
    setMobileOpen(false);
  };

  useEffect(() => {
    if (userInfo.fullname) {
      getCart(dispatch);
      getWishlist(dispatch);
    } else {
      dispatch(emptyCart());
      dispatch(emptyWishlist());
    }
  }, [userInfo]);

  const searchIconRef = useRef(null);
  console.log(searchIconRef);
  return (
    <Stack
      className="navBar"
      sx={{
        bgcolor: "#000000",
        position: "sticky",
        top: "0px",
        height: { mobile: "70px", xs: "70px", sm: "80px", md: "90px", lg: "100px" },
        paddingX: { mobile: "10px", xs: "10px", sm: "40px", md: "60px", lg: "72px" },
        zIndex: 999,
      }}
      justifyContent="center"
    >
      {/* drawer   */}
      <Drawer anchor="left" open={mobileOpen} onClose={() => setMobileOpen(false)}>
        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="start"
          sx={{
            bgcolor: "#000000",
            height: "100%",
          }}
        >
          <Stack>
            <Link to="/">
              <Box
                component={"img"}
                onClick={() => setMobileOpen(false)}
                style={{ width: "210px", height: "auto" }}
                src="/images/logo.png"
                alt="logo"
                mt={1}
              />
            </Link>
            <Stack direction={"column"} paddingY={2} spacing={1} sx={{ with: "210px" }}>
              <Box
                width={"100%"}
                sx={{
                  ...("/" === location.pathname && {
                    backgroundColor: "#1c1c1c",
                  }),
                  paddingX: 2,
                  paddingY: 1,
                }}
              >
                <Link className="link" to="/">
                  <Typography
                    onClick={() => setMobileOpen(false)}
                    variant="h6"
                    style={{
                      color: "#01FFFF",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {formatCase("home")}
                  </Typography>
                </Link>
              </Box>

              <Stack direction="column" spacing={1}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent={"space-between"}
                  spacing={1}
                  sx={{
                    width: "210px",
                    ...(location.pathname.startsWith("/store") && {
                      backgroundColor: "#1c1c1c",
                    }),
                    paddingX: 2,
                    paddingY: 1,
                  }}
                  onClick={handleStoreClick}
                >
                  <Typography
                    variant="h6"
                    style={{
                      color: "#01FFFF",
                      fontFamily: "Poppins, sans-serif",
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    {formatCase("store")}
                  </Typography>
                  {storeOpen ? (
                    <ExpandLess sx={{ color: "#01FFFF" }} />
                  ) : (
                    <ExpandMore sx={{ color: "#01FFFF" }} />
                  )}
                </Stack>
                <Collapse in={storeOpen} timeout="auto" unmountOnExit>
                  <Stack spacing={1}>
                    <Box
                      sx={{
                        width: "100%",
                        height: "100%",
                        ...(location.pathname.startsWith("/store") &&
                          type === "brand-new" && {
                            backgroundColor: "#1c1c1c",
                          }),
                      }}
                      pl={4}
                      py={1}
                    >
                      <Link className="link" to="/store?type=brand-new">
                        <Typography
                          onClick={() => setMobileOpen(false)}
                          variant="h6"
                          style={{
                            color: "#01FFFF",
                            fontFamily: "Poppins, sans-serif",
                            fontWeight: 300,
                            fontSize: "16px",
                          }}
                        >
                          {formatCase("brand-new")}
                        </Typography>
                      </Link>
                    </Box>
                    <Box
                      sx={{
                        width: "100%",
                        height: "100%",
                        ...(location.pathname.startsWith("/store") &&
                          type === "pre-owned" && {
                            backgroundColor: "#1c1c1c",
                          }),
                      }}
                      pl={4}
                      py={1}
                    >
                      <Link className="link" to="/store?type=pre-owned">
                        <Typography
                          onClick={() => setMobileOpen(false)}
                          variant="h6"
                          style={{
                            color: "#01FFFF",
                            fontFamily: "Poppins, sans-serif",
                            fontWeight: 300,
                            fontSize: "16px",
                          }}
                        >
                          {formatCase("pre-owned")}
                        </Typography>
                      </Link>
                    </Box>
                  </Stack>
                </Collapse>
              </Stack>

              <Box
                width={"100%"}
                sx={{
                  ...(location.pathname.startsWith("contact-us") && {
                    backgroundColor: "#1c1c1c",
                  }),
                  paddingX: 2,
                  paddingY: 1,
                }}
              >
                <Link className="link" to="/contact-us">
                  <Typography
                    onClick={() => setMobileOpen(false)}
                    variant="h6"
                    style={{
                      color: "#01FFFF",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {formatCase("contact")}
                  </Typography>
                </Link>
              </Box>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            padding={2.5}
          >
            {/* <Instagram sx={{ color: "white" }} /> */}
            <img
              src={instagram}
              alt="instagram"
              style={{ cursor: "pointer", height: "25px" }}
              onClick={() => {
                window.open("https://www.instagram.com/lambda.gaming", "_blank");
              }}
            />
            <img
              src={facebook}
              alt="facebook"
              style={{ cursor: "pointer", height: "25px" }}
              onClick={() => {
                window.open("https://www.facebook.com/zonelambda", "_blank");
              }}
            />
            <img
              src={twitter}
              alt="twitter"
              style={{ cursor: "pointer", height: "25px" }}
              onClick={() => {
                window.open("https://twitter.com/lambda_gaming", "_blank");
              }}
            />
            {/* <img src={discord} alt="discord" style={{ cursor: "pointer" }} /> */}
          </Stack>
        </Stack>
      </Drawer>

      {/* drawer end  */}

      <Stack className="wrapper" direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction={"row"} spacing={{ mobile: 0, xs: 2 }} justifyContent={"flex-start"}>
          <IconButton
            className="menu"
            onClick={handleButtonClick}
            sx={{
              display: {
                mobile: !showSearch ? "block" : "none",
                xs: !showSearch ? "block" : "none",
                sm: "block",
                md: "block",
                lg: "none",
              },
            }}
          >
            <Menu sx={{ color: "#01FFFF" }} />
          </IconButton>
          <Link to="/">
            <Stack
              className="left"
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                height: "90px",
                width: {
                  mobile: "100px",
                  xs: "100px",
                  sm: "100px",
                  md: "150px",
                  lg: "200px",
                },
                transition: "0.5s ease",
                display: {
                  mobile: !showSearch ? "flex" : "none",
                  xs: !showSearch ? "flex" : "none",
                  sm: "flex",
                  md: "flex",
                  lg: "flex",
                },
              }}
            >
              <img style={{ width: "100%", height: "auto" }} src="/images/logo.png" alt="logo" />
            </Stack>
          </Link>
        </Stack>
        {showSearch && (
          <div style={{ overflow: "hidden", width: "100%" }}>
            <Slide
              direction="left"
              in={showSearch}
              container={searchIconRef.current}
              mountOnEnter
              unmountOnExit
              easing={{
                enter: "linear",
                exit: "linear",
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                  // margin: "15px 20px 15px 0px ",
                  margin: {
                    mobile: " 0px ",
                    xs: "15px 20px 15px 0px",
                    sm: "15px 20px 15px 0px",
                    md: "15px 20px 15px 0px",
                    lg: "15px 20px 15px 0px",
                  },
                }}
              >
                <PrimarySearch setShowSearch={setShowSearch} showSearch={showSearch} />
              </Box>
            </Slide>
          </div>
        )}
        <Stack direction={"row"} spacing={4}>
          {!showSearch && (
            <Stack
              className="right"
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              sx={{
                color: "#01FFFF",
                fontFamily: "Poppins, sans-serif",
                display: { mobile: "none", xs: "none", sm: "none", md: "none", lg: "flex" },
              }}
            >
              {navConfig.map((item, index) => {
                return (
                  <NavItem
                    key={index}
                    path={item.path}
                    menu={item.menu}
                    name={item.name}
                    menuItem={item.menuItem}
                  />
                );
              })}
            </Stack>
          )}

          <Stack
            justifyContent={"center"}
            alignItems="center"
            spacing={1}
            direction="row"
            ref={searchIconRef}
            overflow="hidden"
            maxWidth={{ mobile: "auto", xs: "auto", md: 250 }}
            minHeight={{ mobile: 50, xs: 50, sm: 50 }}
          >
            {!showSearch && (
              <IconButton
                size="small"
                aria-label="show 4 new mails"
                color="inherit"
                onClick={() => setShowSearch(true)}
              >
                <Search sx={{ color: "#01ffff", fontSize: "24px" }} />
              </IconButton>
            )}
            {mdUp && (
              <IconButton onClick={() => navigate("/store/wishlist")}>
                <Badge badgeContent={wishlistCount} color="error">
                  <FavoriteRounded sx={{ color: "#01ffff", fontSize: "24px" }} />
                </Badge>
              </IconButton>
            )}
            {(showSearch || !showSearch) && mdUp && (
              <>
                {" "}
                <IconButton onClick={() => navigate("/store/cart")}>
                  <Badge badgeContent={cartItems?.length || localCartItems?.length} color="error">
                    <ShoppingCart sx={{ color: "#01ffff", fontSize: "24px" }} />
                  </Badge>
                </IconButton>
                <>
                  {userInfo?.fullname && (
                    <>
                      <MenuComponent handleLogout={handleLogout} />
                    </>
                  )}
                  {!userInfo?.fullname && (
                    <Link to="/login" className="link">
                      <Button
                        sx={{
                          margin: 2,
                          width: "70px",
                          backgroundColor: "#01FFFF",
                          color: "#1D2326",
                          fontSize: "0.5rem",
                        }}
                        variant="contained"
                      >
                        Login
                      </Button>
                    </Link>
                  )}
                </>
              </>
            )}
            {!showSearch && !mdUp && (
              <>
                {" "}
                <IconButton onClick={() => navigate("/store/cart")}>
                  <Badge badgeContent={cartItems?.length || localCartItems?.length} color="error">
                    <ShoppingCart sx={{ color: "#01ffff", fontSize: "24px" }} />
                  </Badge>
                </IconButton>
                <>
                  {userInfo?.fullname && (
                    <>
                      <MenuComponent handleLogout={handleLogout} />
                    </>
                  )}
                  {!userInfo?.fullname && (
                    <Link to="/login" className="link">
                      {/* <Tooltip title="Login" placement="bottom">
                        <IconButton onClick={() => handleSelection("LOGIN")}>
                          <Login sx={{ color: "#01ffff", fontSize: "24px" }} />
                        </IconButton>
                      </Tooltip> */}
                      <Button
                        fullWidth
                        size="large"
                        style={{
                          // marginTop: "70px",
                          width: "50px",
                          height: "35px",
                          backgroundColor: "#01FFFF",
                          color: "#1D2326",
                          fontFamily: "inter",
                          fontWeight: "700",
                          fontSize: "13px",
                        }}
                        variant="contained"
                      >
                        Login
                      </Button>
                    </Link>
                  )}
                </>
              </>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

import { East } from "@mui/icons-material";
import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NewArrivalsCard from "../../NewArrivalsCard/NewArrivalsCard";
import { formatCase } from "../../../utils/stringStyle";

export default function NewArrivals({ productType }) {
  const uninterceptedAxiosInstance = axios.create();
  const [latestDeals, setLatestDeals] = useState([]);
  useEffect(() => {
    getLatestProducts();
  }, [productType]);

  const getLatestProducts = () => {
    uninterceptedAxiosInstance
      .get(`${process.env.REACT_APP_API_URL}/products/user/all`, {
        params: { newArrival: true, productType },
      })
      .then((res) => {
        console.log("latest", res);
        setLatestDeals(res.data.data.products);
      })
      .catch((err) => console.log(err));
  };

  const navigate = useNavigate();
  return (
    <Box
      sx={{
        width: "100%",
        paddingY: { mobile: "60px", xs: "60px", md: "100px" },
      }}
    >
      <Container maxWidth="xl">
        <Box>
          <Stack direction="column" alignItems="center" justifyContent="center" spacing={3}>
            <Box
              sx={{
                border: "2px solid #01FFFF",
                borderRadius: "5px",
                padding: "5px 10px",
                bgcolor: "#1D2326",
                boxShadow: "0px 0px 32px rgba(1, 255, 255, 0.25)",
                marginBottom: { mobile: "0px", xs: "20px", md: "30px" },
              }}
            >
              <Typography variant="h2">{formatCase("new arrivals")}</Typography>
            </Box>
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{
                width: {
                  xl: "1400px",
                  lg: "1200px",
                  md: "900px",
                  // sm: "90%",
                  xs: "98%",
                  mobile: "98%",
                },
              }}
            >
              <Grid
                container
                spacing={{ xs: 2, mobile: 1, sm: 2.5, md: 3.5, lg: 4, xl: 5 }}
                direction="row"
                sx={{ width: "100%" }}
              >
                {latestDeals?.map((item, index) => {
                  return (
                    <Grid item mobile={12} sm={6} md={6} lg={4} xl={4}>
                      <Stack justifyContent="center" alignItems="center">
                        <NewArrivalsCard product={item} />
                      </Stack>
                    </Grid>
                  );
                })}

                <Grid item mobile={12} sx={{ color: "white" }} alignItems="center">
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    SX={{ width: "100%" }}
                  >
                    <Stack
                      direction="row"
                      spacing={1}
                      justifyContent="center"
                      alignItems="center"
                      onClick={() => navigate(`/store/products?type=${productType}`)}
                      mb={4}
                      sx={{
                        width: "164px",
                        height: "50px",
                        border: "1px solid #01ffff",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "white",
                          fontSize: "24px",
                          fontFamily: "inter",
                        }}
                      >
                        View All
                      </Typography>
                      <East />
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
}

import { Stack, Typography } from "@mui/material";
import React from "react";
import useResponsive from "../../../utils/useResponsive";

export default function HomeMainText() {
  const xsDown = useResponsive("up", "sm");

  return (
    <Stack>
      <Typography variant="homeBannerHead">
        <span
          style={{
            color: "#2E2E2E",
          }}
        >
          Welcome to{" "}
        </span>
        <br />
        <span
          style={{
            color: "#1AB8B8",
          }}
        >
          Lambda Gaming 
        </span>
      </Typography>
      {xsDown && (
        <Typography
          mt={2}
          variant="homeBannerContent"
          sx={{
            textAlign: "left",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: { sm: "70%", md: "100%" },
          }}
        >
          We started off as a gaming outlet in Calicut, Kerala, in 2017, but now we've set our
          sights on something wider. Our focus will always be to provide a good experience to
          newcomers and seasoned gamers alike. This is applicable to anything we do. The products we
          sell are curated and tested by us. We only sell stuff that our team ourselves would use!
        </Typography>
      )}
    </Stack>
  );
}
